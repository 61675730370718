import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isfetchingFormDetails: false,
  getFormDetailsData: {},
  options: [],
};

const getFormDetailslice = createSlice({
  name: "getFormDetails",
  initialState,
  reducers: {
    fetchFormDetailstarted(state) {
      state.isfetchingFormDetails = true;
      state.loading = true;
    },
    fetchFormDetailsuccess(state, { payload }) {
      state.isfetchingFormDetails = false;
      state.getFormDetailsData = payload;
      state.loading = false;
    },
    fetchFormDetailsFail(state) {
      state.isfetchingFormDetails = false;
      state.loading = false;
    },
    storeSelectedAddress(state, { payload }) {
      state.options = payload;
    },
  },
});

const { actions, reducer } = getFormDetailslice;

export const {
  fetchFormDetailstarted,
  fetchFormDetailsuccess,
  fetchFormDetailsFail,
  storeSelectedAddress,
} = actions;
export default reducer;
