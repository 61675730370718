import React from "react";
import "./cardwithImage.css";
import HTMLReactParser from "html-react-parser";

const CardWithImage = ({
  title,
  Options,
  handleSelect,
  questionSubTitle,
  modalPresent,
}) => {
  const [showing, setShowingData] = React.useState(false);
  const [bgColor, setBgcolor] = React.useState("");
  const isShowing = () => {
    if (showing == true) {
      setShowingData(false);
      setBgcolor("");
    } else {
      setShowingData(true);
      setBgcolor("#f2761a");
    }
  };

  return (
    <section className="section">
      <div className="container  has-text-centered">
        <h2 className=" subtitle is-2 is-size-3-mobile mb-3">
          {HTMLReactParser(`${title}`)}
        </h2>

        <div className="is-flex is-flex-wrap-wrap is-justify-content-center">
          {Options.map((item, index) => {
            {
              // console.log("item image====>", item.option_icon);
            }
            return (
              <div
                key={index}
                onClick={() => handleSelect({ item })}
                className="all-option2 m-2"
              >
                <img src={`/image/${item.option_icon}.svg`} />

                <p>{item.option_title}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CardWithImage;
