import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,

  ValveDataContainer: [],
};

const getAvalveSlice = createSlice({
  name: "getAvalve",
  initialState,
  reducers: {
    fetchgetAValveStarted(state) {
      state.isLoading = true;
    },
    fetchgetAValveSuccess(state, { payload }) {
      state.isLoading = false;
      state.ValveDataContainer = payload;
    },
    fetchgetAValveFail(state, { payload }) {
      state.isLoading = false;
      state.ValveDataContainer = payload;
    },
  },
});

const { actions, reducer } = getAvalveSlice;

export const {
  fetchgetAValveFail,
  fetchgetAValveSuccess,
  fetchgetAValveStarted,
} = actions;
export default reducer;
