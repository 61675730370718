import { call, put } from "redux-saga/effects";
import apiClient from "../../../services/httpServices";

import {
  fetchgetAValveFail,
  fetchgetAValveSuccess,
  fetchgetAValveStarted,
} from "./AddValveReducer";
import { API_URL } from "../../../services/webConstant";

export function* getAValveSaga(action) {
  try {
    yield put(fetchgetAValveStarted());

    const { data } = yield call(apiClient.get, API_URL.GET_VALVE);

    yield put(fetchgetAValveSuccess(data));
  } catch (err) {
    yield put(fetchgetAValveFail());
  }
}
