import React, { useEffect } from "react";
import includedtext from "./includedtext";
import { useDispatch, useSelector } from "react-redux";
import { GET_WHATS_INCLUDED_DATA } from "../../../scenes/products/redux/productActions";

const WhatIncludedDetails = ({ includedDetails, setIncludedDetails }) => {
  const modalCloseHandler = () => {
    setIncludedDetails(false);
  };

  return (
    <>
      {includedDetails && (
        <div className="modal is-active is-flex is-flex-direction-row is-justify-content-end">
          <div
            className="modal-background"
            onClick={() => setIncludedDetails(false)}
          ></div>
          <div
            className="new-modal-card"
           
          >
            <div className=" column is-flex is-flex-direction-column">
              {/* Close Modal Btn */}
              <button
                className="delete button"
                
                aria-label="close"
                onClick={modalCloseHandler}
              ></button>



              
              {/* Modal Title */}
              <div className="modal-text-box ">
                <h1 className="modal-heading"> What's included </h1>
                <p className="modal-sub-heading"> The prices you’ve got on screen are fixed, include VAT, and
                  won’t change. </p>
              </div>


              <div className="is-flex is-flex-direction-column is-justify-content-left">
                {includedtext.map((item, index) => (
                  <div className="inc-box">
                    <i className="far fa-check-circle icon has-text-success"></i>

                    <div className="inc-text">
                      <p > {item.point}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="p-5">
                <p
                  style={{
                    width: "100%",
                    height: ".5px",
                    border: "1px solid grey",
                  }}
                  className="has-text-centered"
                ></p>
              </div>

              <div className="column">
                <h1 className="has-text-left is-size-5 has-text-weight-semibold is-size-6-mobile">
                  WHAT’S NOT INCLUDED
                </h1>
              </div>
              <div class="row" />
              <div className="inc-box">
                <i class="fa fa-times has-text-danger" aria-hidden="true"></i>

                <div className="inc-text">
                  <p >
                    {" "}
                    Removal of asbestos (if found to be present)
                  </p>
                </div>
              </div>
              <div className="inc-box">
                <i class="fa fa-times has-text-danger" aria-hidden="true"></i>

                <div className="inc-text">
                  <p >
                    {" "}
                    Scaffolding, or specialist access equipment (if required)
                  </p>
                </div>
              </div>
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WhatIncludedDetails;
