import React from "react";

import HTMLReactParser from "html-react-parser";
import "./numcard.css";
const NumCard = ({
  title,
  Options,
  handleSelect,
  questionSubTitle,
  handelModal,
  modalPresent,
}) => {
  return (
    <section className="section">
      <div className="container has-text-centered">
        <h2 className=" subtitle is-2 is-size-3-mobile mb-3">
          {HTMLReactParser(`${title}`)}
        </h2>

        <div className="is-flex is-flex-wrap-wrap is-justify-content-center styl3">
          {Options.map((item, index) => {
            return (
              <div
                onClick={() => handleSelect({ item })}
                className=" all-option3 m-2"
                key={index}
              >
                <i className="card-main-icon">
                  <img src={item.option_icon} alt="React Logo" />
                </i>
                <p style={{ fontSize: "23px" }}>{item.option_subtitle}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default NumCard;
