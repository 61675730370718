import { call, put } from "redux-saga/effects";
import apiClient from "../../../../services/httpServices";
import { API_FORM_URL } from "../../../../services/webConstant";

import {
  fetchFormDetailstarted,
  fetchFormDetailsuccess,
  fetchFormDetailsFail,
} from "./formReducer";

export function* getFormDetailsSaga(action) {
  try {
    yield put(fetchFormDetailstarted());
    const { payload } = action;

    const { data } = yield call(
      apiClient.get,
      `${API_FORM_URL.GET_FORM_DETAILS}?postcode=${payload.postCode}`
    );

    yield put(fetchFormDetailsuccess(data));
  } catch (error) {
    yield put(fetchFormDetailsFail());
  }
}
