import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isgettingTermAndCOndition: false,
  getTermConditionData: [],
  isgettingPrivacyPolicyLoading: false,
  privacyPolicyData: [],
  isValidatingPhoneNumberLoading: false,
  phonevalidateData: Boolean,
};

const fetchTermAndConditionSlice = createSlice({
  name: "getTermAndCondition",
  initialState: initialState,
  reducers: {
    getTermandConditionDataStarted(state) {
      state.isgettingTermAndCOndition = true;
    },
    getTermandConditionDataSuccess(state, { payload }) {
      state.isgettingTermAndCOndition = false;
      state.getTermConditionData = payload;
    },
    getTermandConditionDataFail(state, { payload }) {
      state.isgettingTermAndCOndition = false;
      state.getTermConditionData = payload;
    },
    getPrivacyPolicyDataStarted(state) {
      state.isgettingPrivacyPolicyLoading = true;
    },
    getPrivacyPolicyDataSuccess(state, { payload }) {
      state.isgettingPrivacyPolicyLoading = false;
      state.privacyPolicyData = payload;
    },
    getPrivacyPolicyDataFails(state, { payload }) {
      state.isgettingPrivacyPolicyLoading = false;
      state.privacyPolicyData = payload;
    },
    getPhoneNumberValidateDataStarted(state) {
      state.isValidatingPhoneNumberLoading = true;
    },
    getPhoneNumberValidateDataSuccess(state, { payload }) {
      state.isValidatingPhoneNumberLoading = false;
      state.phonevalidateData = payload.phone_valid;
      // console.log("Verifying", payload);
    },
    getPhoneNumberValidateDataFails(state, { payload }) {
      state.isValidatingPhoneNumberLoading = false;
      state.phonevalidateData = payload;
    },
    postQuoteEmailStarted(state) {
      state.isPostingQuote = true;
    },
    postQuoteEmailSuccess(state, { payload }) {
      state.isPostingQuote = false;
      state.postSaveEmailData = payload;
    },
    postQuoteEmailFail(state, { payload }) {
      state.isPostingQuote = false;
      state.postSaveEmailData = payload;
    },
  },
});

const { actions, reducer } = fetchTermAndConditionSlice;

export const {
  getTermandConditionDataStarted,
  getTermandConditionDataSuccess,
  getTermandConditionDataFail,
  getPrivacyPolicyDataStarted,
  getPrivacyPolicyDataFails,
  getPrivacyPolicyDataSuccess,
  getPhoneNumberValidateDataStarted,
  getPhoneNumberValidateDataSuccess,
  getPhoneNumberValidateDataFails,
  postQuoteEmailStarted,
  postQuoteEmailSuccess,
  postQuoteEmailFail,
} = actions;

export default reducer;
