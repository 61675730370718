import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRoute } from "../../../scenes/router/redux/reducer";
import { calculateMonthlyPayment } from "../../../utils/Helper";
import { ROUTES } from "../../../utils/constants";
import "./paybyfinance.css";

const PayByFinance = ({ setPayByFInanceProcess, payByfinanceprocess }) => {
  const dispatch = useDispatch();

  const { CartData } = useSelector(({ addProductToCart }) => addProductToCart);
  const { currencyPhoneEmail } = useSelector(
    ({ getBoilerQuote }) => getBoilerQuote
  );
  console.log("this is currency", currencyPhoneEmail);
  const [depositVal, setDepositVal] = useState(25);
  const [dataFinance, setDataFinance] = useState([
    { monthlyInst: "", installment: "12", apr: "0" },
    { monthlyInst: "", installment: "24", apr: "0" },
    { monthlyInst: "", installment: "36", apr: "11.9" },
    { monthlyInst: "", installment: "60", apr: "11.9" },
    { monthlyInst: "", installment: "120", apr: "11.9" },
  ]);
  const [selectedOption, setSelectedOption] = useState(0);
  const [displayData, setDisplayData] = useState([
    { MonthlyAmount: "" },
    { Interest: "" },
    { LoanAmount: "" },
    { TotalPayable: "" },
  ]);

  // Update emi finance data on changing the initial deposit
  useEffect(() => {
    const updatedDataFinance = dataFinance.map((item, index) => {
      const totalAmount = Number(CartData.grand_total);
      const depositAmount = (depositVal / 100) * totalAmount;
      const loanAmount = totalAmount - depositAmount;
      const annualInterestRate = parseFloat(item.apr);
      const numberOfPayments = Number(item.installment);
      const monthlyInst = calculateMonthlyPayment(
        loanAmount,
        annualInterestRate,
        numberOfPayments
      );
      return { ...item, monthlyInst };
    });
    setDataFinance(updatedDataFinance);
  }, [depositVal]);

  // Update display data after selecting option or changing initial deposit
  useEffect(() => {
    const selectedData = dataFinance[selectedOption];
    const loanAmount =
      Number(CartData.grand_total) -
      (depositVal / 100) * Number(CartData.grand_total);
    const annualInterestRate = parseFloat(selectedData.apr);
    const numberOfPayments = Number(selectedData.installment);
    const monthlyInst = calculateMonthlyPayment(
      loanAmount,
      annualInterestRate,
      numberOfPayments
    );
    const totalPayable = monthlyInst * numberOfPayments;
    const interest = totalPayable - loanAmount;
    setDisplayData([
      { MonthlyAmount: monthlyInst.toFixed(2) },
      { Interest: interest.toFixed(2) },
      { LoanAmount: loanAmount.toFixed(2) },
      { TotalPayable: totalPayable.toFixed(2) },
    ]);
  }, [selectedOption, depositVal]);

  const handleSubmit = () => {
    setPayByFInanceProcess(false);
    dispatch(setRoute(ROUTES.paymentSuccessful));
  };

  return (
    <>
      {payByfinanceprocess && (
        <>
          {" "}
          <div className="FinanceContainer">
            <div className="FinanceModal">
              <div
                className="FinanceModalCloseBtn"
                onClick={() => setPayByFInanceProcess(false)}
              >
                <i
                  class="fa-solid fa-circle-xmark fa-xl"
                  style={{ color: "#0011ff" }}
                ></i>
              </div>
              <div className="columns is-1 is-flex is-flex-direction-column ">
                {/* PAYMENT START */}
                <div className="column">
                  <div className="columns is-justify-content-center">
                    <div className="is-flex is-justify-content-center is-align-items-center ">
                      <span
                        // class="icon is-medium"
                        class="icontick"
                        style={{ width: "3rem !important" }}
                      >
                        <span class="fa-stack fa-sm">
                          <i class="fas fa-circle-check fa-stack-2x has-text-info"></i>
                        </span>
                      </span>
                      <span className="title is-6 has-text-weight-bold ml-2">
                        {" "}
                        Pay Monthly
                      </span>
                    </div>
                  </div>
                </div>

                {/* SELECT EMI DURATION, MONTH & RATE */}
                {dataFinance &&
                  dataFinance.map((item, index) => {
                    const isZeroAPR = parseFloat(item.apr) === 0;
                    return (
                      <div
                        className="column mt-2"
                        style={{
                          backgroundColor: "#f5f5f5",
                          borderRadius: "10px",
                        }}
                        key={index}
                      >
                        <div className="columns is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center">
                          <div className="column">
                            {" "}
                            <input
                              type="radio"
                              name="monthlyFinnace"
                              id={`${index}Fin`}
                              value={index}
                              checked={selectedOption === index}
                              onChange={(e) => {
                                setSelectedOption(parseInt(e.target.value, 10));
                              }}
                            />
                            <label
                              htmlFor={`${index}Fin`}
                              className=" is-size-6 has-text-weight-bold "
                            >
                              {` ${
                                currencyPhoneEmail.company_currency
                                  ? currencyPhoneEmail.company_currency
                                  : "£"
                              } ${Number(item.monthlyInst).toFixed(
                                2
                              )} for ${Number(item.installment)} month`}
                            </label>
                          </div>

                          <div
                            className="column is-2 m-2 is-flex is-justify-content-center is-align-items-center"
                            style={{
                              backgroundColor: isZeroAPR
                                ? "#7ac692"
                                : "#dfeae2",

                              borderRadius: "6px",

                              height: "25px",
                            }}
                          >
                            <p
                              className="has-text-weight-bold"
                              style={{
                                color: isZeroAPR ? "#FFFFFF" : "#7ac692",
                                fontSize: "10px",
                              }}
                            >
                              {`${item.apr} % APR`}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {/* SELECT INITIAL DEPOSIT */}
                <div className="column mt-2">
                  <p className="is-flex has-text-weight-bold is-flex-direction-row is-justify-content-space-between">
                    <div>
                      Deposit{" "}
                      <span className="has-text-success">
                        (25% recommended)
                      </span>
                    </div>
                    <div>
                      {`${currencyPhoneEmail.company_currency} ${(
                        (depositVal / 100) *
                        Number(CartData.grand_total)
                      ).toFixed(2)} (${depositVal}%)`}
                    </div>
                  </p>
                  <input
                    type="range"
                    id="vol"
                    name="vol"
                    min="0"
                    max="99"
                    value={depositVal}
                    onChange={(e) => setDepositVal(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>
                <p id="pDivider"></p>

                {/* DISPLAY DATA */}
                <div className="column is-flex is-justify-content-space-between is-align-items-center ">
                  <p className=" is-size-6 has-text-grey">Monthly Amount :</p>
                  <p className=" is-size-6 has-text-grey">
                    {`${currencyPhoneEmail.company_currency} ${displayData[0].MonthlyAmount}`}
                  </p>
                </div>
                <p
                  id="pDivider"
                  style={{
                    height: "0.2px",
                    width: "100%",
                    backgroundColor: "#e3e8e6",
                  }}
                ></p>
                <div className="column is-flex is-justify-content-space-between is-align-items-center ">
                  <p className=" is-size-6 has-text-grey">Interest :</p>
                  <p className=" is-size-6 has-text-grey">
                    {`${currencyPhoneEmail.company_currency} ${displayData[1].Interest}`}
                  </p>
                </div>
                <p
                  id="pDivider"
                  style={{
                    height: "0.2px",
                    width: "100%",
                    backgroundColor: "#e3e8e6",
                  }}
                ></p>
                <div className="column is-flex is-justify-content-space-between is-align-items-center ">
                  <p className=" is-size-6 has-text-grey">Loan amount :</p>
                  <p className=" is-size-6 has-text-grey">
                    {`${currencyPhoneEmail.company_currency} ${displayData[2].LoanAmount}`}
                  </p>
                </div>
                <p
                  id="pDivider"
                  style={{
                    height: "0.2px",
                    width: "100%",
                    backgroundColor: "#e3e8e6",
                  }}
                ></p>
                <div className="column is-flex is-justify-content-space-between is-align-items-center ">
                  <p className=" is-size-6 has-text-grey">Total payable :</p>
                  <p className=" is-size-6 has-text-grey">
                    {`${currencyPhoneEmail.company_currency} ${displayData[3].TotalPayable}`}
                  </p>
                </div>
                <p
                  id="pDivider"
                  style={{
                    height: "0.2px",
                    width: "100%",
                    backgroundColor: "#e3e8e6",
                  }}
                ></p>

                {/* SUBMIT */}
                <button
                  onClick={handleSubmit}
                  className="button is-fullwidth is-rounded mt-4"
                  style={{ backgroundColor: "#fb6058", height: "40px" }}
                >
                  <p className="has-text-white">Complete My order</p>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PayByFinance;
