import React from "react";
import "./../../../../styles/styles.css";
import { useSelector } from "react-redux";

const ContactCard = ({
  title,
  Options,
  handleSelect,
  questionSubTitle,
  handelModal,
  modalPresent,
}) => {
  const { currencyPhoneEmail, isLoadingdynamicCurrrencyandPhone } = useSelector(
    ({ getBoilerQuote }) => getBoilerQuote
  );
  console.log("currencyPhoneEmail", currencyPhoneEmail);

  const handleChatBot = () => {
    window.leadConnector.chatWidget.openWidget();
  };

  return (
    <section className="section has-text-centered" id="Section14">
      <div className="is-max-smalldesktop">
        <div className="step-title">
          <h1 className="callTitle main-span-text">
            We’re hoping to offer oil boilers very soon
          </h1>
          <p className=" is-size-5">
            Until then, we're happy to talk you through your options over the
            phone, or over live chat.
          </p>
        </div>

        <div className="step-answers columns is-9">
          <div className="column call-box-co ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-550 -100 1700 800"
            >
              <path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z" />
            </svg>

            <h5 className="title is-size-4">Give us a call</h5>
            <div>
              <h1 className="is-size-6">
                Our operational hours are 8:00 AM to 8:00 PM (Mon-Fri) and 9:00
                AM to 3:00 PM (Sat & Sun).
              </h1>
            </div>
            <div className="is-flex is-justify-content-center">
              <button type="button" data-test="" className="chatButton my-2">
                <a href="tel:08001937777" data-test="">
                  <span
                    className="btn__copy btn__copy--large is-size-6"
                    style={{ color: "white" }}
                  >
                    {currencyPhoneEmail && currencyPhoneEmail.company_phone}
                  </span>
                </a>
              </button>
            </div>
          </div>
          <div className="column livechat-box-co is-centered is-flex is-flex-direction-column">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-550 -100 1700 800"
            >
              <path d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z" />
            </svg>
            <h5 className="title is-size-4">Contact us via LiveChat</h5>
            <div>
              <h1 className="is-size-6">
                It's quick, easy and you can fit it around your schedule
              </h1>
            </div>
            <div className="is-flex is-justify-content-center mt-auto">
              <button
                type="button"
                data-test=""
                className="chatButton my-2"
                onClick={() => handleChatBot()}
              >
                <span
                  className="btn__copy btn__copy--large is-size-5"
                  style={{ color: "white" }}
                >
                  Chat to us
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactCard;
