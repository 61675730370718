import React from "react";

const PaymentErrorModal = ({
  errorMessage,
  setErrorModal,
  errorModal,
  setStripePaymentProcess,
}) => {
  return (
    <>
      {errorModal && (
        <div className="quotesContainer">
          <div className="showStartAgainContainer">
            <div className="columns is-flex is-flex-direction-column is-justify-content-center ">
              <div className="column">
                {/* <p className="title is-4 has-text-weight-normal"> Start again</p> */}
              </div>
              <div className="column">
                <p className="subtitle is-6"> {errorMessage}</p>
              </div>

              <div className="column">
                <button
                  class="button is-success is-fullwidth"
                  onClick={() => {
                    setErrorModal(false);
                    setStripePaymentProcess(false);
                  }}
                >
                  {" "}
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentErrorModal;
