import React from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import "./App.css";
import GetAquote from "./scenes/get-a-quote/GetAquote";
import "bulma/css/bulma.min.css";
import { store } from "./stores";
import Router from "./scenes/router/Router";
import PaymentSuccessful from "./scenes/paymeny-successful/PaymentSuccessful";
import InfoForm from "./myComponent/organisms/form/InfoForm";
import Form from "./myComponent/molecule/form/Form";
import PincodeAutocomplete from "./scenes/products/PincodeAutocomplete";
import AddressForm from "./scenes/products/AddressForm";
import FormPostalCode from "./myComponent/molecule/form/FormPostalCode";
import { AddresComponents } from "./myComponent/molecule/form/AddresComponents";
import Login from "./scenes/auth/Login";
import { FETCH_CLIENT } from "./scenes/auth/redux/LoginAuthAction";
import { useEffect } from "react";
import ThemeProvider from "./scenes/products/ThemeProvider";

const App = () => {
  const dispatch = useDispatch();
  let url = window.location.search;
  const newUrl = url.split("?")[1];
  const param1 = newUrl.split("&&");
  let p1 = param1[0];
  let p2 = param1[1];

  useEffect(() => {
    const payload = {
      client_id: Number(p1),
      end_user_is: p2,
    };
    dispatch({ type: FETCH_CLIENT, payload: payload });
  }, []);

  return (
    <>
      <Router url={newUrl} />
      <ThemeProvider />
    </>
  );
};

export default App;
