import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import sagas from "./sagas";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { loadState, saveState } from "../utils/Helper";
// const persistedState = loadState();
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  // preloadedState: persistedState,
});

sagaMiddleware.run(sagas);

// store.subscribe(() => {
//   saveState(store.getState());
// });
export const persistor = persistStore(store);
