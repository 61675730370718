import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoadinToken: false,
  clientToken: "",
  clientData: {},
};

const clientDetailsSlice = createSlice({
  name: "clientDetails",
  initialState,
  reducers: {
    fetchClientStarted(state) {
      state.isLoadinToken = true;
    },
    getClientSuccess(state, { payload }) {
      state.clientToken = payload.token;
      state.clientData = payload.clientDetails;
      state.isLoadinToken = false;
    },
    fetchClientfail(state, { payload }) {
      state.isLoadinToken = false;
      state.clientToken = payload;
    },
  },
});

const { actions, reducer } = clientDetailsSlice;
export const {
  fetchClientfail,
  fetchClientStarted,
  getClientSuccess,
} = actions;

export default reducer;
