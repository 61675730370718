import { call, put } from "redux-saga/effects";
import apiClient from "../../../../services/httpServices";
import { ADD_PRODUCT_TO_BASKET, GET_CART_PRODUCT_DETAILS } from "./CartAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  postAddProductToCartFail,
  postAddProductToCartStarted,
  postAddProductTocartSuccess,
  getProductToCartStarted,
  getProductTocartFails,
  getProductToCartSuccess,
  removeProductFromCartStarted,
  removeproductFromCartFail,
  removeProductFromCartSuccess,
} from "./CartReducer";
import { GET_CART } from "../../../../services/webConstant";

export function* postAddProductToCartSaga(action) {
  const { payload } = action;

  try {
    yield put(postAddProductToCartStarted());

    const addProductToBasketResponse = yield call(
      apiClient.post,
      GET_CART.ADD_PRODUCT_TO_BASKET,
      payload
    );

    yield put(
      postAddProductTocartSuccess({
        cartDetails: addProductToBasketResponse.cart_details,
      })
    );

    toast.success("Added Successfully", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    const PreviousFormData = localStorage.getItem("StoreFormData");

    let PreviousEmailAddress;

    if (PreviousFormData !== null) {
      const formdataval = JSON.parse(PreviousFormData);

      PreviousEmailAddress = formdataval.Email_Id;
    }
    console.log("PreviousEmailAddress", PreviousEmailAddress);
    const payloadData = {
      email: PreviousEmailAddress,
    };
    yield put({ type: GET_CART_PRODUCT_DETAILS, payload: payloadData });
  } catch (err) {
    yield put(
      postAddProductToCartFail({
        error: err.message ? err.message : "Something went wrong",
      })
    );
    toast.error("SomeThing went Wrong", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* getCartProductSaga(action) {
  const { payload } = action;
  console.log("get cart payload", payload);
  try {
    yield put(getProductToCartStarted());

    const { data } = yield call(
      apiClient.get,
      `${GET_CART.GET_CART_PRODUCT_DETAILS}?email=${payload.email}`
    );

    yield put(getProductToCartSuccess({ cartDetails: data.cart_details }));
  } catch (error) {
    yield put(getProductTocartFails());
  }
}

export function* removeProductFromCartSaga(action) {
  const { payload } = action;
  const PreviousFormData = localStorage.getItem("StoreFormData");

  let PreviousEmailAddress;

  if (PreviousFormData !== null) {
    const formdataval = JSON.parse(PreviousFormData);

    PreviousEmailAddress = formdataval.Email_Id;
  }
  console.log("PreviousEmailAddress", PreviousEmailAddress);

  try {
    yield put(removeProductFromCartStarted());

    const removeProduct = yield call(
      apiClient.post,
      GET_CART.REMOVE_CART_ITEM,
      payload
    );

    const payloadData = {
      email: PreviousEmailAddress,
    };

    yield put(removeProductFromCartSuccess(removeProduct));
    yield put({ type: GET_CART_PRODUCT_DETAILS, payload: payloadData });
  } catch (err) {
    yield put(removeproductFromCartFail());
  }
}

//PAYMENT_USING_STRIPE
//CREATE_CLIENT_SECRET
