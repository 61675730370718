import { combineReducers } from "redux";
import GetBoilerQuoteReducer from "./../scenes/get-a-quote/redux/GetAquoteReducer";
import getProductsDetailReducer from "./../scenes/products/redux/productReducer";
import getFormDetailsReducer from "./../myComponent/molecule/form/redux/formReducer";
import getAddSmartDeviceReducer from "./../scenes/addSmartDevice/redux/addSmartDeviceReducer";
import getControlDetailsReducer from "./../scenes/choose-controls/redux/controlReducer";
import bucketReducerReducer from "./../scenes/products/redux/bucketReducer";
import routerReducer from "./../scenes/router/redux/reducer";
import postOrderSummaryReducer from "../scenes/order/redux/OrderSummaryReducer";
import addProductToCartReducer from "../myComponent/organisms/cart/redux/CartReducer";
import getARadiatorReducer from "../scenes/radiator/redux/AddaRadiatorReducer";
import getSingleProductDetailsReducer from "../scenes/productDetails/redux/ProducDetailsReducer";
import getAvalveReducer from "../scenes/AddValves/redux/AddValveReducer";
import getClientSecretDetailReducer from "../myComponent/organisms/checkoutpage/redux/PaymentReducer";
import getAllSavedDataForResumeReducer from "../scenes/router/redux/RouterReducer";
import getPaymentDetailsDataReducer from "../scenes/paymeny-successful/redux/PaymentSuccessReducer";
import getTermAndConditionReducer from "../myComponent/organisms/form/redux/InfoFormReducer";
import clientDetailsSlice from "../scenes/auth/redux/LoginAuthReducer";
const rootReducer = combineReducers({
	clientDetails: clientDetailsSlice,
	getBoilerQuote: GetBoilerQuoteReducer,
	getProductsDetails: getProductsDetailReducer,
	getFormDetails: getFormDetailsReducer,
	getAddSmartDevices: getAddSmartDeviceReducer,
	getControlDetails: getControlDetailsReducer,
	bucketReducer: bucketReducerReducer,
	router: routerReducer,
	postOrderSummary: postOrderSummaryReducer,
	addProductToCart: addProductToCartReducer,
	getARadiator: getARadiatorReducer,
	getSingleProductDetails: getSingleProductDetailsReducer,
	getAvalve: getAvalveReducer,
	getClientSecretDetail: getClientSecretDetailReducer,
	getAllSavedDataForResume: getAllSavedDataForResumeReducer,
	getPaymentDetailsData: getPaymentDetailsDataReducer,
	getTermAndCondition: getTermAndConditionReducer,
});
export default rootReducer;
