import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRoute } from "../router/redux/reducer";
import { ROUTES } from "../../utils/constants";
import { router } from "../router/Router";
// import "./AddARadiator.module.css";
import { Image, Shimmer } from "react-shimmer";
import "../../styles/styles.css";
import ProductInfoModal from "../../myComponent/molecule/Modal/ProductInfoModal";
import Cart from "../../myComponent/organisms/cart/Cart";
import { GET_VALVE } from "./redux/AddValveAction";
import {
  ADD_PRODUCT_TO_BASKET,
  REMOVE_CART_ITEM,
} from "../../myComponent/organisms/cart/redux/CartAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddValves = () => {
  const dispatch = useDispatch();
  const [quantityValve, setQuantityValve] = useState(1);
  const { isLoading, ValveDataContainer } = useSelector(
    (state) => state.getAvalve
  );

  const {
    isAddProductToCartSubmitting,
    addProductToCartSuccess,
    addProductToCardError,
  } = useSelector(({ addProductToCart }) => addProductToCart);
  const { CartData } = useSelector(({ addProductToCart }) => addProductToCart);
  const { success, data } = ValveDataContainer;
  const { currencyPhoneEmail, isLoadingdynamicCurrrencyandPhone } = useSelector(
    ({ getBoilerQuote }) => getBoilerQuote
  );
  const handleNext = () => {
    dispatch(setRoute(ROUTES.orderSummary));
    const call = () => {
      return router(ROUTES.orderSummary);
    };
    setTimeout(call, 500);
  };

  // modal state
  const [isProductInfoModal, setIsProductInfoModal] = useState(false);
  useEffect(() => {
    dispatch({ type: GET_VALVE });
  }, []);

  const handleToProductScreen = () => {
    dispatch(setRoute(ROUTES.products));
  };

  const AddvalveToCart = (data) => {
    const payload = {
      volve_id: data.id,
      product_id: CartData.product_id,

      thermostat_id: CartData.thermostate_id ? CartData.thermostate_id : 0,

      controler_id: CartData.control_id ? CartData.control_id : 0,

      radiator_id: CartData.radiator_id && CartData.radiator_id,
      volvo_qty: quantityValve,
      radiator_qty: CartData.radiator_qty && CartData.radiator_qty,
    };
    dispatch({ type: ADD_PRODUCT_TO_BASKET, payload });
  };
  const handleAdd = () => {
    setQuantityValve(quantityValve + 1);
  };

  const handleSub = () => {
    if (quantityValve > 1) {
      setQuantityValve(quantityValve - 1);
    }
  };

  const handleRemoveValve = (id) => {
    const payload = {
      volvo_id: id,
    };
    dispatch({ type: REMOVE_CART_ITEM, payload });
  };

  if (isLoading) {
    return (
      <>
        <div className="loaderContainer">
          <div className="mainLoader"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="is-max-desktop valve-adjust">
        <div className="columns is-flex-direction-row is-flex-wrap-wrap">
          <div className="column valve-sec is-flex-wrap-wrap">
            <div className="column r-img-sec ">
              <figure className="r-imagebox pt-4">
                <Image
                  src={data && data.image}
                  alt="Placeholder image"
                  style={{ height: 200 }}
                  fallback={<Shimmer />}
                />
              </figure>

              <div className="r-title-sec new-title-sec title-sec px-5 mb-0">
                <h2>Add enough valve for every radiator</h2>
                <p>
                  {" "}
                  Building Regulations mean you should have a TRV on every
                  radiator (if your home is in England), except any radiators in
                  the same room as the main thermostat.{" "}
                </p>
              </div>
            </div>

            <div className="column is-one-thrid">
              <div className="title is-4 pb-4">{data && data.volvo_title}</div>
              <div className="title is-3">
                +
                {currencyPhoneEmail
                  ? currencyPhoneEmail.company_currency
                  : " £"}
                {data && (data.volve_price * quantityValve).toFixed(3)}
              </div>

              <p className="mb-3">Quantity</p>
              <div>
                <button
                  onClick={handleSub}
                  className=" m-1 has-background-white button "
                >
                  {" "}
                  -{" "}
                </button>
                <button className="button m-1 has-background-white">
                  {quantityValve}
                </button>
                <button
                  onClick={handleAdd}
                  className="button  m-1 has-background-white"
                >
                  +
                </button>
              </div>
              <div className="main-btn-sec">
                <button
                  className="home-survey"
                  onClick={() => AddvalveToCart(data)}
                  style={{ width: "250px" }}
                >
                  {isAddProductToCartSubmitting ? (
                    <span class="loader"></span>
                  ) : (
                    <>
                      {" "}
                      Add to basket{" "}
                      <span>
                        <i class="fas fa-plus"></i>
                      </span>{" "}
                    </>
                  )}
                </button>

                {CartData.volve_id && (
                  <button
                    className={`button ${"is-danger"}`}
                    style={{ width: "250px" }}
                    onClick={() => {
                      handleRemoveValve(data.id);
                    }}
                  >
                    Remove from basket
                  </button>
                )}
              </div>
              <ToastContainer />
            </div>
          </div>

          <div className=" column is-4 py-0">
            <Cart handleNext={handleNext} />
          </div>
        </div>
      </div>

      {/* modals */}
      <ProductInfoModal
        isVisible={isProductInfoModal}
        onClose={setIsProductInfoModal}
      />
    </>
  );
};

export default AddValves;
