import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  AllDataForResumeSession: [],
  isAllSavedDataLoading: false,
};
const getAllSavedDataForSessionResumeslice = createSlice({
  name: "getAllSavedDataForResume",
  initialState,
  reducers: {
    fetchAllSavedUserDataToResumeStarted(state) {
      state.isAllSavedDataLoading = true;
    },
    fetchAllSavedUserDataToResumeSuccess(state, { payload }) {
      state.isAllSavedDataLoading = false;
      state.AllDataForResumeSession = payload;
    },
    fetchAllSavedUserDataToResumeFails(state, { payload }) {
      state.isAllSavedDataLoading = false;
      state.AllDataForResumeSession = payload;
    },
  },
});
const { actions, reducer } = getAllSavedDataForSessionResumeslice;

export const {
  fetchAllSavedUserDataToResumeStarted,
  fetchAllSavedUserDataToResumeSuccess,
  fetchAllSavedUserDataToResumeFails,
} = actions;
export default reducer;
