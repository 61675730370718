import React from 'react'
import SVGLOG from '../../../assets/svgIcons/bath-1.svg'
import bathTube from '../../../assets/svgIcons/bath-3.svg'

function UseSVG(iconName) {
    console.log("icon identify",iconName.iconName)
  return (
    <div>
        <img src={bathTube} alt="React Logo" />
    </div>
  )
}

export default UseSVG