import React from "react";

const HelpMeChooseRadiator = ({
  helpmechooseRadiatorModal,
  setHelpMeChooseRadiatorModal,
}) => {
  return (
    <>
      {helpmechooseRadiatorModal && (
        <div className="modal is-active is-flex is-flex-direction-row is-justify-content-end">
          <div
            className="modal-background"
            onClick={() => setHelpMeChooseRadiatorModal(false)}
          ></div>
          <div
            className="card column p-6 is-half is-fullheight"
            style={{ height: "100%", overflow: "auto" }}
          >
            <div className="columns is-flex is-flex-direction-column py-2">
              {/* Close Modal Btn */}
              <button
                className="delete button my-custom-color"
                style={{
                  marginLeft: "-46px",
                  marginTop: "50px",
                  scale: "2.2",
                  position: "fixed",
                }}
                aria-label="close"
                onClick={() => setHelpMeChooseRadiatorModal(false)}
              ></button>

              {/* Modal Title */}
              <div className="column">
                <h1 className="is-size-2 has-text-weight-bold has-text-right mb-3">
                  The Stelrad Softline range
                </h1>
                <p className="is-size-5  has-text-right mb-4">
                  The Softline is one of the most popular radiator ranges in the
                  UK. Beautifully curved with top grille and end panels, the
                  Stelrad Softline radiator is expertly designed for all of your
                  home’s needs. Professionally fit and secure - this top of the
                  range radiator will provide you with optimum comfort.
                </p>
                <p className="has-text-right">
                  As you would expect from a radiator with the Stelrad pedigree,
                  precision is everything. The convectors are expertly welded
                  onto the waterways for even greater efficiency. You even have
                  the option of a flexible connection - giving you the freedom
                  to place this almost anywhere in your home.
                </p>
              </div>
              <div className="column">
                <h1 className="is-size-3 has-text-weight-semibold has-text-right mb-3">
                  Contemporary design.
                </h1>
                <p className="has-text-right">
                  No one likes a jumbo radiator ruining your home’s aesthetic,
                  it can be off-putting to say the least. You won’t get that
                  with the Softline. Thanks to its unique shape, this radiator
                  fits perfectly in your home without taking up too much space.
                </p>
              </div>
              <div className="column">
                <h1 className="is-size-3 has-text-weight-semibold has-text-right mb-3">
                  Compact.
                </h1>
                <p className="has-text-right">
                  Compact design, powerful results. Crafted from using some of
                  the most sophisticated production resources in Europe - these
                  radiators are vigorously tested to provide you with an
                  excellent performance rate. Redefining radiators{" "}
                </p>
              </div>
              <div className="column">
                <h1 className="is-size-3 has-text-weight-semibold has-text-right mb-3">
                  Excellence builds quality.
                </h1>
                <p className="has-text-right">
                  Stelrad accept nothing less than quality. Strictly controlled
                  independent laboratory testing ensures that all Stelrad
                  radiators are guaranteed to perform to a maximum working
                  pressure of 145 psi (10 bar), and conform to BS EN 442 - the
                  European Standard for radiators.
                  <p>
                    All Stelrad Softline radiators are complete with a 15 year
                    manufacturers warranty. So you have the protection you need,
                    for the peace of mind you deserve.
                  </p>
                </p>
              </div>

              {/* image */}
              <div className="column">
                <figure class="image box">
                  <img src="https://www.boxt.co.uk/v1/packs/media/images/products/rad-product-image-f9f324f1d78e995a22bcf5de23b1ab5903910e553c7af9de2dfaa12662ccc30a.png" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HelpMeChooseRadiator;
