import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_BOILER_QUOTE, GET_CURRENCY } from "./redux/GetAquoteAction";
// import { SAVE_BOILER_QUOTE } from "./redux/GetAquoteAction";
import InfoForm from "../../myComponent/organisms/form/InfoForm";
import { storeSlectedOption } from "./redux/GetAquoteReducer";
import QuoteCard from "../../myComponent/molecule/cards/QuoteCard/QuoteCard";
// import QuoteCardSmall from "./../../myComponent/molecule/cards/QuoteCard/QuoteCardSmall";
// import SingleCard from "../../myComponent/Card/SingleCard";
import MediumCard from "../../myComponent/molecule/cards/mideumCard/MediumCard";
import SmallWidthCard from "../../myComponent/molecule/cards/smallWidthCard/SmallWidthCard";
import PriceCard from "../../myComponent/molecule/cards/priceCard/priceCard";
import SmallCard from "../../myComponent/molecule/cards/smallCard/smallCard";
import NumCard from "../../myComponent/molecule/cards/numCard/numCard";
import CardWithImage from "../../myComponent/molecule/cards/cardWithImage/CardWithImage";
import Card from "../../myComponent/molecule/cards/card/Card";
import Form from "../../myComponent/molecule/form/Form";
import ContactCard from "../../myComponent/molecule/cards/contactCard/contactCard";
import BathTubCard from "../../myComponent/molecule/cards/bathTubCard/BathTubCard";
import DescribeYourBoiler from "../../myComponent/molecule/cards/describeYourBoiler/DescribeYourBoiler";
import Mountedcard from "../../myComponent/molecule/cards/mountedcard/Mountedcard";
import CurrentBoiler from "../../myComponent/molecule/cards/currentBoiler/CurrentBoiler";
import Radiatorscard from "../../myComponent/molecule/cards/radiatorsCard/Radiatorscard";
import RadiatorvalveCard from "../../myComponent/molecule/cards/radiatorvalve/RadiatorValveCard";
import RoofWall from "../../myComponent/molecule/cards/roofWallType/RoofWall";
import RoofSlope from "../../myComponent/molecule/cards/roofSlopetype/RoofSlopeType";
import FlueComeOut from "../../myComponent/molecule/cards/flueComeout/FlueComeOut";
import PropertyCard from "../../myComponent/molecule/cards/propertyType/PropertyType";
import FormPostalCode from "../../myComponent/molecule/form/FormPostalCode";
import { AddresComponents } from "../../myComponent/molecule/form/AddresComponents";
import NumOfRadiatorsCard from "../../myComponent/molecule/cards/num-of-radiatorcard/NumOfRadiatorsCard";
import { Helmet } from "react-helmet";

const GetAquote = (props) => {
  const dispatch = useDispatch();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(4);
  const [showForm, setShowForm] = useState(false);
  const [previousQuote, setPreviousQuote] = useState();
  const [form, setForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [contactform, setContactForm] = useState(false);
  const [previousQuoteId, setPreviousQuoteId] = useState();

  const { getBoilerQuoteData, loading, selectedOptions } = useSelector(
    ({ getBoilerQuote }) => getBoilerQuote
  );

  let url = window.location.search;
  const newUrl = url.split("?")[1];
  const param1 = newUrl.split("&&");
  let p1 = param1[0];
  let p2 = param1[1];
  useEffect(() => {
    const payload = {
      random_id: param1[0],
    };
    dispatch({ type: GET_BOILER_QUOTE, payload: payload });
  }, []);

  const BoilerData = getBoilerQuoteData.find(
    (item) => item.id == currentQuestionIndex
  );

  const handelNext = () => {
    setForm(true);
  };

  const handleSelect = (value) => {
    if (value.item.next_question_value_id === null) {
      setShowForm(true);
      return;
    }

    if (value.item.next_question_value_id == -1) {
      setContactForm(true);

      return;
    } else {
      setCurrentQuestionIndex(value.item.next_question_value_id);
      // setPreviousQuote(BoilerData.previous_id);

      setPreviousQuoteId(value.item.previous_question_value_id);
    }

    if (value.item.question_id == 17) {
      console.log("this is item value", value);
      sessionStorage.setItem("bedNumberCount", value.item.option_title);
    }

    dispatch(
      storeSlectedOption({
        questionId: value.item.question_id,
        selectedOption: value,
        // questionTitle: BoilerData && BoilerData.boiler_title,
      })
    );
  };

  const handleBackClick = () => {
    //  if (currentQuestionIndex == Object.keys(getBoilerQuoteData).length - 1) {
    setShowForm(false);
    //  return;
    //  }
    setCurrentQuestionIndex(BoilerData.previous_id);
  };

  // useEffect(() => {
  //   // dispatch({ type: GET_CURRENCY });
  // }, []);
  const handelModal = () => {
    if (showModal == true) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  };

  return (
    <div>
      {showForm ? (
        <>
          {" "}
          <AddresComponents handelNext={handelNext} />
        </>
      ) : (
        <>
          {loading && (
            <div className="loaderContainer">
              <div className="mainLoader"></div>
            </div>
          )}

          {contactform ? (
            <ContactCard />
          ) : (
            <>
              {" "}
              {BoilerData && BoilerData.card_type === "quoteCard" && (
                <QuoteCard
                  title={BoilerData.boiler_title}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  Options={BoilerData.optiones}
                  handleSelect={handleSelect}
                  handelModal={handelModal}
                  modalPresent={BoilerData.modal_present}
                />
              )}
              {BoilerData && BoilerData.card_type === "RoofSlope" && (
                <RoofSlope
                  title={BoilerData && BoilerData.boiler_title}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  Options={BoilerData && BoilerData.optiones}
                  handleSelect={handleSelect}
                  handelModal={handelModal}
                  modalPresent={BoilerData && BoilerData.modal_present}
                />
              )}
              {BoilerData && BoilerData.card_type === "mountedCard" && (
                <Mountedcard
                  title={BoilerData && BoilerData.boiler_title}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  Options={BoilerData && BoilerData.optiones}
                  handleSelect={handleSelect}
                  handelModal={handelModal}
                  modalPresent={BoilerData && BoilerData.modal_present}
                />
              )}
              {BoilerData && BoilerData.card_type === "FlueComeOut" && (
                <FlueComeOut
                  title={BoilerData && BoilerData.boiler_title}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  Options={BoilerData && BoilerData.optiones}
                  handleSelect={handleSelect}
                  handelModal={handelModal}
                  modalPresent={BoilerData && BoilerData.modal_present}
                />
              )}
              {BoilerData && BoilerData.card_type === null && (
                <QuoteCard
                  title={BoilerData && BoilerData.boiler_title}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  Options={BoilerData && BoilerData.optiones}
                  handleSelect={handleSelect}
                  // handelModal={handelModal}
                  // modalPresent={BoilerData && BoilerData.modal_present}
                />
              )}
              {BoilerData && BoilerData.card_type === "Radiatorscard" && (
                <Radiatorscard
                  title={BoilerData && BoilerData.boiler_title}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  Options={BoilerData && BoilerData.optiones}
                  handleSelect={handleSelect}
                  handelModal={handelModal}
                  modalPresent={BoilerData && BoilerData.modal_present}
                />
              )}
              {BoilerData && BoilerData.card_type === "RoofWall" && (
                <RoofWall
                  title={BoilerData && BoilerData.boiler_title}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  Options={BoilerData && BoilerData.optiones}
                  handleSelect={handleSelect}
                  handelModal={handelModal}
                  modalPresent={BoilerData && BoilerData.modal_present}
                />
              )}
              {BoilerData && BoilerData.card_type === "RadiatorvalveCard" && (
                <RadiatorvalveCard
                  title={BoilerData && BoilerData.boiler_title}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  Options={BoilerData && BoilerData.optiones}
                  handleSelect={handleSelect}
                  handelModal={handelModal}
                  modalPresent={BoilerData && BoilerData.modal_present}
                />
              )}
              {BoilerData && BoilerData.card_type === "CurrentBoiler" && (
                <CurrentBoiler
                  title={BoilerData && BoilerData.boiler_title}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  Options={BoilerData && BoilerData.optiones}
                  handleSelect={handleSelect}
                  handelModal={handelModal}
                  modalPresent={BoilerData && BoilerData.modal_present}
                />
              )}
              {BoilerData && BoilerData.card_type === "priceCard" && (
                <PriceCard
                  title={BoilerData && BoilerData.boiler_title}
                  Options={BoilerData && BoilerData.optiones}
                  modal_description={BoilerData && BoilerData.more_details_text}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  handleSelect={handleSelect}
                  handelModal={handelModal}
                  modalPresent={BoilerData && BoilerData.modal_present}
                />
              )}
              {BoilerData && BoilerData.card_type === "DescibeYourBoiler" && (
                <DescribeYourBoiler
                  title={BoilerData && BoilerData.boiler_title}
                  Options={BoilerData && BoilerData.optiones}
                  modal_description={BoilerData && BoilerData.more_details_text}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  handleSelect={handleSelect}
                  handelModal={handelModal}
                  modalPresent={BoilerData && BoilerData.modal_present}
                />
              )}
              {BoilerData && BoilerData.card_type === "SmallCard" && (
                <SmallCard
                  title={BoilerData && BoilerData.boiler_title}
                  Options={BoilerData && BoilerData.optiones}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  handelModal={handelModal}
                  handleSelect={handleSelect}
                  modalPresent={BoilerData && BoilerData.modal_present}
                />
              )}
              {BoilerData && BoilerData.card_type === "mediumCard" && (
                <MediumCard
                  title={BoilerData && BoilerData.boiler_title}
                  Options={BoilerData && BoilerData.optiones}
                  modal_description={BoilerData && BoilerData.more_details_text}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  handleSelect={handleSelect}
                  modalPresent={BoilerData && BoilerData.modal_present}
                  handelModal={handelModal}
                />
              )}
              {BoilerData && BoilerData.card_type === "numCard" && (
                <NumCard
                  title={BoilerData && BoilerData.boiler_title}
                  Options={BoilerData && BoilerData.optiones}
                  modal_description={BoilerData && BoilerData.more_details_text}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  handleSelect={handleSelect}
                  modalPresent={BoilerData && BoilerData.modal_present}
                  handelModal={handelModal}
                />
              )}
              {BoilerData && BoilerData.card_type === "SmallWidthCard" && (
                <SmallWidthCard
                  title={BoilerData && BoilerData.boiler_title}
                  Options={BoilerData && BoilerData.optiones}
                  modal_description={BoilerData && BoilerData.more_details_text}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  handleSelect={handleSelect}
                  handelModal={handelModal}
                  modalPresent={BoilerData && BoilerData.modal_present}
                />
              )}
              {BoilerData && BoilerData.card_type === "CardWithImage" && (
                <CardWithImage
                  title={BoilerData && BoilerData.boiler_title}
                  Options={BoilerData && BoilerData.optiones}
                  modal_description={BoilerData && BoilerData.more_details_text}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  handleSelect={handleSelect}
                  modalPresent={BoilerData && BoilerData.modal_present}
                  handelModal={handelModal}
                />
              )}
              {BoilerData && BoilerData.card_type === "card" && (
                <Card
                  title={BoilerData && BoilerData.boiler_title}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  Options={BoilerData && BoilerData.optiones}
                  handleSelect={handleSelect}
                  modalPresent={BoilerData && BoilerData.modal_present}
                  handelModal={handelModal}
                />
              )}
              {BoilerData && BoilerData.card_type === "BathTubCard" && (
                <BathTubCard
                  title={BoilerData && BoilerData.boiler_title}
                  Options={BoilerData && BoilerData.optiones}
                  modal_description={BoilerData && BoilerData.more_details_text}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  handleSelect={handleSelect}
                  modalPresent={BoilerData && BoilerData.modal_present}
                  handelModal={handelModal}
                />
              )}
              {BoilerData && BoilerData.card_type === "NumOfRadiatorsCard" && (
                <NumOfRadiatorsCard
                  title={BoilerData && BoilerData.boiler_title}
                  questionSubTitle={BoilerData && BoilerData.boiler_subtitle}
                  Options={BoilerData && BoilerData.optiones}
                  handleSelect={handleSelect}
                  handelModal={handelModal}
                  modalPresent={BoilerData && BoilerData.modal_present}
                />
              )}
            </>
          )}
          {/* {currentQuestionIndex == null && <ContactCard />} */}
        </>
      )}
      {showModal && (
        <div className="mainModalContainer">
          <div className="modalContainer">
            <div onClick={handelModal} className="closeButton">
              X
            </div>
            <p className="modalPara">
              {BoilerData && BoilerData.more_details_text}{" "}
              <a href={`tel:${BoilerData && BoilerData.number_availble_que}`}>
                {BoilerData.number_availble_que}
              </a>
            </p>
          </div>
        </div>
      )}

      {currentQuestionIndex > 4 && !form && (
        <div>
          <button
            type="button"
            className={`question-nav-back `}
            onClick={handleBackClick}
          >
            {/* <i className="fa fa-chevron-left"></i>{" "} */}
            <span className="question-nav-back-texts is-size-5">Back </span>
          </button>
          {/* <button onClick={handleBackClick}>
           <span>Back</span>
         </button> */}
        </div>
      )}

      {contactform && (
        <div>
          <button
            type="button"
            className={`question-nav-back `}
            onClick={() => setContactForm(false)}
          >
            {/* <i className="fa fa-chevron-left"></i>{" "} */}
            <span className="question-nav-back-texts">Back </span>
          </button>
          {/* <button onClick={handleBackClick}>
           <span>Back</span>
         </button> */}
        </div>
      )}
    </div>
  );
};

export default GetAquote;
