import React, { useState } from "react";
import Accordion from "./accordion.js";
import data from "./SEI-data.js";
import { useSelector } from "react-redux";

const EveryThingIncludedModal = ({ isVisible, onClose }) => {
  const modalCloseHandler = () => {
    onClose(false);
  };
  const { whatsIncludedData, isWhatsIncludedDataLoading } = useSelector(
    (state) => state.getProductsDetails
  );

  // const IncludedData = whatsIncludedData.IncludedData;

  return (
    <>
      {isVisible && (
        <div className="modal is-active is-flex is-flex-direction-row is-justify-content-end">
          <div
            className="modal-background"
            onClick={() => onClose(false)}
          ></div>
          <div className="new-modal-card">
            <div className="column is-flex is-flex-direction-column py-2">
              {/* Close Modal Btn */}
              <button
                className="delete button"
                aria-label="close"
                onClick={modalCloseHandler}
              ></button>

              {/* Modal Title */}
              <div className="modal-text-box ">
                <h1 className="modal-heading">
                  {" "}
                  Included in your fixed price.{" "}
                </h1>
                <p className="modal-sub-heading">
                  {" "}
                  We include everything needed to make your installation
                  hassle-free and your heating system run like a dream.{" "}
                </p>
              </div>

              {/* Data Mapping */}
              <div>
                {whatsIncludedData &&
                Array.isArray(whatsIncludedData.IncludedData) ? (
                  <div className="column is-flex is-flex-direction-column is-justify-content-left">
                    {whatsIncludedData.IncludedData.length > 0 &&
                      whatsIncludedData.IncludedData.map((item) => {
                        return <Accordion {...item} key={item.id} />;
                      })}
                  </div>
                ) : (
                  <div
                    className="column
                  is-flex
                  is-flex-direction-column
                  is-justify-content-center
                  is-align-items-center"
                  >
                    <p className="is-size-5 has-text-centered mt-5 no-data-text">
                      No data available
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EveryThingIncludedModal;
