import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedSmartDevice } from "../../../scenes/products/redux/bucketReducer";
import { ROUTES } from "../../../utils/constants";
import { router } from "../../../scenes/router/Router";
import { setRoute } from "../../../scenes/router/redux/reducer";
import { GET_CART_PRODUCT_DETAILS } from "./redux/CartAction";
import { postAddProductTocartSuccess } from "./redux/CartReducer";
const Cart = ({ handleNext }) => {
  const dispatch = useDispatch();
  const { CartData } = useSelector(({ addProductToCart }) => addProductToCart);
  const { currencyPhoneEmail, isLoadingdynamicCurrrencyandPhone } = useSelector(
    ({ getBoilerQuote }) => getBoilerQuote
  );
  const selectProduct = useSelector((selectProduct) => selectProduct);
  const getBoilerBrand = selectProduct.bucketReducer.selectedProduct;

  const handleChangeBoiler = () => {
    dispatch(setRoute(ROUTES.products));
    router(ROUTES.products);
  };
  console.log("grand total", CartData.grand_total);
  // useEffect(() => {
  //   let payload = {
  //     email: "ajay@hotmail.com",
  //   };
  //   dispatch({ type: GET_CART_PRODUCT_DETAILS, payload: payload });
  // });
  const handleEmailMyQuote = () => {};
  return (
    <div className="price-summery-box">
      <h5 className="title is-5 px-2">Price including installation from</h5>
      <hr />
      <div className="product-price-box px-2">
        <div className="single-price">
          <div className="single-price-total">
            {true ? (
              <p className="title is-2 mb-4">
                {" "}
                {CartData && CartData.grand_total ? (
                  <>
                    {" "}
                    {currencyPhoneEmail.company_currency}
                    {Number(CartData.grand_total).toLocaleString()}
                  </>
                ) : (
                  <>0</>
                )}
              </p>
            ) : (
              <span className="subtitle is-5">Empty Bucket</span>
            )}
          </div>
          <div className="single-price-subtext smalltext">Including VAT</div>
          <div className="single-price-finance smalltext">
            <span>
              or from{" "}
              <a> {currencyPhoneEmail.company_currency} 27.84 per month</a>{" "}
              {currencyPhoneEmail.company_vat}% APR
            </span>
          </div>
        </div>
      </div>
      <div className="main-btn-sec">
        {/* Design Changes - 03/02 */}
        {/* <button className="home-survey" onClick={handleEmailMyQuote}>
          Email my quote <i class="fa fa-envelope ml-auto" aria-hidden="true" ></i>
        </button> */}
        <button className="basket" onClick={() => handleNext()}>
          Next <i class="fa-solid fa-angle-right"></i>
        </button>
      </div>

      <div className="p-2">
        <div className="shopping-basket-item">
          <div className="shopping-basket-item-sec">
            <i class="fa-solid fa-fire"></i> {CartData && CartData.boiler_title}
          </div>
          <div className="shopping-basket-product-price">
            {currencyPhoneEmail.company_currency}{" "}
            {CartData && CartData.boiler_cost
              ? Number(CartData.boiler_cost).toLocaleString()
              : 0}
          </div>
        </div>
        <div className="shopping-basket-item">
          <div className="left-b" onClick={() => handleChangeBoiler()}>
            <i class="fa-solid fa-sliders"></i> <a>Change boiler</a>
          </div>
          <div className="right-b">
            {/* <a>View details</a> */}
            <p>{getBoilerBrand.description}</p>
          </div>
        </div>

        <div className="shopping-basket-items">
          {CartData && CartData.controls_name && (
            <div className="shopping-basket-item">
              <div className="shopping-basket-item-sec">
                <i class="fa-solid fa-circle-check"></i>{" "}
                {CartData.controls_name}
              </div>
              <div className="shopping-basket-product-price">
                + {currencyPhoneEmail.company_currency}
                {Number(CartData.controls_price).toLocaleString()}
              </div>
            </div>
            // </div>
          )}
          {CartData && CartData.title && (
            <div className=" shopping-basket-item">
              <div className="shopping-basket-item-sec">
                <i class="fa-solid fa-circle-check"></i> {CartData.title}
              </div>
              <div className="shopping-basket-product-price">
                {" "}
                + {currencyPhoneEmail.company_currency}
                {Number(CartData.thermostate_price).toLocaleString()}
              </div>
            </div>
            // </div>
          )}
          {CartData && CartData.type && (
            <div className="shopping-basket-item">
              <div className="shopping-basket-item-sec">
                <i class="fa-solid fa-circle-check"></i> {CartData.type}
              </div>
              <div className="shopping-basket-product-price is-flex is-justify-content-space-between">
                {" "}
                <p className="is-size-6"></p> +{" "}
                {currencyPhoneEmail.company_currency}
                {Number(CartData.radiator_price).toLocaleString()}{" "}
                <span className=" has-text-danger">
                  {" "}
                  {/* ({CartData.radiator_qty}){" "} */}
                </span>
              </div>
            </div>
          )}
          {CartData && CartData.volvo_title && (
            <div className=" shopping-basket-item">
              <div className="shopping-basket-item-sec">
                <i class="fa-solid fa-circle-check"></i> {CartData.volvo_title}
              </div>
              <div className="shopping-basket-product-price">
                {" "}
                + {currencyPhoneEmail.company_currency}
                {CartData.volvoe_price}{" "}
                {/* <span className="has-text-danger">({CartData.volvoe_qty})</span> */}
              </div>
            </div>
          )}
          {CartData && CartData.convert_combi_price && (
            <div className=" shopping-basket-item">
              <div className="shopping-basket-item-sec">
                <i class="fa-solid fa-circle-check"></i> Extra Added price for
                Combi Boiler
              </div>
              <div className="shopping-basket-product-price">
                {" "}
                + {currencyPhoneEmail.company_currency}
                {CartData.convert_combi_price}{" "}
                {/* <span className="has-text-danger">({CartData.volvoe_qty})</span> */}
              </div>
            </div>
          )}
          {CartData && CartData.upgrade_unwanted_price && (
            <div className=" shopping-basket-item">
              <div className="shopping-basket-item-sec">
                <i class="fa-solid fa-circle-check"></i> Extra Added price for
                Hot Water cylinder
              </div>
              <div className="shopping-basket-product-price">
                {" "}
                + {currencyPhoneEmail.company_currency}
                {CartData.upgrade_unwanted_price}{" "}
                {/* <span className="has-text-danger">({CartData.volvoe_qty})</span> */}
              </div>
            </div>
          )}
          {CartData && CartData.upgrade_unwanted_price && (
            <div className=" shopping-basket-item">
              <div className="shopping-basket-item-sec">
                <i class="fa-solid fa-circle-check"></i> Extra Added price for
                Hot Water cylinder
              </div>
              <div className="shopping-basket-product-price">
                {" "}
                + {currencyPhoneEmail.company_currency}
                {CartData.upgrade_unwanted_price}{" "}
                {/* <span className="has-text-danger">({CartData.volvoe_qty})</span> */}
              </div>
            </div>
          )}
          {CartData && CartData.flue_comesout_price && (
            <div className=" shopping-basket-item">
              <div className="shopping-basket-item-sec">
                <i class="fa-solid fa-circle-check"></i> Extra Added price for
                Flue comes Out from roof
              </div>
              <div className="shopping-basket-product-price">
                {" "}
                + {currencyPhoneEmail.company_currency}
                {CartData.flue_comesout_price}{" "}
                {/* <span className="has-text-danger">({CartData.volvoe_qty})</span> */}
              </div>
            </div>
          )}

          {CartData && CartData.additional && (
            <div className=" shopping-basket-item">
              <div className="shopping-basket-item-sec">
                <i class="fa-solid fa-circle-check"></i> Extra Added price
              </div>
              <div className="shopping-basket-product-price">
                {" "}
                + {currencyPhoneEmail.company_currency}
                {/* {CartData.additional[0].price}{" "} */}
                {CartData.additional.reduce(
                  (total, item) => total + parseInt(item.price),
                  0
                )}{" "}
              </div>
            </div>
          )}

          <div className=" shopping-basket-item">
            <div className="shopping-basket-item-sec">
              <i class="fa-solid fa-tag"></i> <b>Grand total</b>
            </div>

            <div className="shopping-basket-product-price">
              <p className="title is-4">
                {" "}
                {CartData && CartData.grand_total ? (
                  <>
                    {" "}
                    {currencyPhoneEmail.company_currency}
                    {Number(CartData.grand_total).toLocaleString()}
                  </>
                ) : (
                  <>0</>
                )}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="price-box-features">
        <p>
          <i class="fa-solid fa-gears"></i> Installation Included
        </p>
      </div>
    </div>
  );
};

export default Cart;
