import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isgettingShowPaymentDetailsLoading: false,
  PaymentDetailsData: [],
};

const fetchShowpaymentDetailsDataslice = createSlice({
  name: "getPaymentDetailsData",
  initialState,
  reducers: {
    fetchshowPaymentDetailsStarted(state) {
      state.isgettingShowPaymentDetailsLoading = true;
    },
    fetchshowPaymentDetailsSuccess(state, { payload }) {
      state.isgettingShowPaymentDetailsLoading = false;
      state.PaymentDetailsData = payload;
    },
    fetchshowPaymentDetailsFail(state, { payload }) {
      state.isgettingShowPaymentDetailsLoading = false;
      state.PaymentDetailsData = payload;
    },
  },
});
const { actions, reducer } = fetchShowpaymentDetailsDataslice;
export const {
  fetchshowPaymentDetailsFail,
  fetchshowPaymentDetailsSuccess,
  fetchshowPaymentDetailsStarted,
} = actions;

export default reducer;
