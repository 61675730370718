import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRoute } from "../router/redux/reducer";
import { ROUTES } from "../../utils/constants";
import { router } from "../router/Router";
import "./AddARadiator.module.css";
import "../../styles/styles.css";
import ProductInfoModal from "../../myComponent/molecule/Modal/ProductInfoModal";
import {
  GET_RADIATOR,
  GET_RADIATOR_HEIGHT,
  GET_RADIATOR_LENGTH,
  GET_RADIATOR_PRICE_BTU,
  GET_RADIATOR_TYPE,
  PRODUCT_RADIATOR_INFO_DATA,
} from "./redux/AddaRadiatorAction";
import Cart from "../../myComponent/organisms/cart/Cart";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ADD_PRODUCT_TO_BASKET,
  REMOVE_CART_ITEM,
} from "../../myComponent/organisms/cart/redux/CartAction";
import HelpMeChooseRadiator from "../../myComponent/molecule/Modal/HelpMeChooseRadiator";
import { Image, Shimmer } from "react-shimmer";
const AddARadiator = () => {
  const [quantity, setQuantity] = useState(1);
  const [typeSelected, setTypeSelected] = useState("Signle Panel Radiator");
  const [typeOptionChosen, setTypeOptionChosen] = useState(false);
  const [dimensionSelected, setDimensionSelected] = useState(1);
  const [dimensionOptionChosen, setDimensionOptionChosen] = useState(false);
  const [helpmechooseRadiatorModal, setHelpMeChooseRadiatorModal] = useState(
    false
  );
  const {
    isAddProductToCartSubmitting,
    addProductToCartSuccess,
    addProductToCardError,
  } = useSelector(({ addProductToCart }) => addProductToCart);
  const { CartData } = useSelector(({ addProductToCart }) => addProductToCart);
  const {
    isLoading,
    getARadiatorData,
    radiatorheightdata,
    isLoadingradiatorheight,
    radiatorLengthdata,
    productDataOfradiator,
    RadiatorpriceandBTUData,
    getRadiatorImageData,
  } = useSelector((state) => state.getARadiator);
  const { currencyPhoneEmail, isLoadingdynamicCurrrencyandPhone } = useSelector(
    ({ getBoilerQuote }) => getBoilerQuote
  );
  // modal stat

  const RadiatorNameType = getARadiatorData.filter(
    (item, index) =>
      getARadiatorData.findIndex((obj, i) => obj.type == item.type) == index
  );

  const [isProductInfoModal, setIsProductInfoModal] = useState(false);

  const dispatch = useDispatch();

  const handleNext = () => {
    dispatch(setRoute(ROUTES.addValves));
    const call = () => {
      return router(ROUTES.addValves);
    };
    setTimeout(call, 500);
  };
  useEffect(() => {
    dispatch({ type: GET_RADIATOR_TYPE });
  }, []);

  const getRadiatorheight = (item) => {
    const payload = {
      type: item,
    };
    dispatch({ type: GET_RADIATOR_HEIGHT, payload: payload });
  };

  const handle_getRadiatorlength = (item) => {
    const [height, type] = item.split(",");
    const payload = {
      type: type,
      height: height,
    };
    dispatch({ type: GET_RADIATOR_LENGTH, payload: payload });
  };

  const AddRadiatorToCart = (id, e) => {
    e.preventDefault();
    const payload = {
      radiator_id: id,

      product_id: CartData.product_id,
      thermostat_id: CartData.thermostate_id ? CartData.thermostate_id : 0,

      controler_id: CartData.control_id ? CartData.control_id : 0,
      volve_id: CartData.volve_id ? CartData.volve_id : 0,
      volvo_qty: CartData.volvoe_qty && CartData.volvoe_qty,
      radiator_qty: quantity,
    };

    dispatch({ type: ADD_PRODUCT_TO_BASKET, payload });
  };

  const handleAdd = () => {
    if (quantity < 10) {
      setQuantity(quantity + 1);
    }
  };

  const handleSub = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const handleToProductScreen = () => {
    dispatch(setRoute(ROUTES.products));
  };

  const handle_getRadiatorPrice = (typeSelected, dimensionSelected) => {
    const payload = {
      type: typeSelected,
      value: dimensionSelected,
    };
    dispatch({ type: GET_RADIATOR_PRICE_BTU, payload: payload });
  };
  useEffect(() => {
    dispatch({ type: PRODUCT_RADIATOR_INFO_DATA });
  }, []);
  useEffect(() => {
    dispatch({ type: GET_RADIATOR });
  }, []);

  const handleRemoveRadiator = (id) => {
    const payload = {
      radiator_id: id,
    };
    dispatch({ type: REMOVE_CART_ITEM, payload });
  };

  const ChoiceRadiatorName = [
    { type: "Single Panel Radiator", value: "Signle Panel Radiator" },
    { type: "Double Panel Radiator", value: "Double Panel Radiator" },
  ];

  const ChoiceRadiatorDimension = [
    { type: "Upto - 100cm wide", value: "1" },
    { type: "100 cm- 200cm wide", value: "2" },
  ];

  if (isLoading) {
    return (
      <>
        <div className="loaderContainer">
          <div className="mainLoader"></div>
        </div>
      </>
    );
  }
  const price = quantity * getARadiatorData.price;
  return (
    <>
      <div className="is-max-desktop radiator-adjust">
        <div className="columns is-flex-direction-row is-flex-wrap-wrap is-justify-content-center is">
          <div className="column is-two-third ">
            <div className="columns is-multiline ">
              <div className="column ">
                <div className={`control-box is-one-third p-5 `}>
                  {/* <div className="card-content"> */}
                  <div className="is-flex is-flex-direction-column is-justify-content-center">
                    {/* <div className="column">
                        <div className="columns is-flex is-flex-direction-row is-justify-content-space-between">
                          <div className="column">
                            <span className="has-text-left is-size-5">
                              Stelrad Softline Compact
                            </span>
                          </div>
                          <div
                            className="column"
                            onClick={() => setIsProductInfoModal(true)}
                          >
                            <span className="is-size-6 has-text-success viewtextinstallation">
                              {" "}
                              Product information{" "}
                            </span>
                            <span class="icon">
                              <i class="fas fa-magnifying-glass"></i>
                            </span>
                            <span></span>
                          </div> 
                        </div>
                      </div> */}

                    {/* demo for columns */}
                    <div className="column">
                      <div className="columns">
                        <div className="column r-img-sec">
                          <figure className="r-imagebox">
                            <img
                              className=""
                              decoding="async"
                              src="https://wbt-public.b-cdn.net/rad-img.jpg"
                            />
                          </figure>

                          <div className="r-title-sec new-title-sec title-sec p-5">
                            <h2>Replace existing radiator</h2>
                            <p>
                              {" "}
                              Choose up to 10 new radiators and we’ll fit these
                              during your boiler installation.We can only fit
                              new radiators in place of existing ones, not in
                              new locations.{" "}
                            </p>

                            <br></br>
                            <p>
                              Our engineers will also check which ones you would
                              like replacing.
                              {/* Design Changes - 03/02 */}
                              {/* <a className="is-underlined"> See our FAQs</a> for
                              more information. */}
                            </p>
                          </div>
                        </div>

                        {/* Option 1 */}
                        <div className="column">
                          <div className="column is-flex is-flex-direction-row is-justify-content-space-between ">
                            <span>Type</span>
                            <div></div>
                          </div>
                          <div className=" ">
                            <div className="column">
                              <select
                                className="input is-hovered r-input"
                                value={typeSelected}
                                onChange={(e) => {
                                  setTypeSelected(e.target.value);
                                  setTypeOptionChosen(true);
                                  {
                                    dimensionOptionChosen &&
                                      handle_getRadiatorPrice(
                                        e.target.value,
                                        dimensionSelected
                                      );
                                  }
                                }}
                              >
                                {" "}
                                <option value="">Choose type</option>
                                {ChoiceRadiatorName.map((item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          {/* Option 2 */}

                          <div className="column r-text-box">
                            <i class="fas fa-circle-info"></i>
                            <p>
                              If we don’t offer the exact size you need please
                              choose the nearest smaller size to your current
                              radiator.
                            </p>
                          </div>
                          <div className="column is-flex is-flex-direction-row is-justify-content-space-between px-3 py-1">
                            <span className="is-5">Select dimension(cm)</span>
                          </div>
                          <div className=" ">
                            <div className="column">
                              <select
                                className="input is-hovered r-input"
                                value={dimensionSelected}
                                onChange={(e) => {
                                  setDimensionSelected(e.target.value);
                                  setDimensionOptionChosen(true);
                                  handle_getRadiatorPrice(
                                    typeSelected,
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="" disabled>
                                  Select Dimension
                                </option>
                                {ChoiceRadiatorDimension.map((item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          {/* <div className="column is-flex is-flex-direction-row is-justify-content-space-between px-3 py-1">
                            <span className="is-5">Height(mm)</span>
                          </div>
                          <div className=" ">
                            <div className="column">
                              <select
                                className="input is-hovered r-input"
                                disabled={!radiatorheightdata}
                                onClick={(e) =>
                                  handle_getRadiatorlength(e.target.value)
                                }
                              >
                                <option value="" disabled>
                                  Select Height
                                </option>
                                {RadiatorNameType &&
                                  radiatorheightdata &&
                                  radiatorheightdata.map((item, index) => (
                                    <option
                                      key={index}
                                      value={`${item.height},${item.type}`}
                                    >
                                      {item.height}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div> */}

                          {/* <div className="column is-flex is-flex-direction-row is-justify-content-space-between px-3 py-1">
                            <span className="is-5">Length(mm)</span>
                          </div>
                          <div className=" ">
                            <div className="column">
                              <select
                                className="input is-hovered r-input"
                                onClick={(e) =>
                                  handle_getRadiatorPrice(e.target.value)
                                }
                              >
                                <option value="" disabled>
                                  Select length
                                </option>
                                {RadiatorNameType &&
                                  radiatorheightdata &&
                                  radiatorLengthdata &&
                                  radiatorLengthdata.map((item, index) => (
                                    <option
                                      value={`${item.length},${item.type},${item.height}`}
                                      key={index}
                                    >
                                      {item.length}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div> */}
                          {RadiatorpriceandBTUData &&
                            RadiatorpriceandBTUData.map((item, index) => {
                              return (
                                <>
                                  {item.btu && (
                                    <>
                                      <div
                                        className="column is-flex is-flex-direction-row is-justify-content-space-between p-3"
                                        key={index}
                                      >
                                        <span>
                                          Total BTU for this radiator is :
                                        </span>
                                        <b>
                                          <i class="fa-solid fa-temperature-half"></i>{" "}
                                          {item.btu}
                                        </b>
                                      </div>
                                    </>
                                  )}
                                  <div className="column">
                                    <div className="r-price-sec">
                                      <p className="r-price">
                                        {/* {console.log(item.price)} */}
                                        {currencyPhoneEmail.company_currency
                                          ? currencyPhoneEmail.company_currency
                                          : "£"}
                                        {item.price * quantity}
                                      </p>
                                      <p className="has-text-center is-size-7 has-text-success">
                                        incl VAT & installation
                                      </p>
                                    </div>
                                    <div>
                                      <button
                                        onClick={handleSub}
                                        className=" m-1 has-background-white button  "
                                      >
                                        {" "}
                                        -{" "}
                                      </button>
                                      <button className="button m-1 has-background-white">
                                        {quantity}
                                      </button>
                                      <button
                                        onClick={handleAdd}
                                        className="button  m-1 has-background-white"
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                  <div className="column">
                                    <p>Add up to 10 radiators to your order.</p>
                                  </div>
                                  <div className="column main-btn-sec">
                                    <button
                                      className="home-survey "
                                      onClick={(e) =>
                                        AddRadiatorToCart(item.id, e)
                                      }
                                    >
                                      {isAddProductToCartSubmitting ? (
                                        <span class="loader"></span>
                                      ) : (
                                        <>
                                          {" "}
                                          Add to basket{" "}
                                          <span className="pl-5">
                                            <i class="fas fa-plus"></i>
                                          </span>{" "}
                                        </>
                                      )}
                                    </button>
                                  </div>
                                  {CartData.radiator_id === item.id && (
                                    <div
                                      className="column main-btn-sec"
                                      style={{ marginTop: "-10px" }}
                                    >
                                      <button
                                        className={`button ${"is-danger"}`}
                                        onClick={() => {
                                          handleRemoveRadiator(item.id);
                                        }}
                                      >
                                        Remove from basket
                                      </button>
                                    </div>
                                  )}
                                  {/* <div className="column main-btn-sec">
                                    <button
                                      className={`button ${"is-danger"}`}
                                      onClick={() => {
                                        handleRemoveRadiator(item.id);
                                      }}
                                    >
                                      Remove from basket
                                    </button>
                                  </div> */}
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className=" column is-one-third ">
            <Cart handleNext={handleNext} />
          </div>
        </div>
      </div>

      {/* modals */}
      <ProductInfoModal
        isVisible={isProductInfoModal}
        onClose={setIsProductInfoModal}
        // Data={RadiatorInformationData}
      />
      <HelpMeChooseRadiator
        helpmechooseRadiatorModal={helpmechooseRadiatorModal}
        setHelpMeChooseRadiatorModal={setHelpMeChooseRadiatorModal}
      />
    </>
  );
};

export default AddARadiator;
