import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedProduct: {
    brand_price: "0",
  },
  selectedController: {
    price: "0",
  },
  selectedSmartdevice: {
    price: "0",
  },
};

const bucketSlice = createSlice({
  name: "bucket",
  initialState,
  reducers: {
    selectProduct(state, { payload }) {
      state.selectedProduct = payload;
    },
    selectedBoiler(state, { payload }) {
      state.selectedController = payload;
    },
    selectedSmartDevice(state, { payload }) {
      state.selectedSmartdevice = payload;
    },
  },
});

const { actions, reducer } = bucketSlice;

export const { selectProduct, selectedBoiler, selectedSmartDevice } = actions;
export default reducer;
