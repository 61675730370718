import React  from "react";
import "./../../../../styles/styles.css";

const Card = ({ title, Options, handleSelect, questionSubTitle, modalPresent }) => {
  const [showing, setShowingData] = React.useState(false);
  const [bgColor, setBgcolor] = React.useState("");
  const isShowing = () => {
    if (showing == true) {
      setShowingData(false);
      setBgcolor("");
    } else {
      setShowingData(true);
      setBgcolor("#f2761a");
    }
  };

  return (
    <section className="section has-text-centered" id="Section3">
      <div className="is-max-smalldesktop">
        <div className="step-title">
        <h1 className="title is-2 has-text-weight-bold">{title}</h1>
        </div>
        <div className="step-subtitle">
          <p className="subtitle">{questionSubTitle}</p>
        </div>
        <div className="step-answers columns is-9">
          {Options.map((item, index) => {
            return (
              <div key={index} onClick={() =>
                handleSelect({item})} className="column">
                <div className=" card card-is-1" >
                  <i className="card-main-icon">
                    <img
                      className="icon"
                      src={`/image/${item.option_icon}.svg`}
                    />
                  </i>
                  <div className="card-title">
                    <h3>{item.option_title}</h3>
                  </div>
                  <span className="card-select">Select</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Card;
