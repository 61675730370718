import { call, put } from "redux-saga/effects";
import apiClient from "../../../services/httpServices";
import {
  fetchshowPaymentDetailsFail,
  fetchshowPaymentDetailsSuccess,
  fetchshowPaymentDetailsStarted,
} from "./PaymentSuccessReducer";
import { SHOW_DATA_PAYMENT } from "../../../services/webConstant";

export function* getShowpaymentDetailsSaga(action) {
  try {
    yield put(fetchshowPaymentDetailsStarted());

    const paymentDataResponse = yield call(
      apiClient.get,
      SHOW_DATA_PAYMENT.SHOW_PAYMENT_DATA
    );
    yield put(fetchshowPaymentDetailsSuccess(paymentDataResponse));
  } catch {
    yield put(fetchshowPaymentDetailsFail());
  }
}
