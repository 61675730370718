import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SAVE_BOILER_QUOTE } from "../../../scenes/get-a-quote/redux/GetAquoteAction";
import { setRoute } from "../../../scenes/router/redux/reducer";
import { clientDetailsSelector } from "../../../stores/selectors";
import { ROUTES } from "../../../utils/constants";
import PrivacyPolicyModal from "../../molecule/Modal/PrivacyPolicyModal";
import TermsAndConditionModal from "../../molecule/Modal/TermsAndConditionModal";
import styles from "./info-form.module.css";
import {
  GET_PRIVACY_POLICY,
  GET_TERM_CONDITIONS,
  PHONE_VALIDATION,
  POST_QUOTE_EMAIL,
} from "./redux/InfoFormAction";

const InfoForm = () => {
  const dispatch = useDispatch();
  const nameRegex = /^[A-Za-z\s]*$/;

  const [termAndCondition, setTermAndCondition] = useState(false);
  const [privacypolicydata, setPrivacypolicyData] = useState(false);
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState("");
  const { isValidatingPhoneNumberLoading, phonevalidateData } = useSelector(
    (state) => state.getTermAndCondition
  );
  const { clientData } = useSelector(clientDetailsSelector);

  const {
    phoneNumberValidateError,
    emailValidateError,
    selectedOptions,
    loading,
  } = useSelector(({ getBoilerQuote }) => getBoilerQuote);
  const { options } = useSelector(({ getFormDetails }) => getFormDetails);
  let address = options.payload.address;
  let postalCode = options.payload.postCode;

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      address: address ? address : "",
      postcode: postalCode ? postalCode : "",
    },
  });

  const Selectedoption = selectedOptions.map((item) => ({
    questID: item.questionId,
    selectedOption: item.optionSelected.item.id,
  }));

  const StoresQuotesData = JSON.stringify(Selectedoption);
  sessionStorage.setItem("StoresQuotesData", StoresQuotesData);

  const onSubmit = (data) => {
    const payload = {
      Client_id: clientData.id,
      First_Name: data.firstName,
      Last_Name: data.lastName,
      Email_Id: data.email,
      Post_Code: data.postcode,
      Address: data.address,
      phoneNumber: data.phoneNumber,
      selctedOptions: Selectedoption,
    };

    const StoreFormData = {
      First_Name: data.firstName,
      Last_Name: data.lastName,
      Email_Id: data.email,
      phoneNumber: data.phoneNumber,
      Post_Code: data.postcode,
    };
    const jsonData = JSON.stringify(StoreFormData);
    localStorage.setItem("StoreFormData", jsonData);
    sessionStorage.setItem("userEmail", data.email);
    dispatch({
      type: SAVE_BOILER_QUOTE,
      payload,
    });
    dispatch({ type: POST_QUOTE_EMAIL, payload: { email: data.email } });
  };

  const handlePhoneNumberChange = (event) => {
    let key = "55614AEB5E07473CBE4ACDDBDB4CB3DC";
    const newPhoneNumber = event.target.value;

    const payload = {
      params: {
        phone: Number(newPhoneNumber),
        key: key,
      },
    };
    setPhoneNumberTouched(true);
    dispatch({ type: PHONE_VALIDATION, payload: payload });
  };
  useEffect(() => {
    setPhoneNumberValid(phonevalidateData);
  }, [phonevalidateData]);
  useEffect(() => {
    dispatch({ type: GET_TERM_CONDITIONS });
    dispatch({ type: GET_PRIVACY_POLICY });
  }, []);
  const handleToProduct = () => {
    dispatch(setRoute(ROUTES.addressComponents));
  };
  return (
    <>
      <section>
        <nav class="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-menu is-active">
            <div class="buttons p-3 " onClick={handleToProduct}>
              <a class="button is-primary">
                <strong>Back</strong>
              </a>
            </div>
          </div>
        </nav>
      </section>
      <section className="section has-text-centered" id="Section25">
        <div className="is-max-smalldesktop">
          <div>
            <h2 className="heading-bold">
              COMPLETE THE FORM TO SEE YOUR{" "}
              <span className={styles.spanText}>BOILER</span> OPTION{" "}
            </h2>
            <p className="subtitle is-5 mt-2">
              To display your boiler choice, price and finance option we simply
              require the bellow information
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className={styles.inform}>
            <div className="field">
              <input
                name="firstName"
                type="text"
                placeholder="Enter First Name"
                className="input is-medium"
                {...register("firstName", {
                  required: "First Name is required",
                  pattern: {
                    value: nameRegex,
                    message: "Invalid First Name",
                  },
                })}
              />
              {errors.firstName && (
                <span className="is-size-7-mobile ">
                  {errors.firstName.message}*
                </span>
              )}
            </div>
            <div className="field">
              <input
                name="lastName"
                type="text"
                placeholder="Enter Last Name"
                className="input is-medium"
                {...register("lastName", {
                  required: "Last Name is required",
                  pattern: {
                    value: nameRegex,
                    message: "Invalid Last Name",
                  },
                })}
              />
              {errors.lastName && (
                <span className="is-size-7-mobile ">
                  {errors.lastName.message}*
                </span>
              )}
            </div>

            <div className="field">
              <input
                name="email"
                type="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                placeholder=" Enter Email Address"
                className="input is-medium"
                {...register("email", { required: true })}
                // onChange={handleEmailInputChange}
              />
              {errors.email && (
                <span className="is-size-7-mobile ">
                  {errors.email.message}*
                </span>
              )}
            </div>
            <div className="field">
              <input
                name="address"
                type="text"
                placeholder=" Enter address"
                className="input is-medium"
                defaultValue={address}
                {...register("address", { required: true })}
                // readOnly
              />
            </div>
            <div className="field">
              <input
                name="postcode"
                type="text"
                // pattern="/^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]|[A-HK-Y][0-9]([0-9]|[ABEHMNPRV-Y])))[ ]?[0-9][ABD-HJLNP-UW-Z]{2})$/"
                placeholder=" Enter  Postcode"
                className="input is-medium"
                defaultValue={postalCode}
                {...register("postcode", { required: true })}
                // readOnly
              />
              {errors.postcode && (
                <span className="is-size-7-mobile ">
                  Please enter a valid postcode*
                </span>
              )}
            </div>
            <div className="field">
              <div
                className={`control ${
                  isValidatingPhoneNumberLoading
                    ? "is-loading "
                    : phoneNumberValid
                    ? "has-icons-right"
                    : "is-medium"
                }`}
              >
                <input
                  name="phoneNumber"
                  type="tel"
                  // maxLength={10}
                  placeholder="Enter Phone Number"
                  className={`input is-medium ${
                    phoneNumberValid ? "input is-medium" : ""
                  }`}
                  {...register("phoneNumber", { required: true })}
                  onChange={handlePhoneNumberChange}
                />

                {phoneNumberTouched && phoneNumberValid ? (
                  <span className="icon is-right has-text-success">
                    <i className="fas fa-check"></i>
                  </span>
                ) : (
                  ""
                )}
              </div>
              {phoneNumberTouched && (
                <span className="is-size-7-mobile ">
                  {phoneNumberValid == true ? "" : "Invalid Phone Number"}
                </span>
              )}
            </div>

            <div className={styles.formCard}>
              <h1>OUR PROMISE TO YOU:</h1>
              <div className={styles.paragraph}>
                <p> &#10004; We Promise we won't inudate you</p>
                <p>&#10004; We'll keep it relevent</p>
                <p>&#10004; We'll never share your details</p>
              </div>
            </div>
            <div className="control my-5 is-flex is-flex-direction-row is-justify-content-center is-align-items-center mx-2">
              <div>
                <input type="checkbox" value=" " name="" id="" required />{" "}
              </div>
              <div>
                {" "}
                <p className="subtitle is-6 is-size-7-mobile ml-3">
                  I agree with{" "}
                  <u
                    className="viewtextinstallation  is-size-7-mobile"
                    onClick={() => setTermAndCondition(true)}
                  >
                    terms conditions
                  </u>{" "}
                  and{" "}
                  <u
                    className="viewtextinstallation  is-size-7-mobile"
                    onClick={() => setPrivacypolicyData(true)}
                  >
                    privacy policy.
                  </u>
                </p>
              </div>
            </div>
            <div className="main-btn-sec">
              <span type="submit" disabled={!phoneNumberValid}>
                {loading ? (
                  <span class="loader"></span>
                ) : (
                  <button className="home-survey">
                    {" "}
                    Show boiler <i class="fa-solid fa-arrow-right"></i>
                  </button>
                )}{" "}
              </span>
              <ToastContainer />
            </div>
          </form>
        </div>
      </section>

      <TermsAndConditionModal
        termAndCondition={termAndCondition}
        setTermAndCondition={setTermAndCondition}
      />
      <PrivacyPolicyModal
        privacypolicydata={privacypolicydata}
        setPrivacypolicyData={setPrivacypolicyData}
      />
    </>
  );
};

export default InfoForm;
