import React, { useState } from "react";
import CalenderComponent from "./CalenderComponent";
import { format } from "date-fns";
import "./modal.css";
import { enGB } from "date-fns/locale";
import { useForm } from "react-hook-form";
import CalenderNew from "./CalNew";

const BookHomeSurveyModal = ({ isVisible, onClose }) => {
  const [selectedDate, setSelectedDate] = useState();

  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [isVideoSurveySelected, setIsVideoSurveySelected] = useState(false);
  const [surveyMethod, setSurveyMethod] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onSubmitButton(data) {
    const formData = { ...data, selectedDate, surveyMethod };
    onClose(false);
  }

  const currentDate = new Date().toLocaleDateString("en-us", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return (
    <>
      {isVisible && (
        <div className="modal is-active is-flex is-flex-direction-row is-justify-content-end">
          <div
            className="modal-background"
            onClick={() => onClose(false)}
          ></div>
          <div className="new-modal-card">
            {/* Close Modal Btn */}
            <button
              className="delete button"
              aria-label="close"
              onClick={() => onClose(false)}
            ></button>

            {/* Modal Title */}
            <div className="modal-text-box ">
              <h1 className="modal-heading">
                {" "}
                Book your free home or video remote survey{" "}
              </h1>
              <p className="modal-sub-heading">
                {" "}
                Just find a day and time to suit you:{" "}
              </p>
            </div>

            {/* Calendar Component */}

            <div className="calander-box">
              {isCalendarVisible && selectedDate ? (
                <div
                  onClick={() => setIsCalendarVisible(!isCalendarVisible)}
                  className="date-reset-box is-flex"
                >
                  <div className="mr-2">
                    <p className="is-5 is-size-6-mobile has-text-weight-bold">
                      {selectedDate
                        ? format(selectedDate, "dd MMM yyyy", {
                            locale: enGB,
                          })
                        : currentDate}
                    </p>
                  </div>
                  <i className="fa-solid fa-arrows-rotate is-4"></i>
                </div>
              ) : (
                <div className="column">
                  <div
                    className=" is-flex is-flex-direction-column"
                    // onClick={(e) => {
                    //   e.stopPropagation();
                    //   setIsCalendarVisible(!isCalendarVisible);
                    // }}
                  >
                    {/* <CalenderComponent
                      date={selectedDate}
                      setDate={setSelectedDate}
                    /> */}
                    <CalenderNew
                      date={selectedDate}
                      setDate={setSelectedDate}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Form */}
            <form onSubmit={handleSubmit(onSubmitButton)}>
              {/* radio btns */}
              <div className="survey-time-selector">
                <div className="column">
                  <label>
                    <input
                      {...register("time", {
                        required: "Select the servey Time",
                      })}
                      type="radio"
                      value="10 AM"
                    />
                    &nbsp; 10 AM
                  </label>
                </div>
                <div className="column">
                  <label>
                    <input
                      {...register("time", {
                        required: "Select the servey Time",
                      })}
                      type="radio"
                      value="2 PM"
                    />
                    &nbsp; 12 PM
                  </label>
                </div>
                <div className="column">
                  <label>
                    <input
                      {...register("time", {
                        required: "Select the servey Time",
                      })}
                      type="radio"
                      value="4 PM"
                    />
                    &nbsp; 2 PM
                  </label>
                </div>
                <div className="column">
                  <label>
                    <input
                      {...register("time", {
                        required: "Select the servey Time",
                      })}
                      type="radio"
                      value="4 PM"
                    />
                    &nbsp; 4 PM
                  </label>
                </div>
              </div>
              <div className="column">
                {errors.time && (
                  <p className="error-message is-size-7-mobile">
                    {errors.time.message}
                  </p>
                )}
              </div>

              {/* survey */}
              <p className="modal-sub-heading mb-4">
                {" "}
                Would like a on-site or remote survey?{" "}
              </p>
              <div className="survey-time-selector">
                <div className="column">
                  <button
                    className={`survey-type-button ${
                      surveyMethod === "Remote Survey" ? "is-active" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setSurveyMethod("Remote Survey");
                      setIsVideoSurveySelected(!isVideoSurveySelected);
                    }}
                  >
                    Remote Survey
                  </button>
                </div>
                <div className="column">
                  <button
                    className={`survey-type-button ${
                      surveyMethod === "Home Survey" ? "is-active" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setSurveyMethod("Home Survey");
                      setIsVideoSurveySelected(false);
                    }}
                  >
                    On-Site Survey
                  </button>
                </div>
              </div>

              {/* Video Survey Radio btns */}

              {isVideoSurveySelected && (
                <div className="survey-time-selector">
                  <div className="column">
                    <label>
                      <input
                        {...register("videoSurveyMethod", {
                          required: "Select the servey Type",
                        })}
                        type="radio"
                        value="Zoom"
                      />
                      &nbsp; Zoom
                    </label>
                  </div>
                  <div className="column">
                    <label>
                      <input
                        {...register("videoSurveyMethod", {
                          required: "Select the servey Type",
                        })}
                        type="radio"
                        value="Microsoft Teams"
                      />
                      &nbsp; Microsoft Teams
                    </label>
                  </div>
                </div>
              )}
              {/* Postal Code */}

              <div className="postcode-box">
                <p>Postal Code: </p>
                <div className="post-code-input">
                  <input
                    {...register("postalCode", {
                      required: "Postal Code is required",
                      pattern: {
                        // value: /^[0-9]{5}$|^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/,
                        value: /^(?:\d{6}|([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/,
                        message: "Invalid Postal Code",
                      },
                    })}
                    type="text"
                    className="input"
                    placeholder="Enter your postal code here"
                  />
                  {errors.postalCode && (
                    <p className="error-message is-size-7-mobile">
                      {errors.postalCode.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Submit btn */}
              <button
                type="submit"
                className="submit-button"
                disabled={
                  !surveyMethod ||
                  (surveyMethod === "Remote Survey" &&
                    !isVideoSurveySelected) ||
                  Object.keys(errors).length > 0
                }
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default BookHomeSurveyModal;
