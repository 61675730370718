import { createSlice } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";

const initialState = {
  isfetchingProductsDetails: false,
  getProductsDetailsData: [],
  selectedOptions: [],
  fetchProductsError: "",
  isfetchingDropdownProducts: false,
  boilerBrandsDropdown: [],
  fetchDropdownError: "",
  postSaveEmail: [],
  error: null,
  whatsIncludedData: [],
  availabilityDate: [],
  isWhatsIncludedDataLoading: false,
  loading: false,
  isHelpChooseBoilerSubmitting: false,
  helpChooseBoilerSuccess: false,
  numberOfBedrooms: 0,
  saveQuoteSuccess: false,
  saveQuoteLoading: false,
  isgetUserBookingDetailsDataLoading: false,
  isgetUserBookingDetailsData: [],
  isgetAllSaveUserDataDetailsLoading: false,
  getAllUserDataSelected: [],
  fetchAdditionalCharges: 0,
};

const getProductsDetailslice = createSlice({
  name: "getProductsDetails",
  initialState,
  reducers: {
    fetchProductsDetailstarted(state) {
      state.isfetchingProductsDetails = true;
      state.loading = true;
    },
    fetchProductsDetailsuccess(state, { payload }) {
      state.isfetchingProductsDetails = false;
      state.getProductsDetailsData = payload.productData;

      state.loading = false;
      // state.addproductList= payload.addproduct
      state.numberOfBedrooms = payload.numberOfBedrooms;
    },
    fetchProductsDetailsFail(state) {
      state.isfetchingProductsDetails = false;
      state.loading = false;
    },

    fetchProductDropdownStarted(state) {
      state.isfetchingDropdownProducts = true;
      state.loading = true;
    },
    fetchProductDropdownSuccess(state, { payload }) {
      console.log("payload.boilerType", payload);
      state.isfetchingDropdownProducts = false;
      state.boilerBrandsDropdown = payload.boilerType;
      state.loading = false;
    },
    fetchProductDropdownFail(state) {
      state.isfetchingDropdownProducts = false;
      state.loading = false;
    },
    sortedData(state, { payload }) {
      const tempsortData = payload.tempsortData;
      console.log("tempsortData", payload.tempsortData);
      let newSortdata;

      if (tempsortData && tempsortData.length > 0) {
        if (payload.val === "LowestPrice") {
          const sortingproduct = (a, b) => {
            return a.boiler_cost - b.boiler_cost;
          };

          newSortdata = tempsortData.slice().sort(sortingproduct);
        }
        if (payload.val === "HighestPrice") {
          const sortingproductH = (a, b) => {
            return b.boiler_cost - a.boiler_cost;
          };
          newSortdata = tempsortData.slice().sort(sortingproductH);
        }
        if (payload.val === "HotWaterFlowRate") {
          const sortingproductB = (a, b) => {
            return a.boiler_hot_water_flow_rate - b.boiler_hot_water_flow_rate;
          };

          newSortdata = tempsortData.slice().sort(sortingproductB);
        }
      } else {
        // Handle the case when tempsortData is empty or undefined
        newSortdata = [];
      }

      state.getProductsDetailsData = newSortdata;
    },

    fetchAdditionalChargesStart(state) {
      state.saveQuoteLoading = true;
    },
    fetchAdditionalCharges(state, { payload }) {
      state.fetchAdditionalCharges = payload.additionalValue;
      state.saveQuoteLoading = false;
    },
    fetchAdditionalChargesError(state) {
      state.saveQuoteLoading = false;
    },

    AllData(state, { payload }) {
      state.getProductsDetailsData = payload;
    },
    postSaveQuoteEmailStarted(state) {
      state.saveQuoteLoading = true;
      state.error = null;
      state.saveQuoteSuccess = false;
    },
    postSaveQuoteEmailuccess(state, { payload }) {
      state.saveQuoteLoading = false;
      state.postSaveEmail = payload;
      state.saveQuoteSuccess = true;
    },
    postSaveQuoteEmailFail(state, { payload }) {
      state.saveQuoteLoading = false;
      state.error = payload;
      state.saveQuoteSuccess = false;
    },
    isHelpChooseBoilterSubmissionStarted(state) {
      state.isHelpChooseBoilerSubmitting = true;
      state.helpChooseBoilerSuccess = false;
    },
    isHelpChooseBoilterSubmissionSuccess(state) {
      state.isHelpChooseBoilerSubmitting = false;
      state.helpChooseBoilerSuccess = true;
    },
    isHelpChooseBoilterSubmissionFail(state) {
      state.isHelpChooseBoilerSubmitting = false;
      state.helpChooseBoilerSuccess = false;
    },
    clearHelpChooseBoilterSubmission(state) {
      state.isHelpChooseBoilerSubmitting = false;
      state.helpChooseBoilerSuccess = false;
    },
    fetchWhatsIncludedDataStarted(state) {
      state.isWhatsIncludedDataLoading = true;
    },
    fetchAvailabilityDateStarted(state) {
      state.isWhatsIncludedDataLoading = true;
    },
    fetchWhatsIncludedDataSuccess(state, { payload }) {
      state.isWhatsIncludedDataLoading = false;
      state.whatsIncludedData = payload;
    },
    fetchAvailabilityDateSuccess(state, { payload }) {
      state.isWhatsIncludedDataLoading = false;
      state.availabilityDate = payload;
    },
    fetchAvailabilityDateFails(state, { payload }) {
      state.isWhatsIncludedDataLoading = false;
    },
    fetchWhatsIncludedDataFails(state, { payload }) {
      state.isWhatsIncludedDataLoading = false;
    },
    fetchUserBookingInfoDetailsStarted(state) {
      state.isgetUserBookingDetailsDataLoading = true;
    },
    fetchUserBookinginfoDetailsSuccess(state, { payload }) {
      state.isgetUserBookingDetailsDataLoading = false;
      state.isgetUserBookingDetailsData = payload;
    },
    fetchUserBookingInfoDetailsFails(state, { payload }) {
      state.isgetUserBookingDetailsDataLoading = false;
      state.isgetUserBookingDetailsData = payload;
    },
    fetchAllUserSaveDatadetailsStarted(state) {
      state.isgetAllSaveUserDataDetailsLoading = true;
    },
    fetchAllUserSaveDatadetailsSuccess(state, { payload }) {
      state.isgetAllSaveUserDataDetailsLoading = false;
      state.getAllUserDataSelected = payload;
    },
    fetchAllUserSaveDatadetailsFail(state, { payload }) {
      state.isgetAllSaveUserDataDetailsLoading = false;
      state.getAllUserDataSelected = payload;
    },

    postAllProductStarted(state) {
      state.isPostingAllProduct = true;
    },
    postAllProductSuccess(state, { payload }) {
      state.isPostingAllProduct = false;
      state.postAllProductEmailData = payload;
    },
    postAllProductFail(state, { payload }) {
      state.isPostingAllProduct = false;
      state.postAllProductEmailData = payload;
    },

    postParticularProductStarted(state) {
      state.isPostingParticularProduct = true;
    },
    postParticularProductSuccess(state, { payload }) {
      state.isPostingParticularProduct = false;
      state.postParticularProductEmailData = payload;
    },
    postParticularProductFail(state, { payload }) {
      state.isPostingParticularProduct = false;
      state.postParticularProductEmailData = payload;
    },
  },
});

const { actions, reducer } = getProductsDetailslice;

export const {
  fetchProductsDetailstarted,
  fetchProductsDetailsuccess,
  fetchProductsDetailsFail,
  fetchProductDropdownStarted,
  fetchProductDropdownSuccess,
  fetchProductDropdownFail,
  sortedData,
  AllData,
  postSaveQuoteEmailFail,
  postSaveQuoteEmailStarted,
  postSaveQuoteEmailuccess,
  isHelpChooseBoilterSubmissionStarted,
  isHelpChooseBoilterSubmissionSuccess,
  isHelpChooseBoilterSubmissionFail,
  clearHelpChooseBoilterSubmission,
  fetchWhatsIncludedDataFails,
  fetchWhatsIncludedDataSuccess,
  fetchWhatsIncludedDataStarted,
  fetchUserBookingInfoDetailsFails,
  fetchUserBookinginfoDetailsSuccess,
  fetchUserBookingInfoDetailsStarted,
  fetchAdditionalCharges,
  fetchAdditionalChargesStart,
  fetchAdditionalChargesError,
  fetchAvailabilityDateStarted,
  fetchAvailabilityDateSuccess,
  fetchAvailabilityDateFails,
  postAllProductStarted,
  postAllProductSuccess,
  postAllProductFail,
  postParticularProductStarted,
  postParticularProductSuccess,
  postParticularProductFail,
} = actions;
export default reducer;
