import { call, put } from "redux-saga/effects";
import apiClient from "../../../services/httpServices";
import { API_CONTROL_URL } from "../../../services/webConstant";
import {
  fetchControlDetailStarted,
  fetchControlDetailSuccess,
  fetchControlDetailsFail,
  fetchSingleControlFail,
  fetchSingleControlSuccess,
  fetchSingleControlStarted,
  fetchFilterDetailStarted,
  fetchFilterDetailSuccess,
  fetchFilterDetailsFail,
} from "./controlReducer";

export function* getControlDetailsSaga(payload) {
  try {
    yield put(fetchControlDetailStarted());
    const { data } = yield call(
      apiClient.get,
      API_CONTROL_URL.GET_CONTROL_DETAILS
    );

    yield put(fetchControlDetailSuccess(data));
  } catch (error) {
    yield put(fetchControlDetailsFail());
  }
}

// NEW
export function* getFilterDetailsSaga(payload) {
  try {
    yield put(fetchFilterDetailStarted());
    const { data } = yield call(
      apiClient.get,
      API_CONTROL_URL.GET_FILTER_DETAILS
    );

    yield put(fetchFilterDetailSuccess(data));
  } catch (error) {
    yield put(fetchFilterDetailsFail());
  }
}

export function* getSingleControlDetailSaga(action) {
  const { payload } = action;

  try {
    yield put(fetchSingleControlStarted());

    const getSIngleControlResponse = yield call(
      apiClient.get,
      `${API_CONTROL_URL.GET_SINGLE_CHOOSE_CONTROL}?controls_id=${payload.controls_id}`
    );

    yield put(fetchSingleControlSuccess(getSIngleControlResponse.data.data));
  } catch (err) {
    yield put(fetchSingleControlFail());
  }
}
