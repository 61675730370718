import React, { useEffect } from "react";
import "./productdetails.css";
import { useDispatch, useSelector } from "react-redux";
import { setRoute } from "../router/redux/reducer";
import { ROUTES } from "../../utils/constants";
import { router } from "../router/Router";
import { ADD_PRODUCT_TO_BASKET } from "../../myComponent/organisms/cart/redux/CartAction";
import { ToastContainer, toast } from "react-toastify";
import { clearAddProductToCartSuccess } from "../../myComponent/organisms/cart/redux/CartReducer";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
const ProductDetails = () => {
  const dispatch = useDispatch();
  const [showDesc, setShowDesc] = useState(false);

  const { getSingleProductDetailsData, isLoading } = useSelector(
    (state) => state.getSingleProductDetails
  );
  const { currencyPhoneEmail, isLoadingdynamicCurrrencyandPhone } = useSelector(
    ({ getBoilerQuote }) => getBoilerQuote
  );
  const {
    CartData,
    isAddProductToCartSubmitting,
    addProductToCartSuccess,
    addProductToCardError,
  } = useSelector(({ addProductToCart }) => addProductToCart);

  const handleToRadiator = (item) => {
    const payload = {
      // cart_id: 1,
      product_id: item.id,

      thermostat_id: CartData.thermostate_id ? CartData.thermostate_id : 0,

      controler_id: CartData.control_id ? CartData.control_id : 0,

      radiator_id: CartData.radiator_id ? CartData.radiator_id : 0,
      volve_id: CartData.volvo_id ? CartData.volvo_id : 0,
      radiator_qty: CartData.radiator_qty && CartData.radiator_qty,
      volvo_qty: CartData.volvoe_qty && CartData.volvoe_qty,
    };
    dispatch({ type: ADD_PRODUCT_TO_BASKET, payload });
    dispatch(setRoute(ROUTES.addARadiator));
  };
  // useEffect(() => {
  //   if (addProductToCartSuccess) {
  //     toast.success("Added Successfully", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     dispatch(setRoute(ROUTES.controls));
  //     const moveTocontrol = () => {
  //       return router(ROUTES.controls);
  //     };
  //     setTimeout(moveTocontrol, 1000);

  //     dispatch(clearAddProductToCartSuccess({}));
  //   } else if (addProductToCardError) {
  //     toast.error("Somethings went Wrong", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // }, [addProductToCartSuccess, addProductToCardError]);
  const handleproductToBasket = (item) => {
    const payload = {
      // cart_id: 1,
      product_id: item.id,

      thermostat_id: CartData.thermostate_id ? CartData.thermostate_id : 0,

      controler_id: CartData.control_id ? CartData.control_id : 0,

      radiator_id: CartData.radiator_id ? CartData.radiator_id : 0,
      volve_id: CartData.volvo_id ? CartData.volvo_id : 0,
      radiator_qty: CartData.radiator_qty && CartData.radiator_qty,
      volvo_qty: CartData.volvoe_qty && CartData.volvoe_qty,
    };
    dispatch({ type: ADD_PRODUCT_TO_BASKET, payload });
    dispatch(setRoute(ROUTES.controls));
    const moveTocontrol = () => {
      return router(ROUTES.controls);
    };
    setTimeout(moveTocontrol, 1000);
  };
  const handleToValvesScreen = (item) => {
    const payload = {
      // cart_id: 1,
      product_id: item.id,

      thermostat_id: CartData.thermostate_id ? CartData.thermostate_id : 0,

      controler_id: CartData.control_id ? CartData.control_id : 0,

      radiator_id: CartData.radiator_id ? CartData.radiator_id : 0,
      volve_id: CartData.volvo_id ? CartData.volvo_id : 0,
      radiator_qty: CartData.radiator_qty && CartData.radiator_qty,
      volvo_qty: CartData.volvoe_qty && CartData.volvoe_qty,
    };
    dispatch({ type: ADD_PRODUCT_TO_BASKET, payload });
    dispatch(setRoute(ROUTES.addValves));
  };

  useEffect(() => {});

  const handleToProduct = () => {
    dispatch(setRoute(ROUTES.products));
  };

  if (isLoading) {
    return (
      <>
        <div className="loaderContainer">
          <div className="mainLoader"></div>
        </div>
      </>
    );
  }
  return (
    <>
      <section className="section has-text-centered less-pad-mob py-5">
        <div className="container is-max-desktop">
          {getSingleProductDetailsData &&
            getSingleProductDetailsData.map((item, index) => {
              return (
                <div className="columns is-centered " key={index}>
                  <div className="column is-7">
                    <div className="boiler-image is-4by5">
                      <img
                        src={item.boiler_image_path}
                        style={{ width: "400px", height: "500px" }}
                      />
                    </div>
                  </div>
                  <div className="column is-5">
                    <div className="column is-flex is-flex-direction-column is-justified-content-centered">
                      <h1 class=" heading-bold">{item.boiler_title}</h1>
                      <h2 class="subtitle is-size-7">
                        Fixed price with installation & old tank removal
                      </h2>
                      <div className="price-box">
                        <div className="price-box-inner">
                          <div className="single-price discounted">
                            <h3 className="title is-4">£{item.boiler_cost}</h3>
                            <span className="tag is-success is-light">
                              Save {currencyPhoneEmail.company_currency}300
                            </span>
                          </div>
                          <span className="double-price__divider">/</span>
                          <div className="original-price finance-price">
                            <h3 className="title is-4">
                              {currencyPhoneEmail.company_currency}
                              {item.boiler_cost}
                            </h3>
                            <span>
                              or from{" "}
                              <a>
                                {currencyPhoneEmail.company_currency}27.67 per
                                month
                              </a>{" "}
                              <br />
                              {currencyPhoneEmail.company_vat}% APR
                            </span>
                            <span></span>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                      <div className="column">
                        <div className="my-2">
                          <button
                            className="button is-success is-outlined is-medium is-fullwidth"
                            onClick={() => handleToRadiator(item)}
                          >
                            Add a radiator
                          </button>
                        </div>
                        <div className="my-2">
                          <button className="button is-outlined is-medium is-fullwidth">
                            Email My quotes
                          </button>
                        </div>

                        <div className="my-2">
                          <button
                            className="button is-success is-medium is-fullwidth"
                            onClick={() => handleproductToBasket(item)}
                          >
                            {isAddProductToCartSubmitting ? (
                              <span class="loader"></span>
                            ) : (
                              <div>Add to Basket</div>
                            )}
                          </button>
                          <ToastContainer />
                        </div>
                        <div
                          className="my-2"
                          onClick={() => handleToValvesScreen(item)}
                        >
                          <a href="#">
                            <p className="is-size-6 is-lowercase is-underlined has-text-danger has-text-centered">
                              Add a valves
                            </p>
                          </a>
                        </div>
                      </div>
                      <div className="columns is-flex is-flex-direction-row is-justify-content-left is-align-items-center m-1">
                        <i
                          class="fa fa-check has-text-danger"
                          aria-hidden="true"
                        ></i>

                        <div className="column has-text-left ml-2 is-size-7-fullhd">
                          Eligible for discounted installation on selected days!
                        </div>
                      </div>
                      <div className="columns is-flex is-flex-direction-row is-justify-content-left is-align-items-center m-1">
                        <i
                          class="fa fa-check has-text-danger"
                          aria-hidden="true"
                        ></i>

                        <div className="column has-text-left ml-2 is-size-7-fullhd">
                          A highly reliable boiler, perfect for larger family
                          homes with 2+ bathrooms
                        </div>
                      </div>
                      <div className="columns is-flex is-flex-direction-row is-justify-content-left is-align-items-center m-1">
                        <i
                          class="fa fa-check has-text-danger"
                          aria-hidden="true"
                        ></i>

                        <div className="column has-text-left ml-2 is-size-7-fullhd">
                          Removal of asbestos (if found to be present)
                        </div>
                      </div>
                      <div className="columns is-flex is-flex-direction-row is-justify-content-left is-align-items-center m-1">
                        <i
                          class="fa fa-check has-text-danger"
                          aria-hidden="true"
                        ></i>

                        <div className="column has-text-left ml-2 is-size-7-fullhd">
                          Condensate pipework (if none already)
                        </div>
                      </div>
                      <div className="columns is-flex is-flex-direction-row is-justify-content-left is-align-items-center m-1">
                        <i
                          class="fa fa-check has-text-danger"
                          aria-hidden="true"
                        ></i>

                        <div className="column has-text-left ml-2 is-size-7-fullhd">
                          Condensate pipework (if none already)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
      <section className="section has-text-centered less-pad-mob py-5">
        <div className="container is-max-desktop">
          <h1 className="title is-size-3">What's in the Box</h1>
          <div className="columns is-centered">
            <div className="column is-flex is-flex-direction-column is-justify-content-center">
              {getSingleProductDetailsData &&
                getSingleProductDetailsData.map((item) => {
                  return (
                    <div className="column is-flex is-flex-direction-column">
                      <div
                        className="columns is-flex is-flex-direction-row is-justify-content-left is-align-items-center p-3 pl-4 "
                        onClick={() => setShowDesc(!showDesc)}
                      >
                        {showDesc ? (
                          <span>
                            <i class="fa-solid fa-minus"></i>
                          </span>
                        ) : (
                          <span>
                            <i
                              class="fa-solid fa-plus"
                              style={{ color: "coral" }}
                            ></i>
                            <span className="ml-2"> Boiler</span>
                          </span>
                        )}
                      </div>
                      {showDesc && (
                        <div className="columns is-centered">
                          <div className="column is-three-quarters is-centered ">
                            <div className="column is-flex is-flex-direction-row is-justify-content-space-between">
                              <p>Boiler Width</p>
                              <p>{item.boiler_width}</p>
                            </div>
                            <div className="column is-flex is-flex-direction-row is-justify-content-space-between">
                              <p>Boiler height</p>
                              <p>{item.boiler_height}</p>
                            </div>
                            <div className="column is-flex is-flex-direction-row is-justify-content-space-between">
                              <p>Boiler Depth</p>
                              <p>{item.boiler_depth}</p>
                            </div>

                            <div className="column is-flex is-flex-direction-row is-justify-content-space-between">
                              <p>Hot Water Flow rate</p>
                              <p>{item.boiler_hot_water_flow_rate}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <section></section>
      </section>
    </>
  );
};

export default ProductDetails;
