import { call, put } from "redux-saga/effects";

import apiClient from "../../../services/httpServices";
import { API_CONTROL_URL, API_URL } from "../../../services/webConstant";
import {
  fetchAddSmartDevicesStarted,
  fetchAddSmartDevicesSuccess,
  fetchAddSmartDevicesFail,
  addSmartDeviceSingleProductStarted,
  addSmartDeviceSingleProductSuccess,
  addSmartDeviceSingleProductFail,
} from "./addSmartDeviceReducer";

export function* getAddSmartDeviceSagas(action) {
  const payload = action.payload;

  try {
    yield put(fetchAddSmartDevicesStarted());
    const { data } = yield call(apiClient.get, API_URL.GET_SMART_DEVICES);

    yield put(fetchAddSmartDevicesSuccess(data));
  } catch (error) {
    yield put(fetchAddSmartDevicesFail());
  }
}

export function* addSmartDeviceSingleProduct(action) {
  const { payload } = action;

  try {
    yield put(addSmartDeviceSingleProductStarted());
    const { data } = yield call(
      apiClient.get,
      `${API_CONTROL_URL.GET_SMART_DEVICES_DETAILS}?smart_id=${payload.smart_id}`
    );

    yield put(addSmartDeviceSingleProductSuccess(data.data));
  } catch (error) {
    yield put(addSmartDeviceSingleProductFail());
  }
}
