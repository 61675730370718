import { call, put } from "redux-saga/effects";
import apiClient from "../../../services/httpServices";
import {
  fetchAllSavedUserDataToResumeStarted,
  fetchAllSavedUserDataToResumeSuccess,
  fetchAllSavedUserDataToResumeFails,
} from "./RouterReducer";
import { API_URL } from "../../../services/webConstant";

export function* getAllSavedDataForResumeSaga(action) {
  try {
    yield put(fetchAllSavedUserDataToResumeStarted());

    const AllDataForResume = yield call(
      apiClient.get,
      API_URL.GET_ALL_SAVE_USER_DATA_DETAILS
    );
    yield put(fetchAllSavedUserDataToResumeSuccess(AllDataForResume.data.data));
  } catch {
    yield put(fetchAllSavedUserDataToResumeFails());
  }
}
