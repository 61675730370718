export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const SAVE_PRODUCT_DETAILS = "SAVE_PRODUCT_DETAILS";

export const GET_DROPDOWN_DETAILS = "GET_DROPDOWN_DETAILS";
export const SAVE_BOOKING_MAIL = "SAVE_BOOKING_MAIL";

export const HELP_CHOOSE_BOILER = "HELP_CHOOSE_BOILER";
export const GET_WHATS_INCLUDED_DATA = "GET_WHATS_INCLUDED_DATA";
export const GET_USERINFO_BOOKING_DETAILS = "GET_USERINFO_BOOKING_DETAILS";
export const GET_ADDITIONAL_CHARGES = "GET_ADDITIONAL_CHARGES";

export const POST_ALL_PRODUCT_EMAIL = "POST_ALL_PRODUCT_EMAIL";
export const POST_PARTICULAR_PRODUCT_EMAIL = "POST_PARTICULAR_PRODUCT_EMAIL";
export const GET_AVAILABILITY_DATE = "GET_AVAILABILITY_DATE"

//api/get-booking-info
//
