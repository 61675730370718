import React from "react";
import Svg from "../../../atom/image/Svg";
import HTMLReactParser from "html-react-parser";
import "./radiatorscard.css";
import UseSVG from "../../../atom/image/UseSVG";
import logo1 from "../../../../assets/svgIcons/averge-tap.svg";
import logo2 from "../../../../assets/svgIcons/back-boiler.svg";
import logo3 from "../../../../assets/svgIcons/bath-1.svg";
import logo4 from "../../../../assets/svgIcons/bath-2.svg";
import logo5 from "../../../../assets/svgIcons/bath-3.svg";
import logo6 from "../../../../assets/svgIcons/bedroom-1 2.svg";
import logo7 from "../../../../assets/svgIcons/bedroom-2.svg";
import logo8 from "../../../../assets/svgIcons/bedroom-3.svg";
import logo9 from "../../../../assets/svgIcons/bedroom-4 2.svg";
import logo10 from "../../../../assets/svgIcons/bedroom-5.svg";
import logo11 from "../../../../assets/svgIcons/bedroom.svg";
import logo12 from "../../../../assets/svgIcons/bungalow.svg";
import logo13 from "../../../../assets/svgIcons/combi-boiler.svg";
import logo14 from "../../../../assets/svgIcons/cupboard.svg";
import logo15 from "../../../../assets/svgIcons/detached.svg";
import logo16 from "../../../../assets/svgIcons/doesntFitWithOurPlan.svg";
import logo17 from "../../../../assets/svgIcons/fast-tap.svg";
import logo18 from "../../../../assets/svgIcons/flat-1.svg";
import logo19 from "../../../../assets/svgIcons/flat.svg";
import logo20 from "../../../../assets/svgIcons/floor-standing.svg";
import logo21 from "../../../../assets/svgIcons/garage.svg";
import logo22 from "../../../../assets/svgIcons/highest-two-third.svg";
import logo23 from "../../../../assets/svgIcons/horizontal-flue.svg";
import logo24 from "../../../../assets/svgIcons/kitchen.svg";
import logo25 from "../../../../assets/svgIcons/loft.svg";
import logo26 from "../../../../assets/svgIcons/lowest-third.svg";
import logo27 from "../../../../assets/svgIcons/lpg.svg";
import logo28 from "../../../../assets/svgIcons/mains-gas.svg";
import logo29 from "../../../../assets/svgIcons/mid-terrace.svg";
import logo30 from "../../../../assets/svgIcons/noWithImage.svg";
import logo31 from "../../../../assets/svgIcons/notworking.svg";
import logo32 from "../../../../assets/svgIcons/oil.svg";
import logo33 from "../../../../assets/svgIcons/old&inefficient.svg";
import logo34 from "../../../../assets/svgIcons/other.svg";
import logo35 from "../../../../assets/svgIcons/regular-boiler.svg";
import logo36 from "../../../../assets/svgIcons/same-room.svg";
import logo37 from "../../../../assets/svgIcons/semi-detached-1.svg";
import logo38 from "../../../../assets/svgIcons/semi-detached.svg";
import logo39 from "../../../../assets/svgIcons/shower-1_1.svg";
import logo40 from "../../../../assets/svgIcons/shower-2 1.svg";
import logo41 from "../../../../assets/svgIcons/slow-tap.svg";
import logo42 from "../../../../assets/svgIcons/spoled.svg";
import logo43 from "../../../../assets/svgIcons/system-boiler.svg";
import logo44 from "../../../../assets/svgIcons/unknown.svg";
import logo45 from "../../../../assets/svgIcons/utiily-room.svg";
import logo46 from "../../../../assets/svgIcons/vertical-flue.svg";
import logo47 from "../../../../assets/svgIcons/wall-moundet.svg";
import logo48 from "../../../../assets/svgIcons/yesWithImageBulb.svg";
import logo49 from "../../../../assets/svgIcons/shower-0.svg";
import logo50 from "../../../../assets/svgIcons/shower-1.svg";
import logo51 from "../../../../assets/svgIcons/shower-3.svg";
import logo52 from "../../../../assets/svgIcons/1-5-radiators.svg";
import logo53 from "../../../../assets/svgIcons/6-10-radiators.svg";
import logo54 from "../../../../assets/svgIcons/11-15-radiators.svg";
import logo55 from "../../../../assets/svgIcons/Over-15-radiators.svg";
import logo56 from "../../../../assets/svgIcons/less-then-two-meters.svg";
import logo57 from "../../../../assets/svgIcons/more-then-two-meters.svg";
import logo58 from "../../../../assets/svgIcons/no.svg";
import logo59 from "../../../../assets/svgIcons/yes.svg";
import logo60 from "../../../../assets/svgIcons/one-floor.svg";
import logo61 from "../../../../assets/svgIcons/two-floor.svg";
import logo62 from "../../../../assets/svgIcons/round.svg";
import logo63 from "../../../../assets/svgIcons/square.svg";

const Radiatorscard = ({
  title,
  Options,
  handleSelect,
  questionSubTitle,
  handelModal,
  modalPresent,
}) => {
  const [showing, setShowingData] = React.useState(false);
  const [bgColor, setBgcolor] = React.useState("");
  const isShowing = () => {
    if (showing == true) {
      setShowingData(false);
      setBgcolor("");
    } else {
      setShowingData(true);
      setBgcolor("#f2761a");
    }
  };

  return (
    <section className="section">
      <div className="container  has-text-centered">
        <h2 className="subtitle is-2 is-size-3-mobile mb-3">
          {HTMLReactParser(`${title}`)}
        </h2>
        <h6 className="subtitle is-5 is-size-6-mobile">{questionSubTitle}</h6>

        <div className="is-flex is-flex-wrap-wrap is-justify-content-center">
          {Options.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => handleSelect({ item })}
                className=" all-option3 m-2"
              >
                <i className="card-main-icon">
                  <img src={item.option_icon} alt="React Logo" />
                  {/* <img src={logo51} alt="React Logo" /> */}
                </i>

                <p style={{ fontSize: "23px", marginTop: "20px" }}>
                  {item.option_title}
                </p>

                <div className="card-title">
                  <p className="optionTitleForType">{item.option_subtitle}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Radiatorscard;
