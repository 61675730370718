import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Product.css";
import "./../../styles/styles.css";
import { selectProduct } from "./redux/bucketReducer";
import { useForm, Controller } from "react-hook-form";
import {
  GET_PRODUCT_DETAILS,
  GET_DROPDOWN_DETAILS,
  SAVE_BOOKING_MAIL,
  GET_WHATS_INCLUDED_DATA,
  GET_USERINFO_BOOKING_DETAILS,
  GET_ADDITIONAL_CHARGES,
  GET_AVAILABILITY_DATE,
  POST_ALL_PRODUCT_EMAIL,
  POST_PARTICULAR_PRODUCT_EMAIL,
} from "./redux/productActions";
import ChooseControls from "../choose-controls/ChooseControls";
import { router } from "../router/Router";
import { ROUTES } from "../../utils/constants";
import { setRoute } from "../router/redux/reducer";
import { sortedData, AllData } from "./redux/productReducer";
import SaveQuoteModal from "../../myComponent/molecule/Modal/SaveQuoteModal";
import WhatIncludedDetails from "../../myComponent/molecule/Modal/WhatIncludedDetails";
import HelpChooseModal from "../../myComponent/molecule/Modal/RequestCallmodal";
import ViewEditModal from "../../myComponent/molecule/Modal/ViewEditModal";
import EveryThingIncludedModal from "../../myComponent/molecule/Modal/EverythingIncludedModal";
import BookHomeSurveyModal from "../../myComponent/molecule/Modal/BookHomeSurveyModal";
import HelpMeChooseModal from "../../myComponent/molecule/Modal/HelpMeChooseModal";
import RequestCallModal from "../../myComponent/molecule/Modal/RequestCallmodal";
import { ADD_PRODUCT_TO_BASKET } from "../../myComponent/organisms/cart/redux/CartAction";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clearAddProductToCartSuccess } from "../../myComponent/organisms/cart/redux/CartReducer";
import { GET_SINGLE_PRODUCT_DETAILS } from "../productDetails/redux/ProductDetailsAction";
import { SHOW_PAYMENT_DATA } from "../paymeny-successful/redux/PaymentSuccessAction";
import { GET_ALL_SAVE_USER_DATA_DETAILS } from "../router/redux/RouterAction";
import SaveTheQuote from "../../myComponent/molecule/Modal/SaveTheQuote";
import SaveAllQuote from "../../myComponent/molecule/Modal/SaveQuoteModal";

const Products = () => {
  const dispatch = useDispatch();
  const [saveaQuoteModal, setSaveQuoteModal] = useState(false);
  const [saveAllQuoteModal, setSaveAllQuoteModal] = useState(false);
  const [saveQuoteId, setSaveQuoteId] = useState("");
  const [isBookServeyModalVisible, setIsBookSurveyModalVisible] = useState(
    false
  );
  const [boilerNameSelected, setBoilerNameSelected] = useState("");
  const [boilerTypeSelected, setBoilerTypeSelected] = useState("");
  const [includedDetails, setIncludedDetails] = useState();
  const [isReqCallModalVisible, setIsReqCallModalVisible] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);
  const [isEverythingIncModal, setIsEverythingIncModal] = useState(false);

  const [isHelpMeChooseModalVisible, setIsHelpMeChooseModalVisible] = useState(
    false
  );
  const { CartData } = useSelector(({ addProductToCart }) => addProductToCart);
  const { AllDataForResumeSession } = useSelector(
    ({ getAllSavedDataForResume }) => getAllSavedDataForResume
  );
  const { currencyPhoneEmail, isLoadingdynamicCurrrencyandPhone } = useSelector(
    ({ getBoilerQuote }) => getBoilerQuote
  );

  const [bedCount, setBedCount] = useState(7);
  const [bathCount, setBathCount] = useState(2);
  const [showerCount, setShowerCount] = useState(4);
  const userEmail = sessionStorage.getItem("userEmail");

  let previousFirstName;
  let previousLastName;
  let previousEmail;
  let previousPhoneNumber;

  const PreviousFormData = localStorage.getItem("StoreFormData");
  if (PreviousFormData !== null) {
    const formdataval = JSON.parse(PreviousFormData);
    previousFirstName = formdataval.First_Name;
    previousLastName = formdataval.Last_Name;
    previousEmail = formdataval.Email_Id;
    previousPhoneNumber = formdataval.phoneNumber;
  }

  const beCount = sessionStorage.getItem("bedNumberCount");
  const {
    getProductsDetailsData,
    boilerBrandsDropdown,
    isfetchingProductsDetails,
    loading,
    numberOfBedrooms,
    saveQuoteSuccess,
    fetchAdditionalCharges,
  } = useSelector(({ getProductsDetails }) => getProductsDetails);

  const {
    isAddProductToCartSubmitting,
    addProductToCartSuccess,
    addProductToCardError,
  } = useSelector(({ addProductToCart }) => addProductToCart);

  const productDetails = getProductsDetailsData;
  console.log("Product detailssss", productDetails);
  let tempsortData = productDetails;

  const newUniqueItem = boilerBrandsDropdown.filter((item, index) => {
    return (
      boilerBrandsDropdown.findIndex(
        (obj) => obj.boiler_brand_name === item.boiler_brand_name
      ) === index
    );
  });

  const UniqueBolierBrandType = boilerBrandsDropdown.filter((item, index) => {
    return (
      boilerBrandsDropdown.findIndex(
        (obj) => obj.boiler_type_name === item.boiler_type_name
      ) === index
    );
  });

  const sorting = (val) => {
    dispatch(sortedData({ val, tempsortData }));
  };

  useEffect(() => {
    const payload = {
      brand_id: "",
      bedroom: beCount,
      email: userEmail,
    };
    dispatch({ type: GET_PRODUCT_DETAILS, payload });
    dispatch({ type: GET_DROPDOWN_DETAILS, payload });
    dispatch({ type: GET_WHATS_INCLUDED_DATA });
    dispatch({ type: GET_ADDITIONAL_CHARGES, email: userEmail });
    dispatch({ type: GET_AVAILABILITY_DATE });
  }, [GET_WHATS_INCLUDED_DATA, numberOfBedrooms]);

  useEffect(() => {
    if (saveQuoteSuccess) {
      setSaveQuoteModal(false);
      toast.success("Quote Sent Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [saveQuoteSuccess]);

  useEffect(() => {
    dispatch({ type: GET_USERINFO_BOOKING_DETAILS });
  }, [numberOfBedrooms]);

  const totalProducts = productDetails.length;

  const handleBucketProduct = (item) => {
    dispatch(selectProduct(item));

    const payload = {
      // cart_id: 1,
      product_id: item.pro_id,

      thermostat_id: CartData.thermostate_id ? CartData.thermostate_id : 0,
      controler_id: CartData.control_id ? CartData.control_id : 0,

      radiator_id: CartData.radiator_id ? CartData.radiator_id : 0,
      volve_id: CartData.volve_id ? CartData.volve_id : 0,
      volvo_qty: CartData.volvoe_qty && CartData.volvoe_qty,
      radiator_qty: CartData.radiator_qty && CartData.radiator_qty,
    };
    dispatch({ type: ADD_PRODUCT_TO_BASKET, payload });
    dispatch(setRoute(ROUTES.controls));

    router(ROUTES.controls);
  };

  const handleQuoteModalClose = () => {
    setSaveQuoteModal(false);
  };

  const handleAllQuoteModalClose = () => {
    setSaveAllQuoteModal(false);
  };

  const handleSelectedBrands = (item) => {
    setBoilerNameSelected(item.boiler_brand_name);
    console.log("this is second product call");
    let payload = {
      // boilertype: item.boiler_type_id,
      brand_id: item.boiler_brand_id,
      bedroom: numberOfBedrooms,
      email: userEmail,
    };
    dispatch({ type: GET_PRODUCT_DETAILS, payload });
  };

  const handleSelectedBoilerType = (item) => {
    setBoilerTypeSelected(item.boiler_type_name);
    console.log("this is third product call");
    let payload = {
      // boilertype: item.boiler_type_id,
      brand_id: item.boiler_brand_id && item.boiler_brand_id,
      bedroom: numberOfBedrooms && numberOfBedrooms,
      email: userEmail,
    };
    dispatch({ type: GET_PRODUCT_DETAILS, payload });
  };
  // console.log("product details ==>", productDetails);
  const handleSaveQuoteModal = (id) => {
    setSaveQuoteModal(true);
    setSaveQuoteId(id);
  };

  const handleAllBoiler = () => {
    setBoilerNameSelected("");
    setBoilerTypeSelected("");
    dispatch(AllData(boilerBrandsDropdown));
  };

  const handleToProductDetails = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    const payload = {
      product_id: item.pro_id,
    };
    dispatch({ type: GET_SINGLE_PRODUCT_DETAILS, payload });
    const call = () => {
      return dispatch(setRoute(ROUTES.productDetails));
    };
    setTimeout(call, 500);
  };

  const handleToRadiatorScreen = (item) => {
    const payload = {
      // cart_id: 1,
      product_id: item.id,

      thermostat_id: CartData.thermostate_id ? CartData.thermostate_id : 0,

      controler_id: CartData.control_id ? CartData.control_id : 0,

      radiator_id: CartData.radiator_id ? CartData.radiator_id : 0,
      volve_id: CartData.volve_id && CartData.volve_id,
      volvo_qty: CartData.volvoe_qty && CartData.volvoe_qty,
      radiator_qty: CartData.radiator_qty && CartData.radiator_qty,
    };
    dispatch({ type: ADD_PRODUCT_TO_BASKET, payload });
    dispatch(setRoute(ROUTES.addARadiator));
  };

  const handleToValvesScreen = (item) => {
    const payload = {
      // cart_id: 1,
      product_id: item.id,

      thermostat_id: CartData.thermostate_id ? CartData.thermostate_id : 0,

      controler_id: CartData.control_id ? CartData.control_id : 0,

      radiator_id: CartData.radiator_id ? CartData.radiator_id : 0,
      volvo_id: CartData.volve_id ? CartData.volve_id : 0,
      volvo_qty: CartData.volvoe_qty && CartData.volvoe_qty,
      radiator_qty: CartData.radiator_qty && CartData.radiator_qty,
    };
    dispatch({ type: ADD_PRODUCT_TO_BASKET, payload });
    dispatch(setRoute(ROUTES.addValves));
  };

  const handleWhatIncludedModal = () => {
    setIsEverythingIncModal((prevVal) => !prevVal);
  };
  const handleToStartAgain = () => {
    dispatch(setRoute(ROUTES.getQuotes));
  };

  // useEffect(() => {
  //   dispatch({ type: GET_ALL_SAVE_USER_DATA_DETAILS });
  // }, []);

  if (loading || isfetchingProductsDetails) {
    return (
      <>
        <div className="loaderContainer">
          <div className="mainLoader"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="main-sec">
        {/* <section> */}
        <div className="filter-top-main-sec">
          <div className="left-side-options">
            <button className="start-button" onClick={handleToStartAgain}>
              {" "}
              <i className="fa-solid fa-rotate-left" />{" "}
              <span className="mobile-start">Restart</span>{" "}
            </button>
          </div>

          <div className="top-right-side-options">
            {/* <div className="column is-flex is-justify-content-flex-end mr-5"> */}

            <button
              className="filter-button help-button"
              onClick={() => setIsHelpMeChooseModalVisible(true)}
            >
              <i className="fa-regular fa-circle-question" />

              <span className="desk-btn-text">Help me choose</span>
              <span className="mobile-btn-text">Help</span>
            </button>

            <button
              className="filter-button call-button"
              onClick={() => setIsReqCallModalVisible(true)}
            >
              <i className="fa-solid fa-headset" />
              <span className="desk-btn-text">Request a call</span>
              <span className="mobile-btn-text">Support</span>
            </button>
          </div>
        </div>
        {/* </section> */}

        <div className="filter-main-sec">
          <div className="left-side-options">
            <div className="short-sec">
              <h2>
                We've matched you with{" "}
                <span className="number-of-boiler">
                  {" "}
                  {totalProducts} boilers
                </span>{" "}
              </h2>
            </div>
          </div>
          <div className="right-side-options">
            {/* <span className="is-size-6-mobile">
                  {numberOfBedrooms > 1
                    ? `${numberOfBedrooms} Beds`
                    : `${numberOfBedrooms} Bed`}
                </span> */}

            <select
              className="select filter-button"
              // onClick={(e) => sorting(e.target.value)}
              onSelect={(e) => sorting(e.target.value)}
              id="sort"
            >
              <option value="" disabled selected hidden>
                Select Order
              </option>
              <option value="LowestPrice">Lowest Price</option>
              <option value="HighestPrice">Highest Price</option>
              <option value="HotWaterFlowRate">Hot water Flow Rate</option>
            </select>

            <button
              className="filter-button"
              onClick={() => setIsViewModal(!isViewModal)}
            >
              <i className="fa-solid fa-sliders" />
              Filter
            </button>

            {/* Design Changes - 03/02 */}
            {/* <button
              class="filter-button"
              onClick={() => setIncludedDetails(true)}
            >
              {" "}
              <i className="fa-solid fa-list" /> What's Included?{" "}
            </button> */}

            <button
              class="filter-button save-quote-top"
              onClick={() => setSaveAllQuoteModal(true)}
            >
              <i class="fa-regular fa-envelope"></i> Save Quotes{" "}
            </button>
          </div>
        </div>

        {/*	<!--- Summary Items ends ---> */}

        {/* <!--- Summary Items ---> */}
        <section>
          <div className="container nu-filter-items mb-4">
            <div className="nu-filter-inner">
              <div className="filter-menu-left">
                {/* <!--dropdown 1--> */}
                <div className="dropdown is-hoverable">
                  <div className="dropdown-trigger">
                    <button
                      className="button"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu1"
                    >
                      <span>
                        {boilerNameSelected
                          ? boilerNameSelected
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(" ")
                          : "Boiler Brands"}
                      </span>
                      <span className="icon is-small">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 -150 748 712"
                        >
                          <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div
                    className="dropdown-menu"
                    id="dropdown-menu1"
                    role="menu"
                  >
                    {/* <div className="dropdown-content"> */}
                    <div className="dropdown-content is-small">
                      <button
                        className="button is-white dropdown-item "
                        // aria-haspopup="true"
                        // aria-controls="dropdown-menu1"
                        onClick={handleAllBoiler}
                      >
                        All Boiler
                      </button>
                    </div>
                    {newUniqueItem &&
                      newUniqueItem.map((item, id) => {
                        const capitalizedWords = item.boiler_brand_name
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ");
                        return (
                          <div className="dropdown-content" key={id}>
                            <button
                              className="button is-white dropdown-item"
                              key={id}
                              onClick={() => handleSelectedBrands(item)}
                            >
                              {/* {console.log("item===>", item.boiler_brand_name)} */}
                              {capitalizedWords}
                            </button>
                          </div>
                        );
                      })}
                    {/* </div> */}
                  </div>
                </div>
                {/* <!--dropdown 2--> */}
                <div className="dropdown is-hoverable">
                  <div className="dropdown-trigger">
                    <button
                      className="button"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu4"
                    >
                      <span>
                        {boilerTypeSelected
                          ? boilerTypeSelected
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(" ")
                          : "Boiler Types"}
                      </span>
                      <span className="icon is-small">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 -150 748 712"
                        >
                          <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div
                    className="dropdown-menu"
                    id="dropdown-menu4"
                    role="menu"
                  >
                    <div className="dropdown-content is-small">
                      <button
                        className="button is-white  dropdown-item "
                        // aria-haspopup="true"
                        // aria-controls="dropdown-menu1"
                        onClick={handleAllBoiler}
                      >
                        All Boilers
                      </button>
                    </div>
                    {UniqueBolierBrandType &&
                      UniqueBolierBrandType.map((item, id) => {
                        const capitalizedWords = item.boiler_type_name
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ");

                        return (
                          <div className="dropdown-content is-small" key={id}>
                            <button
                              className="button is-white dropdown-item"
                              key={id}
                              onClick={() => handleSelectedBoilerType(item)}
                            >
                              {capitalizedWords}
                            </button>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="filter-menu-right">
                {/* <!--dropdown 3--> */}
                <div className="dropdown is-right is-hoverable">
                  {/* <div className="dropdown-menu" id="dropdown-menu4" role="menu"> */}
                  <div className="dropdown-trigger">
                    <div className="select">
                      <select
                        onClick={(e) => sorting(e.target.value)}
                        id="sort"
                      >
                        <option value="LowestPrice">Lowest Price</option>
                        <option value="HighestPrice">Highest Price</option>
                        <option value="HotWaterFlowRate">
                          Hot water Flow Rate
                        </option>
                      </select>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>

              <div className="filter-menu-mob">
                {/*	<!--dropdown 4--> */}
                <div className="dropdown is-left is-hoverable">
                  <div className="dropdown-trigger ">
                    <button
                      className="button"
                      // aria-haspopup="true"
                      // aria-controls="dropdown-menu4"
                    >
                      <span>
                        {boilerNameSelected
                          ? boilerNameSelected
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(" ")
                          : "Filters"}
                      </span>
                      <span className="icon is-small">
                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>

                  <div
                    className="dropdown-menu"
                    id="dropdown-menu4"
                    role="menu"
                  >
                    {newUniqueItem &&
                      newUniqueItem.map((item, id) => (
                        <div className="dropdown-content is-small" key={id}>
                          <button
                            className="button is-white"
                            key={id}
                            onClick={() => handleSelectedBrands(item)}
                          >
                            {item.boiler_brand_name
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(" ")}
                          </button>
                        </div>
                      ))}
                  </div>
                </div>

                {/*	<!--dropdown 5--> */}
                <div className="dropdown is-right is-hoverable ">
                  <div className="dropdown-trigger">
                    <div className="dropdown is-right is-hoverable">
                      <div className="dropdown-trigger">
                        <div className="select">
                          <select
                            onClick={(e) => sorting(e.target.value)}
                            id="sort"
                          >
                            <option value="LowestPrice">Lowest Price</option>
                            <option value="HighestPrice">Highest Price</option>
                            <option value="HotWaterFlowRate">
                              Hot water Flow Rate
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <!--dropdown 3--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section> */}
        <div className="filter-second-main-sec display-none">
          <div className="columns  is-justify-content-flex-end m-1">
            {/* <div className="column is-flex is-justify-content-flex-end mr-5"> */}

            <button
              class="filter-button"
              onClick={() => setSaveQuoteModal(true)}
            >
              Save Quotes{" "}
            </button>
            <button
              class="filter-button"
              onClick={() => setIncludedDetails(true)}
            >
              {" "}
              What's Included?{" "}
            </button>
          </div>
        </div>
        {/* </section> */}

        {/* <section className="section"> */}

        <div>
          <div>
            {productDetails && productDetails.length > 0 ? (
              <div>
                {productDetails.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="product-sec">
                        <div className="image-sec">
                          <div className="brand-img-sec">
                            <img src="https://boxt.imgix.net/uploads/manufacturer/image/13058899-991d-414d-ac9a-93e3aaa22fd5/worcester-bosch.svg" />
                          </div>
                          <div
                            className="product-img"
                            onClick={(e) => handleToProductDetails(item, e)}
                          >
                            <img src={item.boiler_image_path} />
                          </div>
                          <div className="dimensions-sec dimensions-desk ">
                            <img src="icons/dimensions.svg" alt="" />
                            <p>
                              W{" "}
                              <span className="product-width">
                                {item.boiler_width}
                              </span>
                              mm x H{" "}
                              <span className="product-width">
                                {item.boiler_height}
                              </span>
                              mm x D{" "}
                              <span className="product-width">
                                {item.boiler_depth}
                              </span>
                              mm
                            </p>
                          </div>
                        </div>

                        {/*  */}

                        <div className="detail-sec">
                          <h2 className="title is-3 pb-2">
                            {item.boiler_title}
                          </h2>

                          <p className="subtitle is-6">
                            {" "}
                            {item.boiler_subtitle}{" "}
                          </p>

                          <p className="prduct-sub">
                            {item.boiler_description}{" "}
                          </p>

                          <div class="product-tags-sec">
                            {item.popularChoice !== null &&
                              item.popularChoice !== 0 &&
                              item.popularChoice != 0 && (
                                <div class="product-tag">
                                  <i class="fa-solid fa-lightbulb"></i> popular
                                  Choice
                                </div>
                              )}
                            {item.compactModel !== null &&
                              item.compactModel !== 0 &&
                              item.compactModel != 0 && (
                                <div class="product-tag">
                                  <i class="fa-solid fa-down-left-and-up-right-to-center"></i>{" "}
                                  STA compact model
                                </div>
                              )}
                            {item.latestModel !== null &&
                              item.latestModel !== 0 &&
                              item.latestModel != 0 && (
                                <div class="product-tag">
                                  <i class="fa-solid fa-bahai"></i> latest model
                                </div>
                              )}
                            {item.finance !== null &&
                              item.finance !== 0 &&
                              item.finance != 0 && (
                                <div class="product-tag">
                                  <i class="fa-solid fa-coins"></i> finance
                                  available
                                </div>
                              )}
                          </div>

                          <div className="display-none has-text-left">
                            <button
                              class="button is-danger is-inverted"
                              onClick={(e) => handleToProductDetails(item, e)}
                            >
                              {" "}
                              More info{" "}
                            </button>
                            {/* <a className="  has-text-danger is-underlined "> </a> */}
                          </div>

                          <div class="spec-sec">
                            <div class="spec-inner-sec">
                              <span class="specs-name">Warranty</span>
                              <span class="specs">
                                {item.boiler_warranty}
                                <i class="fa-solid fa-circle-check"></i>
                              </span>
                            </div>

                            <div class="spec-inner-sec">
                              <span class="specs-name">
                                Hot Water Flow Rate
                              </span>
                              <span class="specs">
                                {item.boiler_hot_water_flow_rate} Liters / Min
                                <i class="fa-solid fa-bars-staggered"></i>
                              </span>
                            </div>

                            <div class="spec-inner-sec">
                              <span class="specs-name">Power</span>
                              <span class="specs">
                                {item.boiler_power}
                                <i class="fa-solid fa-fire"></i>
                              </span>
                            </div>
                          </div>
                        </div>

                        {/*  */}

                        <div className="price-sec">
                          <p className="w-inc">
                            Your Fixed Price Including Installation: <br />{" "}
                            {/* Design Changes - 03/02 */}
                            {/* <a onClick={handleWhatIncludedModal} href="#">
                              Whats included?
                            </a>{" "} */}
                          </p>
                          <div className="main-price-sec">
                            <h3 className="fixed-price">
                              {" "}
                              {currencyPhoneEmail.company_currency
                                ? currencyPhoneEmail.company_currency
                                : "£"}
                              {`${
                                !isNaN(parseInt(item.boiler_cost))
                                  ? parseInt(item.boiler_cost)
                                  : 0 + !isNaN(fetchAdditionalCharges)
                                  ? fetchAdditionalCharges
                                  : 0
                              }${".00"}`}
                            </h3>
                            <div className="save-sec">
                              <span className="corssed-price">
                                {" "}
                                {currencyPhoneEmail.company_currency
                                  ? currencyPhoneEmail.company_currency
                                  : "£"}
                                {(
                                  parseFloat(item.boiler_cost) +
                                  fetchAdditionalCharges +
                                  300
                                ).toFixed(2)}{" "}
                              </span>
                              <span className="saved-price">
                                save{" "}
                                {currencyPhoneEmail.company_currency
                                  ? currencyPhoneEmail.company_currency
                                  : "£"}
                                300
                              </span>
                            </div>
                          </div>

                          {/* <p className="monthly-price"> */}
                          {/* {" "}
                            or from{" "}
                            <span>
                              {" "}
                              {currencyPhoneEmail.company_currency
                                ? currencyPhoneEmail.company_currency
                                : "£"}
                              27.67
                            </span>{" "}
                            per month (<span>10.9%</span> APR) */}
                          {/* {" "} */}
                          {/* {currencyPhoneEmail
                              ? currencyPhoneEmail.company_vat
                              : 0}{" "} */}
                          {/* </p> */}

                          <div className="main-btn-sec">
                            <button
                              className="basket"
                              onClick={() => handleBucketProduct(item)}
                            >
                              {isAddProductToCartSubmitting ? (
                                <span class="loader"></span>
                              ) : (
                                <span> Add to Basket</span>
                              )}
                              <i class="fa-solid fa-cart-shopping"></i>
                            </button>

                            {/* <ToastContainer /> */}

                            <button
                              className="save-quote"
                              onClick={() => {
                                handleSaveQuoteModal(item.id);
                              }}
                            >
                              Save this quote{" "}
                              <i
                                class="fa fa-envelope ml-auto"
                                aria-hidden="true"
                              ></i>
                            </button>

                            <button
                              className="home-survey"
                              onClick={() => setIsBookSurveyModalVisible(true)}
                            >
                              Book free Home Survey{" "}
                              <i class="fa-solid fa-square-poll-vertical"></i>
                            </button>

                            {/* <a onClick={() => handleToRadiatorScreen(item)}>
                              Add a Radiator
                            </a> */}
                            {/* <a onClick={() => handleToValvesScreen(item)}>
                              Add a valves
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className=" my-5">
                <div className="column is-flex is-flex-direction-column is-justify-content-center is-align-items-center my-4 mx-4">
                  <p className="title is-4 is-size-6-mobile has-text-centered">
                    We don't have any products that match your selection.
                  </p>
                  <p className="title is-4 is-size-6-mobile has-text-centered">
                    Please reach out to us through{" "}
                    <span
                      className="has-text-danger is-underlined is-italic"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <a href={`mailto:${currencyPhoneEmail.company_email}`}>
                        {currencyPhoneEmail.company_email}
                      </a>
                    </span>{" "}
                    or give us a call at{" "}
                    <span
                      className="has-text-danger is-underlined is-italic"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <a href={`tel:${currencyPhoneEmail.company_phone}`}>
                        {currencyPhoneEmail.company_phone}
                      </a>
                    </span>{" "}
                    for assistance.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <BookHomeSurveyModal
        isVisible={isBookServeyModalVisible}
        onClose={setIsBookSurveyModalVisible}
      />

      <WhatIncludedDetails
        includedDetails={includedDetails}
        setIncludedDetails={setIncludedDetails}
      />
      <RequestCallModal
        isVisible={isReqCallModalVisible}
        onClose={setIsReqCallModalVisible}
      />
      <ViewEditModal
        isViewModal={isViewModal}
        setIsViewModal={setIsViewModal}
        // trial...
        bedCount={bedCount}
        setBedCount={setBedCount}
        bathCount={bathCount}
        setBathCount={setBathCount}
        showerCount={showerCount}
        setShowerCount={setShowerCount}
      />
      <EveryThingIncludedModal
        isVisible={isEverythingIncModal}
        onClose={setIsEverythingIncModal}
      />
      <HelpMeChooseModal
        isVisible={isHelpMeChooseModalVisible}
        onClose={setIsHelpMeChooseModalVisible}
      />
      {/* </section> */}
      <SaveTheQuote
        saveaQuoteModal={saveaQuoteModal}
        handleCancel={handleQuoteModalClose}
        userPrefillData={{
          First_Name: previousFirstName,
          Last_Name: previousLastName,
          Email_Id: previousEmail,
          phoneNumber: previousPhoneNumber,
        }}
        id={saveQuoteId}
      />

      <SaveAllQuote
        saveAllQuoteModal={saveAllQuoteModal}
        handleCancel={handleAllQuoteModalClose}
        userPrefillData={{
          First_Name: previousFirstName,
          Last_Name: previousLastName,
          Email_Id: previousEmail,
          phoneNumber: previousPhoneNumber,
        }}
      />
    </>
  );
};

export default Products;
