import { format, isBefore } from "date-fns";
import React from "react";
import "react-nice-dates/build/style.css";
import { useSelector } from "react-redux";
import "./calendar.css";

function CalenderComponent({ date, setDate }) {
  const { availabilityDate } = useSelector(
    ({ getProductsDetails }) => getProductsDetails
  );

  const myData = useSelector(({ getProductsDetails }) => getProductsDetails);

  const [increase, setIncrease] = React.useState(5);
  const [decrease, setDecrease] = React.useState(0);
  const [buttonCount, setButtonCount] = React.useState(5);
  const [selectedItems, setSelectedItems] = React.useState([]);
  let AvailableSlots = availabilityDate.IncludedData;

  AvailableSlots = AvailableSlots.filter((slot) => slot.Total_Slot > 0);

  AvailableSlots = AvailableSlots.sort((a, b) => {
    return (
      new Date(a.engineer_availability_date) -
      new Date(b.engineer_availability_date)
    );
  });

  const limitedData = AvailableSlots.slice(decrease, increase);

  const isDisabledDate = (day) => {
    return isBefore(day, new Date());
  };

  const disabledModifier = (day) => {
    return isDisabledDate(day) ? "disabled" : undefined;
  };

  const toggleSelection = (itemId) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(itemId) ? [] : [itemId]
    );
  };

  return (
    <div className="columns is-flex is-flex-direction-column">
      <div className="column box">
        <div className="cal-box">
          <h3>
            Great news, we can fit as soon as{" "}
            {format(
              new Date(AvailableSlots[0].engineer_availability_date),
              "LLL"
            )}{" "}
            {format(
              new Date(AvailableSlots[0].engineer_availability_date),
              "d"
            )}
          </h3>

          <div className="cal-nav">
            {buttonCount > 5 ? (
              <i
                className="fa-solid fa-arrow-left"
                onClick={(e) => {
                  e.stopPropagation();
                  setIncrease(increase - 1);
                  setDecrease(decrease - 1);
                  setButtonCount(buttonCount - 1);
                }}
              ></i>
            ) : (
              <i className="fa-solid fa-arrow-left"></i>
            )}

            <p style={{ textAlign: "center" }}> Pick your install date </p>
            {buttonCount <= AvailableSlots.length - 1 ? (
              <i
                className="fa-solid fa-arrow-right"
                onClick={(e) => {
                  e.stopPropagation();
                  setIncrease(increase + 1);
                  setDecrease(decrease + 1);
                  setButtonCount(buttonCount + 1);
                }}
              ></i>
            ) : (
              <i className="fa-solid fa-arrow-right"></i>
            )}
          </div>

          <div className="cal-date">
            {limitedData.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`cal-date-box ${
                    selectedItems.includes(item.engineer_availability_date)
                      ? "active-cal-box"
                      : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelection(item.engineer_availability_date);
                    setDate(new Date(item.engineer_availability_date));
                  }}
                >
                  <span className="day">
                    {format(new Date(item.engineer_availability_date), "E")}
                  </span>
                  <div className="date-sec">
                    <span className="date">
                      {format(new Date(item.engineer_availability_date), "d")}
                    </span>
                    <span className="date-suffix">th</span>
                  </div>
                  <span className="slot-box">
                    <div className="slot-box-total">{item.Total_Slot}</div>{" "}
                    <div className="slot-box-text">
                      <p className="slot-box-text-one">slot</p>
                      <p className="slot-box-text-two">left</p>
                    </div>
                  </span>
                  <span className="month">
                    {format(new Date(item.engineer_availability_date), "LLL")}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalenderComponent;
