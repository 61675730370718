import { all, takeLatest } from "redux-saga/effects";
import { GET_FORM_DETAILS } from "../myComponent/molecule/form/redux/formActions";
import { getFormDetailsSaga } from "../myComponent/molecule/form/redux/formSagas";
import {
  ADD_PRODUCT_TO_BASKET,
  GET_CART_PRODUCT_DETAILS,
  REMOVE_CART_ITEM,
} from "../myComponent/organisms/cart/redux/CartAction";
import {
  STARTED_CREATING_PAYMENT,
  STORE_PAYMENT_DATA,
} from "../myComponent/organisms/checkoutpage/redux/paymentAction";
import {
  GET_PRIVACY_POLICY,
  GET_TERM_CONDITIONS,
  PHONE_VALIDATION,
  POST_QUOTE_EMAIL,
} from "../myComponent/organisms/form/redux/InfoFormAction";
import {
  getPhoneNumberValidateData,
  getPrivacyPolicySaga,
  getTermConditionDataSaga,
  postQuoteEmailSaga,
} from "../myComponent/organisms/form/redux/InfoFormSaga";
import { GET_VALVE } from "../scenes/AddValves/redux/AddValveAction";
import { getAValveSaga } from "../scenes/AddValves/redux/AddValveSaga";
import {
  GET_BOILER_QUOTE,
  VALIDATE_PHONE_NUMBER,
  VALIDATE_EMAIL_ADDRESS,
  SAVE_BOILER_QUOTE,
  GET_CURRENCY,
} from "../scenes/get-a-quote/redux/GetAquoteAction";
import {
  addSmartDeviceSingleProduct,
  getAddSmartDeviceSagas,
} from "../scenes/addSmartDevice/redux/addSmartDeviceSagas";
import { FETCH_CLIENT } from "../scenes/auth/redux/LoginAuthAction";
import { fetchClientSaga } from "../scenes/auth/redux/LoginAuthSagas";
import {
  GET_CONTROL_DETAILS,
  GET_SINGLE_CHOOSE_CONTROL,
  GET_FILTER_DETAILS,
} from "../scenes/choose-controls/redux/controlAction";
import {
  getControlDetailsSaga,
  getSingleControlDetailSaga,
  getFilterDetailsSaga,
} from "../scenes/choose-controls/redux/controlSagas";

import {
  GET_SMART_DEVICES,
  GET_SMART_DEVICES_DETAILS,
} from "../scenes/addSmartDevice/redux/addSmartDeviceAction";
import {
  dynamicCurrencyEmailPhoneSaga,
  getBoilerQuoteSaga,
  saveBoilerQuoteSaga,
  valdiateEmailSaga,
  valdiatePhoneNumberSaga,
} from "../scenes/get-a-quote/redux/GetAquoteSaga";
import {
  GET_BOOKING_ORDER,
  GET_INSTALLER,
  GET_ORDER_SUMMARY,
  ORDER_SUMMARY_DETAILS,
} from "../scenes/order/redux/OrderSummaryActions";
import {
  getInstallerDetailSaga,
  getOrderSummarySaga,
  postBookOrderSummarySaga,
  postOrderSummarySaga,
} from "../scenes/order/redux/OrderSummarySaga";
import { SHOW_PAYMENT_DATA } from "../scenes/paymeny-successful/redux/PaymentSuccessAction";
import { getShowpaymentDetailsSaga } from "../scenes/paymeny-successful/redux/PaymentSuccessSaga";
import { GET_SINGLE_PRODUCT_DETAILS } from "../scenes/productDetails/redux/ProductDetailsAction";
import { getSingleProductDetailsSaga } from "../scenes/productDetails/redux/ProductDetailsSaga";
import {
  GET_ADDITIONAL_CHARGES,
  GET_DROPDOWN_DETAILS,
  GET_PRODUCT_DETAILS,
  GET_USERINFO_BOOKING_DETAILS,
  GET_WHATS_INCLUDED_DATA,
  HELP_CHOOSE_BOILER,
  SAVE_BOOKING_MAIL,
  POST_ALL_PRODUCT_EMAIL,
  POST_PARTICULAR_PRODUCT_EMAIL,
  GET_AVAILABILITY_DATE,
} from "../scenes/products/redux/productActions";

import {
  getAdditionalChargesSaga,
  getProductDetailsSaga,
  getProductDropdownSaga,
  getUserInfoDetailsSaga,
  getWhatsIncludedSaga,
  helpChooseBoilerSaga,
  postSaveQuoteSEmailSaga,
  postAllProductEmailSaga,
  postParticularProductEmailSaga,
  getAvailabilityDate,
} from "../scenes/products/redux/productSagas";
import {
  getCartProductSaga,
  postAddProductToCartSaga,
  removeProductFromCartSaga,
} from "../myComponent/organisms/cart/redux/CartSaga";
import {
  GET_RADIATOR,
  GET_RADIATOR_HEIGHT,
  GET_RADIATOR_LENGTH,
  GET_RADIATOR_PRICE_BTU,
  GET_RADIATOR_TYPE,
  PRODUCT_RADIATOR_INFO_DATA,
} from "../scenes/radiator/redux/AddaRadiatorAction";
import {
  getARadiatorImagedataSaga,
  getARadiatorSaga,
  getProductInformationDataSaga,
  getRadiatorHeightSaga,
  getRadiatorLengthSaga,
  getRadiatorPriceandBtuSaga,
} from "../scenes/radiator/redux/AddaRadiatorSaga";

import {
  postStorePaymentDataSaga,
  postStorePaymentDetailSaga,
  postclientDataForSecretKeySaga,
} from "../myComponent/organisms/checkoutpage/redux/PaymentSaga";
import { getAllSavedDataForResumeSaga } from "../scenes/router/redux/RouterSaga";
import { GET_ALL_SAVE_USER_DATA_DETAILS } from "../scenes/router/redux/RouterAction";

export default function* sagas() {
  yield all([takeLatest(FETCH_CLIENT, fetchClientSaga)]);
  yield all([takeLatest(GET_BOILER_QUOTE, getBoilerQuoteSaga)]);
  yield all([takeLatest(VALIDATE_PHONE_NUMBER, valdiatePhoneNumberSaga)]);
  yield all([takeLatest(VALIDATE_EMAIL_ADDRESS, valdiateEmailSaga)]);
  yield all([takeLatest(SAVE_BOILER_QUOTE, saveBoilerQuoteSaga)]);
  yield all([takeLatest(GET_FORM_DETAILS, getFormDetailsSaga)]);
  yield all([takeLatest(GET_PRODUCT_DETAILS, getProductDetailsSaga)]);
  yield all([takeLatest(GET_CONTROL_DETAILS, getControlDetailsSaga)]);
  // NEW
  yield all([takeLatest(GET_FILTER_DETAILS, getFilterDetailsSaga)]);
  yield all([takeLatest(GET_SMART_DEVICES, getAddSmartDeviceSagas)]);
  yield all([takeLatest(GET_DROPDOWN_DETAILS, getProductDropdownSaga)]);
  yield all([takeLatest(ORDER_SUMMARY_DETAILS, postOrderSummarySaga)]);
  yield all([takeLatest(GET_ORDER_SUMMARY, getOrderSummarySaga)]);

  yield all([takeLatest(SAVE_BOOKING_MAIL, postSaveQuoteSEmailSaga)]);
  yield all([takeLatest(HELP_CHOOSE_BOILER, helpChooseBoilerSaga)]);
  yield all([takeLatest(ADD_PRODUCT_TO_BASKET, postAddProductToCartSaga)]);
  yield all([takeLatest(GET_CART_PRODUCT_DETAILS, getCartProductSaga)]);

  yield all([takeLatest(GET_RADIATOR_TYPE, getARadiatorSaga)]);
  yield all([
    takeLatest(GET_SINGLE_PRODUCT_DETAILS, getSingleProductDetailsSaga),
  ]);
  yield all([takeLatest(GET_VALVE, getAValveSaga)]);
  yield all([takeLatest(GET_INSTALLER, getInstallerDetailSaga)]);
  yield all([takeLatest(GET_BOOKING_ORDER, postBookOrderSummarySaga)]);
  yield all([takeLatest(GET_WHATS_INCLUDED_DATA, getWhatsIncludedSaga)]);
  yield all([takeLatest(GET_AVAILABILITY_DATE, getAvailabilityDate)]);
  yield all([takeLatest(GET_ADDITIONAL_CHARGES, getAdditionalChargesSaga)]);
  yield all([takeLatest(GET_USERINFO_BOOKING_DETAILS, getUserInfoDetailsSaga)]);
  yield all([
    takeLatest(GET_SINGLE_CHOOSE_CONTROL, getSingleControlDetailSaga),
  ]);
  yield all([
    takeLatest(STARTED_CREATING_PAYMENT, postclientDataForSecretKeySaga),
  ]);
  yield all([
    takeLatest(GET_SMART_DEVICES_DETAILS, addSmartDeviceSingleProduct),
  ]);
  yield all([takeLatest(REMOVE_CART_ITEM, removeProductFromCartSaga)]);
  yield all([takeLatest(STORE_PAYMENT_DATA, postStorePaymentDataSaga)]);
  yield all([takeLatest(GET_RADIATOR_HEIGHT, getRadiatorHeightSaga)]);
  yield all([takeLatest(GET_RADIATOR_LENGTH, getRadiatorLengthSaga)]);
  yield all([takeLatest(GET_RADIATOR_PRICE_BTU, getRadiatorPriceandBtuSaga)]);
  yield all([
    takeLatest(PRODUCT_RADIATOR_INFO_DATA, getProductInformationDataSaga),
  ]);
  yield all([takeLatest(GET_RADIATOR, getARadiatorImagedataSaga)]);
  yield all([
    takeLatest(GET_ALL_SAVE_USER_DATA_DETAILS, getAllSavedDataForResumeSaga),
  ]);
  yield all([takeLatest(SHOW_PAYMENT_DATA, getShowpaymentDetailsSaga)]);
  yield all([takeLatest(GET_TERM_CONDITIONS, getTermConditionDataSaga)]);
  yield all([takeLatest(GET_PRIVACY_POLICY, getPrivacyPolicySaga)]);
  yield all([takeLatest(PHONE_VALIDATION, getPhoneNumberValidateData)]);

  yield all([takeLatest(GET_CURRENCY, dynamicCurrencyEmailPhoneSaga)]);
  yield all([takeLatest(POST_QUOTE_EMAIL, postQuoteEmailSaga)]);
  yield all([takeLatest(POST_ALL_PRODUCT_EMAIL, postAllProductEmailSaga)]);
  yield all([
    takeLatest(POST_PARTICULAR_PRODUCT_EMAIL, postParticularProductEmailSaga),
  ]);
}

//
//dynamicCurrencyEmailPhoneSaga
