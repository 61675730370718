import React, { useState } from "react";
const Accordion = ({ title, description }) => {
  const [showDesc, setShowDesc] = useState(false);
  return (
    <>
      <div className="column isflex is-flex-direction-column box">
        <div
          className="columns is-flex is-flex-direction-row is-justify-content-left is-align-items-center p-3 pl-4 "
          onClick={() => setShowDesc(!showDesc)}
        >
          {showDesc ? (
            <i class="fa-solid fa-minus"></i>
          ) : (
            <i class="fa-solid fa-plus" style={{ color: "coral" }}></i>
          )}

          <div className="column has-text-left ml-2 has-text-weight-semibold is-size-6-mobile">
            {title}
          </div>
        </div>
        {showDesc && (
          <div className="column has-text-left ">
            <p className="is-size-7-mobile" style={{ lineHeight: "1.7" }}>
              {" "}
              {description}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default Accordion;
