import React, { useEffect, useRef } from "react";
import { createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_FORM_DETAILS } from "./redux/formActions";
import { useForm } from "react-hook-form";
import { storeSelectedAddress } from "./redux/formReducer";
import { setRoute } from "../../../scenes/router/redux/reducer";
import { ROUTES } from "../../../utils/constants";
import { GoogleApiWrapper, Autocomplete } from "google-maps-react";
import { useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
export const AddresComponents = ({ props }) => {
  const dispatch = useDispatch();
  const [postalCode, setPostalCode] = useState("");
  const [address, setAddress] = useState("");
  const [formatedAdre, setFormatedAdd] = useState("");
  const [coordinate, setCoordinate] = useState({
    lat: null,
    lng: null,
  });

  const addressAndPostData = {
    post_code: postalCode && postalCode,
    address: address,
  };
  const jsonData = JSON.stringify(addressAndPostData);

  localStorage.setItem("addressAndPostData", jsonData);
  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setAddress(value);
      setCoordinate(latLng);

      // Find postal code in address_components of the first result
      const postalCodes = results[0].address_components
        .filter((component) => component.types.includes("postal_code"))
        .map((component) => component.long_name);
      setPostalCode(postalCodes[0] || "");
      setFormatedAdd(results[0].formatted_address || "");
    } catch (error) {
      console.error("Error while geocoding:", error);
    }
  };
  const handelAddress = () => {
    dispatch(
      storeSelectedAddress({
        payload: {
          postCode: postalCode && postalCode.toString(),
          address: formatedAdre,
        },
      })
    );
    //   handelNext();
    dispatch(setRoute(ROUTES.infoForm));
  };

  const { clientData } = useSelector(({ clientDetails }) => clientDetails);
  const [searchOptions, setSearchOptions] = useState(null);

  useEffect(() => {
    geocodeByAddress(clientData.restricted_area).then((results) => {
      const location = results[0].geometry.location;
      setSearchOptions({
        location: location,
        radius: 90000,
        strictBounds: true,
        locationRestriction: {
          south: location.lat() - 3,
          west: location.lng() - 3,
          north: location.lat() + 3,
          east: location.lng() + 3,
        },
      });
    });
  }, []);

  return (
    <section className="section has-text-centered" id="Section25">
      <div className="is-max-smalldesktop">
        <div className="step-title">
          <h1 className="heading-bold">
            Finally, enter the post code of the property where we will perform
            the install
          </h1>
        </div>
        <div className="box">
          <div className="card-content">
            <div className="content">
              <p className="subtitle is-size-5">
                {" "}
                We use this to assign you a local installer make sure you enter
                the right one, or you'll have to go back and change it later
              </p>
            </div>
          </div>
          {searchOptions && (
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
              searchOptions={searchOptions}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div style={{ marginBottom: "35px" }}>
                  <input
                    {...getInputProps({ placeholder: "Type address" })}
                    className="input is-medium"
                  />
                  <div>{loading ? <div>loading.....</div> : null}</div>
                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#d9d9d9" : "#fff",
                      height: "28px",
                      color: "Black",
                      textAlign: "left",
                      padding: "15px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, { style })}
                        className="column"
                      >
                        <p className="title is-7"> {suggestion.description}</p>
                      </div>
                    );
                  })}
                </div>
              )}
            </PlacesAutocomplete>
          )}
          <div className="control my-1">
            {postalCode && (
              <>
                {" "}
                <p className="title is-6 has-text-left is-size-7-mobile">
                  <span> Postal Code:</span> {postalCode}
                </p>
              </>
            )}
            {formatedAdre && (
              <>
                {" "}
                <p className="title is-6 has-text-left is-size-7-mobile">
                  <span> Address :</span>
                  <span className="ml-1">{formatedAdre}</span>
                </p>
              </>
            )}
          </div>
          <div className="field mt-3">
            <div className="main-btn-sec">
              <button
                onClick={handelAddress}
                disabled={!formatedAdre}
                className="home-survey"
              >
                Next <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
          {/* <button
        disabled={!postcode}
        style={{ backgroundColor: "blue", color: "white" }}
      >
        Handle next
      </button> */}
        </div>
      </div>
    </section>
  );
};
