import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CalenderComponent from "../../myComponent/molecule/Modal/CalenderComponent";
import EveryThingIncludedModal from "../../myComponent/molecule/Modal/EverythingIncludedModal";
import SaveTheQuote from "../../myComponent/molecule/Modal/SaveTheQuote";
import Checkout from "../../myComponent/organisms/checkoutpage/Checkout";
import { STARTED_CREATING_PAYMENT } from "../../myComponent/organisms/checkoutpage/redux/paymentAction";
import "./../../styles/styles.css";
import "./OrderSummary.css";
import {
  GET_INSTALLER,
  ORDER_SUMMARY_DETAILS,
} from "./redux/OrderSummaryActions";

const OrderSummary = () => {
  const dispatch = useDispatch();
  const nameRegex = /^[A-Za-z\s]*$/;

  const [installationDate, setInstallationDate] = useState(false);
  const [EditAddress, setEditAddress] = useState(false);
  const [saveQuoteModal, setSaveQuoteModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [meetInstaller, setMeetInstaller] = useState(false);
  const [userInfoData, setUserInfoData] = useState(false);
  const [chooseYourCalenderDate, setChooseYourCalenderDate] = useState(false);
  const [paymentGatewayCheckoutPage, setpaymentGatewayCheckOutPage] = useState(
    false
  );
  const [date, setDate] = useState();
  const [toggleCalender, setToggleCalender] = useState(false);
  const [isEverythingIncModal, setIsEverythingIncModal] = useState(false);

  const { CartData } = useSelector(({ addProductToCart }) => addProductToCart);
  const { getRadiatorImageData } = useSelector((state) => state.getARadiator);
  const { secretData, isLoadingKeyData } = useSelector(
    ({ getClientSecretDetail }) => getClientSecretDetail
  );
  const { isPostOrderSummarLoading } = useSelector(
    ({ postOrderSummary }) => postOrderSummary
  );
  const { currencyPhoneEmail, isLoadingdynamicCurrrencyandPhone } = useSelector(
    ({ getBoilerQuote }) => getBoilerQuote
  );
  const selectedSmartDevice = useSelector(
    (selectedSmartDevice) => selectedSmartDevice
  );
  const selectedSmartDeviceDesc =
    selectedSmartDevice.bucketReducer.selectedSmartdevice;

  const { getOrderSummaryData, isLoading, getInstallerData } = useSelector(
    (state) => state.postOrderSummary
  );

  let postalCode;
  let address;
  let previousFirstName;
  let PreviouslastName;
  let PreviousEmailAddress;
  let PreviousPhoneNumber;
  let PreviousCity;
  let PreviousCountry;

  const jsonData = localStorage.getItem("addressAndPostData");
  if (jsonData !== null) {
    const data = JSON.parse(jsonData);
    address = data.address;
    let addressParts = address.split(",");
    PreviousCity = addressParts[0].replace(/\s+$/g, "");
    PreviousCountry = addressParts[addressParts.length - 1].replace(
      /^\s+/g,
      ""
    );
  }

  const PreviousFormData = localStorage.getItem("StoreFormData");
  if (PreviousFormData !== null) {
    const formdataval = JSON.parse(PreviousFormData);
    previousFirstName = formdataval.First_Name;
    PreviouslastName = formdataval.Last_Name;
    PreviousEmailAddress = formdataval.Email_Id;
    PreviousPhoneNumber = formdataval.phoneNumber;
    postalCode = formdataval.Post_Code;
  }

  const toggleCalenderHandler = () => {
    const payload = {
      calander_date: date,
    };
    dispatch({ type: ORDER_SUMMARY_DETAILS, payload });
    dispatch({ type: GET_INSTALLER });
    setToggleCalender(false);
    setMeetInstaller(true);
    setUserInfoData(true);
  };

  useEffect(() => {
    setInstallationDate(true);
  }, []);

  const {
    register,
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: previousFirstName ? previousFirstName : "",
      RentalProperty: "",
      LastName: PreviouslastName ? PreviouslastName : "",
      address: "",
      email: PreviousEmailAddress ? PreviousEmailAddress : "",
      postcode: postalCode ? postalCode : "",
      number: PreviousPhoneNumber ? PreviousPhoneNumber : "",
      surveyType: "",
      salutation: "",
      useradressField: "",
      addressLine1: address ? address : "",
      addressLine2: "",
      City: PreviousCity ? PreviousCity : "",
      Country: PreviousCountry ? PreviousCountry : "",
      addressLine3: "",
    },
  });

  const onSubmit = (data) => {
    setFormData(data);

    const payload = {
      // calander_date: "20-06-2023",
      installation_add_1: data.addressLine1,
      installation_add_2: data.addressLine2 ? data.addressLine2 : "",
      installation_add_3: data.addressLine3 ? data.addressLine3 : "",
      installation_city_or_town: data.City,
      installation_country: data.Country,
      installation_post_codes: data.postcode,
      details_title: data.salutation,
      details_first_name: data.name,
      details_surname: data.LastName,
      details_email: data.email,
      details_mobile: data.number,
      hear_about_boxt: data.surveyType,
    };
    reset();
    dispatch({ type: ORDER_SUMMARY_DETAILS, payload });

    const actionPayload = {
      amount: CartData && CartData.grand_total,
      // amount: 900,
      currency: "INR",
      method: "card",
      desc: "hii add description here",
      name: data.name,
      enable: true,
      email: data.email,
      address_line: data.addressLine1,
      postal_code: data.postcode,
      city: data.City,
      state: "CG",
      country: data.Country,
    };

    dispatch({ type: STARTED_CREATING_PAYMENT, payload: actionPayload });
    setpaymentGatewayCheckOutPage(true);
    setUserInfoData(false);
  };

  const handleInstallationDateAndCalender = () => {
    setInstallationDate(false);
    setChooseYourCalenderDate(true);
    setToggleCalender(true);
  };

  const handleInstallerAndVisting = () => {
    // const payload = {
    //   order_total_amount: CartData.grand_total ? CartData.grand_total : "",
    //   product_id: CartData.product_id && CartData.product_id,
    //   controler_id: CartData.control_id && CartData.control_id,
    //   thermostat_id: CartData.thermostate_id && CartData.thermostate_id,
    //   radiator_id: CartData.radiator_id && CartData.radiator_id,
    //   volvo_id: CartData.volvo_id ? CartData.volvo_id : 0,
    // };

    // dispatch({ type: GET_BOOKING_ORDER, payload });

    setMeetInstaller(false);
    setUserInfoData(true);
  };

  const handleToPayPage = () => {
    setUserInfoData(false);
  };

  const handleEditAddress = () => {};

  if (isLoading) {
    return (
      <>
        <div className="loaderContainer">
          <div className="mainLoader"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <section className="less-pad-mob">
        <div class="new-title-sec title-sec pb-3">
          <h2>Here's your quote </h2>
        </div>

        <div className="checkout-box">
          <section>
            <div className="p-2">
              <div className="checkout-flex-box">
                <div className="checkout-lable">
                  <i class="fas fa-cart-shopping"></i>
                  <p>
                    Your total fixed price is{" "}
                    {currencyPhoneEmail && currencyPhoneEmail.company_currency}{" "}
                    {CartData && CartData.grand_total
                      ? Number(CartData.grand_total).toLocaleString()
                      : 0}
                  </p>
                </div>

                <div
                  className="checkout-edit"
                  onClick={() => {
                    setInstallationDate(true);
                    setToggleCalender(false);
                    setMeetInstaller(false);
                    setUserInfoData(false);
                  }}
                >
                  <p>
                    <i class="fa-solid fa-pen-to-square"></i> edit
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* PART 1 */}
          {installationDate && (
            <section className="p-4 is-p-2-mobile">
              <div className="order-summary-align-left">
                <div className="order-summry-box">
                  <div className="order-summry-box-inner">
                    <section>
                      <div className="tag-poins-box">
                        <div className="tag-poins">
                          <div className="tag-poins-icon">
                            <i class="fa-solid fa-quote-left"></i>
                          </div>
                          <p>
                            {" "}
                            We'll beat any like-for-like quote, or give you
                            {currencyPhoneEmail &&
                              currencyPhoneEmail.company_currency}
                            50.
                          </p>
                        </div>

                        <div className="tag-poins">
                          <div className="tag-poins-icon">
                            <i class="fa-solid fa-check-to-slot"></i>
                          </div>
                          <p>Every job is audited by our technical team</p>
                        </div>

                        <div className="tag-poins">
                          <div className="tag-poins-icon">
                            <i class="fa-solid fa-ranking-star"></i>
                          </div>
                          <p>We're top rated </p>
                        </div>
                      </div>
                    </section>

                    <section className="mt-3">
                      <div className="new-summary-list-box-inner ">
                        {CartData && CartData.boiler_title && (
                          <div className="list-items">
                            <div className="item-icon">
                              <img src={CartData.boiler_image_path} />
                              {/* <img src={CartData.boiler_image_path} /> */}
                              <div className="item-descrip">
                                <strong className="is-size-6-mobile">
                                  {" "}
                                  {CartData && CartData.boiler_title}
                                </strong>
                                {/* <p className="subtitle is-6">
                                with 10 year warranty
                              </p> */}
                              </div>
                            </div>

                            <div className="item-price">
                              <h5 className="subtitle">
                                <strong>
                                  {currencyPhoneEmail &&
                                    currencyPhoneEmail.company_currency}
                                  {CartData &&
                                    Number(
                                      CartData.boiler_cost
                                    ).toLocaleString()}
                                </strong>
                              </h5>
                            </div>
                          </div>
                        )}

                        {CartData && CartData.controls_name && (
                          <div className="list-items">
                            <div className="item-icon">
                              <img src={CartData.controls_image} />
                              {/* <img src={CartData.controls_image} /> */}
                              <div className="item-descrip">
                                <strong className="is-size-6-mobile">
                                  {CartData.controls_name}
                                </strong>
                                {/* <p className="subtitle is-6">
                                Including {selectedBoilerProduct.warranty_year}{" "}
                                guarantee
                              </p> */}
                              </div>
                            </div>

                            <div className="item-price">
                              <h5 className="subtitle">
                                <strong>
                                  {" "}
                                  {currencyPhoneEmail &&
                                    currencyPhoneEmail.company_currency}{" "}
                                  {Number(
                                    CartData.controls_price
                                  ).toLocaleString()}
                                </strong>
                              </h5>
                            </div>
                          </div>
                        )}

                        {CartData && CartData.title && (
                          <div className="list-items">
                            <div className="item-icon">
                              {/* <img src={CartData.controls_image} /> */}
                              <img src={CartData.image_path} />
                              <div className="item-descrip">
                                <strong className="is-size-6-mobile">
                                  {CartData.title}
                                </strong>
                                {/* <p className="subtitle is-6">
                                warrenty of{" "}
                                {selectedSmartDeviceDesc.warranty_year}
                              </p> */}
                              </div>
                            </div>

                            <div className="item-price">
                              <h5 className="subtitle">
                                <strong>
                                  {currencyPhoneEmail &&
                                    currencyPhoneEmail.company_currency}{" "}
                                  {Number(
                                    CartData.thermostate_price
                                  ).toLocaleString()}
                                </strong>
                              </h5>
                            </div>
                          </div>
                        )}
                        {CartData && CartData.type && (
                          <div className="list-items">
                            <div className="item-icon">
                              <img
                                src={
                                  getRadiatorImageData &&
                                  getRadiatorImageData.image
                                }
                              />
                              <div className="item-descrip">
                                <strong className="is-size-6-mobile">
                                  {" "}
                                  {CartData.type}
                                </strong>
                                {/* <p className="subtitle is-6">
                                warrenty of{" "}
                                {selectedSmartDeviceDesc.warranty_year}
                              </p> */}
                              </div>
                            </div>

                            <div className="item-price">
                              <h5 className="subtitle">
                                <span className="quantity-text">
                                  qty: {CartData.radiator_qty}
                                </span>

                                <strong>
                                  {currencyPhoneEmail &&
                                    currencyPhoneEmail.company_currency}{" "}
                                  {Number(
                                    CartData.radiator_price
                                  ).toLocaleString()}
                                </strong>
                              </h5>
                            </div>
                          </div>
                        )}
                        {CartData && CartData.volvo_title && (
                          <div className="list-items">
                            <div className="item-icon">
                              <img src={CartData.volvoe_image} />
                              <div className="item-descrip">
                                <strong className="is-size-6-mobile">
                                  {" "}
                                  {CartData.volvo_title}
                                </strong>
                                {/* <p className="subtitle is-6">
                                warrenty of{" "}
                                {selectedSmartDeviceDesc.warranty_year}
                              </p> */}
                              </div>
                            </div>

                            <div className="item-price">
                              <h5 className="subtitle">
                                <span className="quantity-text">
                                  qty: {CartData.volvoe_qty}
                                </span>
                                <strong>
                                  {currencyPhoneEmail &&
                                    currencyPhoneEmail.company_currency}{" "}
                                  {Number(
                                    CartData.volvoe_price
                                  ).toLocaleString()}
                                </strong>
                              </h5>
                            </div>
                          </div>
                        )}

                        <div className="clearfix"></div>

                        <div className="fixed-price-box">
                          <div className="list-items-price">
                            <div className="left-info">
                              <p className="checkout-price-heading">
                                Total fixed price including VAT
                              </p>
                            </div>

                            <div className="right-info">
                              <p className="checkout-price-text">
                                {currencyPhoneEmail &&
                                  currencyPhoneEmail.company_currency}
                                {CartData &&
                                  CartData.grand_total.toLocaleString()}
                              </p>
                              <p className="is-underlined is-size-7">
                                or{" "}
                                {currencyPhoneEmail &&
                                  currencyPhoneEmail.company_currency}
                                28.51 per month{" "}
                                {currencyPhoneEmail
                                  ? currencyPhoneEmail.company_vat
                                  : "0"}
                                %
                              </p>
                            </div>
                          </div>

                          <div className="promocode-box">
                            <p className="control is-expanded">
                              <input
                                className="input"
                                type="text"
                                placeholder="Got a promo code? Enter it here"
                              />
                            </p>
                            <p className="control">
                              <a className="button is-info"> Apply</a>
                            </p>
                          </div>
                          <div
                            className="has-text-centered"
                            onClick={() => setIsEverythingIncModal(true)}
                          >
                            <a className=" subtitle ">
                              <p className="textUnderlineRed subtitle is-6 has-underline is-size-6-mobile">
                                {" "}
                                What’s included in my installation?
                              </p>
                            </a>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </section>
                    <section>
                      <div className="summary-buttons">
                        <div className="main-btn-sec">
                          <button
                            className="home-survey"
                            onClick={handleInstallationDateAndCalender}
                          >
                            Next <i class="fa-solid fa-arrow-down"></i>
                          </button>

                          {/* <button
                            className="save-quote"
                            onClick={() => setSaveQuoteModal(true)}
                          >
                            Save quote <i class="fa-solid fa-envelope"></i>
                          </button> */}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
        <fieldset
          disabled={installationDate ? true : false}
          className="checkout-box"
        >
          <section>
            <div className="p-2">
              <div className="checkout-flex-box">
                <div className="checkout-lable">
                  <i class="fas fa-calendar-days"></i>
                  <p>
                    {date ? (
                      format(date, "dd MMM yyyy", {
                        locale: enGB,
                      })
                    ) : (
                      <p>When should we install?</p>
                    )}
                  </p>
                </div>

                <div
                  className="checkout-edit"
                  onClick={() => {
                    setToggleCalender(true);
                    setMeetInstaller(false);
                    setUserInfoData(false);
                  }}
                >
                  {/* <p> 
                      {toggleCalender && chooseYourCalenderDate ? " " : "Edit"}
                    </p> */}

                  <p>
                    {" "}
                    <i class="fa-solid fa-pen-to-square"></i> edit{" "}
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* PART 2 */}
          {toggleCalender && chooseYourCalenderDate && (
            <section className="">
              <div className="customer-details-box mt-5">
                <div className="customer-details-box-inner">
                  <div className="column">
                    <div className="is-flex is-flex-direction-column cal-box">
                      <CalenderComponent date={date} setDate={setDate} />

                      <div className="main-btn-sec mb-5">
                        <button
                          disabled={!date || isPostOrderSummarLoading}
                          className={`home-survey ${
                            isPostOrderSummarLoading ? "is-loading" : ""
                          }`}
                          onClick={toggleCalenderHandler}
                        >
                          {isPostOrderSummarLoading ? "Loading..." : "Next"}{" "}
                          <i class="fa-solid fa-arrow-down"></i>
                        </button>
                      </div>

                      <div className="mt-5 p-5 has-background-info-light">
                        <p>
                          Don’t see the date you’re after? Message us on or call
                          us on{" "}
                          <a href={`tel:${currencyPhoneEmail.company_phone}`}>
                            {currencyPhoneEmail.company_phone}
                          </a>{" "}
                          and we’ll see if we can install sooner.
                          <br></br>
                          <br></br>
                          Your local installer will arrive between 7.30 - 9.30am
                          and your delivery will arrive separately by courier.
                          If anything changes, we’ll be in touch
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </fieldset>

        {/* PART 3 */}
        {/* <div className="checkout-box">
          <section>
            <div className="p-2">
              <div className="checkout-flex-box">
                <div className="checkout-lable">
                  <i class="fas fa-user"></i>
                  <div>
                    {getInstallerData && getInstallerData.length > 0 ? (
                      getInstallerData.map((item, index) => (
                        <p>Local Installer is {item.installer_name}</p>
                      ))
                    ) : (
                      <p>Meet Your Local Installer</p>
                    )}
                  </div>
                </div>

                <div
                  className="checkout-edit"
                  onClick={() => {
                    setToggleCalender(false);
                    setMeetInstaller(true);
                    setUserInfoData(false);
                  }}
                >
                  <p>
                    <i class="fa-solid fa-pen-to-square"></i> edit
                  </p>
                </div>
              </div>
            </div>
          </section>
          {meetInstaller && (
            <section className="has-text-centered">
              <div className="column installation-time-take">
                <p>
                  <i class="fa-solid fa-clock"></i> Your installation will take
                  up to 2 days
                </p>
              </div>
              {getInstallerData &&
                getInstallerData.map((item) => {
                  return (
                    <div className="summary-box">
                      <div className="installer-box">
                        <div>
                          <b className="subtitle is-4 is-size-5-mobile">
                            Hi I'm {item.installer_name} 👋
                          </b>
                        </div>
                        <div className="mt-3">
                          <p>{item.intsaller_description}</p>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="second-installer-box">
                        <i class="fa-solid fa-van-shuttle"></i>

                        <p>
                          On the rare occasion that we need to change your
                          chosen installer we’ll send you an email to let you
                          know.
                        </p>
                      </div>

                      <div className=" main-btn-sec">
                        <button
                          className="home-survey"
                          onClick={handleInstallerAndVisting}
                        >
                          Next <i class="fa-solid fa-arrow-down"></i>
                        </button>
                      </div>
                    </div>
                  );
                })}
            </section>
          )}
        </div> */}
        {/* PART 4 */}
        <div className="checkout-box">
          <section>
            <div className="p-2">
              <div className="checkout-flex-box">
                <div className="checkout-lable">
                  <i class="fas fa-location-dot"></i>
                  <div>
                    {formData ? (
                      formData.addressLine1 ? (
                        <p>{formData.addressLine1}</p>
                      ) : (
                        <p>Where we are Visiting</p>
                      )
                    ) : (
                      <p>Where we are Visiting</p>
                    )}
                  </div>
                </div>

                <div
                  className="checkout-edit"
                  onClick={() => {
                    setToggleCalender(false);
                    setMeetInstaller(false);
                    setUserInfoData(true);
                  }}
                >
                  {" "}
                  <p>
                    {" "}
                    <i class="fa-solid fa-pen-to-square"></i> edit
                  </p>
                </div>
              </div>
            </div>
          </section>
          {userInfoData && (
            <div className="customer-details-box  ">
              <div className="customer-details-box-inner">
                <h4 className="subtitle is-4 has-text-left">
                  Installation address
                </h4>
                <fieldset>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="columns">
                      <div className="column">
                        <label className="label1 has-text-left is-size-6-mobile">
                          Address line 1
                        </label>

                        <Controller
                          name="addressLine1"
                          control={control}
                          rules={{ required: "addressLine1 is required" }}
                          render={({ field }) => (
                            <input
                              className="input is-size-6-mobile"
                              {...field}
                            />
                          )}
                        />
                        {errors.addressLine1 && (
                          <span className="is-size-6-mobile has-text-danger">
                            {errors.addressLine1.message}*
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="columns ">
                      <div
                        className="column is-flex is-justify-content-center is-align-items-center"
                        onClick={() => setEditAddress(!EditAddress)}
                      >
                        {" "}
                        <p className="address-edit">
                          <i class="fa-solid fa-pen-to-square"></i> Edit Address
                        </p>
                      </div>
                    </div>

                    {EditAddress && (
                      <>
                        {" "}
                        <div className="columns">
                          <div className="column">
                            <label className="label1 has-text-left is-size-6-mobile">
                              Address Line 2(optional)
                            </label>

                            <Controller
                              name="addressLine2"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className="input is-size-6-mobile"
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="columns">
                          <div className="column">
                            <label className="label1 has-text-left is-size-6-mobile">
                              Address Line 3(optional)
                            </label>

                            <Controller
                              name="addressLine3"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className="input is-size-6-mobile"
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="columns">
                      <div className="column">
                        <label className="label1 has-text-left is-size-6-mobile">
                          City or Town
                        </label>

                        <Controller
                          name="City"
                          control={control}
                          rules={{
                            required: "City is required",
                            pattern: {
                              value: nameRegex,
                              message: "Invalid City name",
                            },
                          }}
                          render={({ field }) => (
                            <input
                              className="input is-size-6-mobile"
                              {...field}
                            />
                          )}
                        />
                        {errors.City && (
                          <span className="is-size-6-mobile has-text-danger has-text-left">
                            {errors.City.message}*
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <label className="label1 has-text-left is-size-6-mobile">
                          Country
                        </label>

                        <Controller
                          name="Country"
                          control={control}
                          rules={{
                            required: "Country is required",
                            pattern: {
                              value: nameRegex,
                              message: "Invalid Country name",
                            },
                          }}
                          render={({ field }) => (
                            <input
                              className="input is-size-6-mobile"
                              {...field}
                            />
                          )}
                        />
                        {errors.Country && (
                          <span className="is-size-6-mobile has-text-danger">
                            {errors.Country.message}*
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="has-background-primary-light">
                      <p className="p-5 ">Is this a rental property?</p>
                      <div className="column is-full">
                        <div className="is-flex is-flex-direction-row is-justify-content-space-around is-align-item-center">
                          <div className="column">
                            <Controller
                              name="RentalProperty"
                              rules={{ required: " required" }}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="radio"
                                  value="rental"
                                  id="rentalRadio"
                                />
                              )}
                            />
                            <label
                              className="subtitle is-size-7 my-1 px-2"
                              htmlFor="rentalRadio"
                            >
                              Yes, this is a rental property
                            </label>
                          </div>
                          <div className="column">
                            <Controller
                              name="RentalProperty"
                              control={control}
                              rules={{ required: " required" }}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="radio"
                                  value="homeowner"
                                  id="homeownerRadio"
                                />
                              )}
                            />
                            <label
                              className="subtitle is-size-7 my-1 px-2"
                              htmlFor="homeownerRadio"
                            >
                              No, I’m the homeowner
                            </label>
                          </div>
                        </div>
                        {errors.RentalProperty && (
                          <span className="is-size-6-mobile has-text-danger has-text-left">
                            {errors.RentalProperty.message}*
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="columns my-4 p-3">
                      <p className="title is-6 has-text-bold has-text-left is-size-6-mobile">
                        Your details
                      </p>
                    </div>

                    <div className="columns is-flex is-flex-direction-row  is-align-item-center">
                      <div className="column">
                        <label className="label1 has-text-left is-size-6-mobile">
                          FirstName
                        </label>
                        <Controller
                          name="name"
                          control={control}
                          rules={{
                            required: "FirstName is required",
                            pattern: {
                              value: nameRegex,
                              message: "Invalid FirstName",
                            },
                          }}
                          render={({ field }) => (
                            <input
                              className="input is-size-6-mobile"
                              {...field}
                            />
                          )}
                        />
                        {errors.name && (
                          <span className="is-size-6-mobile has-text-danger has-text-left">
                            {errors.name.message}*
                          </span>
                        )}
                      </div>

                      <div className="column">
                        <label className="label1 has-text-left is-size-6-mobile">
                          LastName
                        </label>
                        <Controller
                          name="LastName"
                          control={control}
                          rules={{
                            required: "LastName is required",
                            pattern: {
                              value: nameRegex,
                              message: "Invalid LastName",
                            },
                          }}
                          render={({ field }) => (
                            <input
                              className="input is-size-6-mobile"
                              {...field}
                            />
                          )}
                        />
                        {errors.LastName && (
                          <span className="is-size-6-mobile has-text-danger has-text-left">
                            {errors.LastName.message}*
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column">
                        <label className="label1 has-text-left is-size-6-mobile">
                          Email
                        </label>

                        <Controller
                          name="email"
                          control={control}
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                          rules={{ required: "Email is required" }}
                          render={({ field }) => (
                            <input
                              type="email"
                              className="select input is-hovered is-size-6-mobile"
                              {...field}
                            />
                          )}
                        />
                        {errors.email && (
                          <span className="is-size-6-mobile has-text-danger has-text-left">
                            {errors.email.message}*
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column">
                        <label className="label1 has-text-left is-size-6-mobile">
                          Phone Number
                        </label>

                        <Controller
                          name="number"
                          control={control}
                          rules={{ required: "PhoneNumber is required" }}
                          render={({ field }) => (
                            <input
                              className="select input is-hovered is-size-6-mobile"
                              type="tel"
                              pattern="[0-9]{4}[0-9]{3}[0-9]{3}"
                              {...field}
                            />
                          )}
                        />
                        {errors.number && (
                          <span className="is-size-6-mobile has-text-danger has-text-left">
                            {errors.number.message}*
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <label className="label1 has-text-left is-size-6-mobile">
                          Post Code
                        </label>

                        <Controller
                          name="postcode"
                          control={control}
                          // rules={{ required: "postcode is required" }}
                          render={({ field }) => (
                            <input
                              className="select input is-hovered is-size-6-mobile"
                              {...field}
                            />
                          )}
                        />
                        {errors.postcode && (
                          <span className="is-size-6-mobile has-text-danger has-text-left">
                            {errors.postcode.message}*
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <label className="label1 has-text-left is-size-6-mobile">
                          How did you first hear about NuHome?
                        </label>
                        <Controller
                          name="surveyType"
                          control={control}
                          rules={{ required: "Survey Type is required" }}
                          render={({ field }) => (
                            <select
                              {...field}
                              className="select input is-hovered is-size-6-mobile"
                            >
                              <option value="">Select dropdown</option>
                              <option value="Google">Google</option>
                              <option value="Heard from people">
                                Heard from people
                              </option>
                              <option value="Youtube">Youtube</option>
                              <option value="Other">Other</option>
                            </select>
                          )}
                        />
                        {errors.surveyType && (
                          <span className="is-size-6-mobile has-text-danger has-text-left">
                            {errors.surveyType.message}*
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="main-btn-sec">
                      <Controller
                        name="submit"
                        control={control}
                        render={({ field }) => (
                          <span
                          // disabled={!isValid}
                          // onClick={handleToPayPage}
                          >
                            {isLoadingKeyData ? (
                              <span className="loader"></span>
                            ) : (
                              <button className="home-survey">
                                {" "}
                                Proceed to Pay{" "}
                                <i class="fa-solid fa-arrow-down"></i>{" "}
                              </button>
                            )}
                          </span>
                        )}
                      />
                    </div>
                  </form>
                </fieldset>
              </div>
            </div>
          )}
        </div>
        <Checkout paymentGatewayCheckoutPage={paymentGatewayCheckoutPage} />
        <EveryThingIncludedModal
          isVisible={isEverythingIncModal}
          onClose={setIsEverythingIncModal}
        />
        <SaveTheQuote
          saveaQuoteModal={saveQuoteModal}
          handleCancel={setSaveQuoteModal}
        />
      </section>
    </>
  );
};
export default OrderSummary;
