import React from "react";
import "./roofwall.css";
import Svg from "../../../atom/image/Svg";
import HTMLReactParser from "html-react-parser";

const RoofWall = ({
  title,
  Options,
  handleSelect,
  questionSubTitle,
  handelModal,
  modalPresent,
}) => {
  const [showing, setShowingData] = React.useState(false);
  const [bgColor, setBgcolor] = React.useState("");
  const isShowing = () => {
    if (showing == true) {
      setShowingData(false);
      setBgcolor("");
    } else {
      setShowingData(true);
      setBgcolor("#f2761a");
    }
  };

  return (
    <section className="section ">
      <div className="container has-text-centered">
        <h2 className="subtitle is-2 is-size-3-mobile mb-3">
          {HTMLReactParser(`${title}`)}
        </h2>

        <h5 class="subtitle is-5 is-size-5-mobile detl-txt">
          {modalPresent == "Yes" ? (
            <a
              class="is-underlined js-modal-trigger"
              data-target="modal-js-1"
              onClick={() => handelModal()}
            >
              <i class="fa fa-info-circle" aria-hidden="true"></i>{" "}
              {questionSubTitle}
            </a>
          ) : (
            <></>
          )}
        </h5>
        <div className="is-flex is-flex-wrap-wrap is-justify-content-center">
          {Options.map((item, index) => {
            return (
              <div
                onClick={() =>
                  handleSelect({
                    item,
                  })
                }
                key={index}
                className=" all-option2 m-2"
              >
                <img src={`/image/${item.option_icon}.svg`} />

                <p>{item.option_title}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default RoofWall;
