import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  error: null,
  getRadiatorImageLoading: false,
  getRadiatorImageData: [],
  getARadiatorData: [],
  radiatorheightdata: [],
  isLoadingradiatorheight: false,
  isgettingRadiatorlengthLoading: false,
  radiatorLengthdata: [],
  isradiatorProductInfoLoading: false,
  productDataOfradiator: [],
  isHelpMeChooseRadiatorLoading: false,
  helpmeChooseRadiatorData: [],
  isgetRadiatorPriceAndBtuLoading: false,
  RadiatorpriceandBTUData: [],
};

const getARadiatorSlice = createSlice({
  name: "getARadiator",
  initialState,
  reducers: {
    fetchgetARadiatorStarted(state) {
      state.isLoading = true;
      state.error = null;
    },

    fetchgetARadiatorSuccess(state, { payload }) {
      state.isLoading = false;
      state.getARadiatorData = payload;
    },
    fetchgetARadiatorFails(state, { payload }) {
      state.isLoading = false;
      state.getARadiatorData = payload;
    },
    getRadiatorheightDataStarted(state) {
      state.isLoadingradiatorheight = true;
    },
    getRadiatorHeightDataSuccess(state, { payload }) {
      state.isLoadingradiatorheight = false;
      state.radiatorheightdata = payload;
    },
    getRadiatorHeightDataFail(state, { payload }) {
      state.isLoadingradiatorheight = false;
      state.radiatorheightdata = payload;
    },
    getRadiatorlengthStarted(state) {
      state.isgettingRadiatorlengthLoading = true;
    },
    getRadiatorlengthSuccess(state, { payload }) {
      state.isgettingRadiatorlengthLoading = false;
      state.radiatorLengthdata = payload;
    },
    getRadiatorlengthFail(state, { payload }) {
      state.isgettingRadiatorlengthLoading = false;
      state.radiatorLengthdata = payload;
    },

    getHelpMeChooseRadiatorStarted(state) {
      state.isHelpMeChooseRadiatorLoading = true;
    },
    getHelpMeChooseRadiatorSuccess(state, { payload }) {
      state.isHelpMeChooseRadiatorLoading = false;
      state.helpmeChooseRadiatorData = payload;
    },
    getHelpMeChooseRadiatorFail(state, { payload }) {
      state.isHelpMeChooseRadiatorLoading = false;
      state.helpmeChooseRadiatorData = payload;
    },
    getradiatorPriceandBTUStarted(state) {
      state.isgetRadiatorPriceAndBtuLoading = true;
    },
    getradiatorPriceandBTUSuccess(state, { payload }) {
      state.isgetRadiatorPriceAndBtuLoading = false;
      state.RadiatorpriceandBTUData = payload;
    },
    getradiatorPriceandBTUfail(state, { payload }) {
      state.isgetRadiatorPriceAndBtuLoading = false;
      state.RadiatorpriceandBTUData = payload;
    },
    getRadiatorProductInformationDataStarted(state) {
      state.isradiatorProductInfoLoading = true;
    },
    getRadiatorProductInformationDataSuccess(state, { payload }) {
      state.isradiatorProductInfoLoading = false;
      state.productDataOfradiator = payload;
    },
    getRadiatorProductInformationDataFail(state, { payload }) {
      state.isradiatorProductInfoLoading = false;
      state.productDataOfradiator = payload;
    },
    getRadiatorImageDataStarted(state) {
      state.getRadiatorImageLoading = true;
    },
    getRadiatorImageDataSuccess(state, { payload }) {
      state.getRadiatorImageLoading = false;
      state.getRadiatorImageData = payload;
    },
    getRadiatorImageDatafail(state, { payload }) {
      state.getRadiatorImageLoading = false;
      state.getRadiatorImageData = payload;
    },
  },
});

const { actions, reducer } = getARadiatorSlice;

export const {
  fetchgetARadiatorStarted,
  fetchgetARadiatorSuccess,
  fetchgetARadiatorFails,
  getRadiatorHeightDataFail,
  getRadiatorheightDataStarted,
  getRadiatorHeightDataSuccess,
  getRadiatorlengthStarted,
  getRadiatorlengthSuccess,
  getRadiatorlengthFail,
  getRadiatorProductInformationDataStarted,
  getRadiatorProductInformationDataSuccess,
  getRadiatorProductInformationDataFail,
  getHelpMeChooseRadiatorFail,
  getHelpMeChooseRadiatorSuccess,
  getHelpMeChooseRadiatorStarted,
  getradiatorPriceandBTUSuccess,
  getradiatorPriceandBTUStarted,
  getradiatorPriceandBTUfail,
  getRadiatorImageDataStarted,
  getRadiatorImageDataSuccess,
  getRadiatorImageDatafail,
} = actions;
export default reducer;
