import React, { useEffect, useRef } from "react";
import { createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_FORM_DETAILS } from "./redux/formActions";
import { useForm } from "react-hook-form";
import { storeSelectedAddress } from "./redux/formReducer";
import { setRoute } from "../../../scenes/router/redux/reducer";
import { ROUTES } from "../../../utils/constants";
import { GoogleApiWrapper, Autocomplete } from "google-maps-react";
import { useState } from "react";

function FormPostcode(props) {
  const dispatch = useDispatch();
  const autocompleteRef = useRef(null);
  const [postcode, setPostCode] = useState("");
  const [postalloading, setPostalLoading] = useState(true);

  const onAutocompleteReady = (autocomplete) => {
    const { google } = props;

    const options = {
      types: ["(regions)"],
      componentRestrictions: { country: "UK" }, // Change country code as per your requirement
    };

    const autocompleteInstance = new google.maps.places.Autocomplete(
      autocomplete,
      options
    );

    autocompleteInstance.addListener("place_changed", () => {
      const place = autocompleteInstance.getPlace();
      if (place && place.address_components) {
        const postalCodes = place.address_components
          .filter((component) => component.types.includes("postal_code"))
          .map((component) => component.long_name);

        setPostCode(postalCodes);
        // Display postal codes in console or store them in state
      }
    });

    // Google Maps API loaded, set loading to false
    setPostalLoading(false);
  };
  console.log("postalloading====>", postalloading);
  useEffect(() => {
    if (autocompleteRef.current) {
      onAutocompleteReady(autocompleteRef.current);
    }
  }, [props]);
  const handelAddress = () => {
    if (postcode) {
      dispatch(
        storeSelectedAddress({
          payload: {
            postCode: postcode && postcode.toString(),
            address: postcode,
          },
        })
      );
      //   handelNext();
      dispatch(setRoute(ROUTES.infoForm));
    }
  };

  //   if (postalloading == true) {
  //     return (
  //       <>
  //         <div className="loaderContainer">
  //           <div className="mainLoader"></div>
  //         </div>
  //       </>
  //     );
  //   }
  return (
    <section className="section has-text-centered" id="Section25">
      <div className="is-max-smalldesktop">
        <div className="step-title">
          <h1 className="heading-bold">
            Finally, enter the post code of the property where we will perform
            the install
          </h1>
        </div>
        <div className="box">
          <div className="card-content">
            <div className="content">
              We use this to assign you a local installer make sure you enter
              the right one, or you'll have to go back and change it later
            </div>
          </div>
          <h1 className="title is-6"> {postcode && postcode.toString()}</h1>
          <input
            ref={autocompleteRef}
            placeholder="Enter PIN code"
            type="text"
            className="input is-medium"
          />
          <div className="field mt-3">
            <div className="main-btn-sec">
              <button
                onClick={handelAddress}
                disabled={!postcode}
                className="home-survey"
              >
                Next <i class="fa-solid fa-square-poll-vertical"></i>
              </button>
            </div>
          </div>
          {/* <button
            disabled={!postcode}
            style={{ backgroundColor: "blue", color: "white" }}
          >
            Handle next
          </button> */}
        </div>
      </div>
    </section>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAYY2jlGhu3mzukE1ghn0Z0LIt6kkR07zk",
})(FormPostcode);
