import React from "react";
import { Image, Shimmer } from "react-shimmer";
import "./modal.css";

const MoreInfoModal = ({
  isMoreInfoModal,
  setIsMoreInfoModal,
  item,
  AddSmartDevivceLoading, 
  isFetchingSingleControls,
}) => {
  return (
    <>
      {isMoreInfoModal && (
        <div className="modal is-active is-flex is-flex-direction-row is-justify-content-end">
          <div
            className="modal-background"
            onClick={() => setIsMoreInfoModal(false)}
          ></div>
          <div
            className="new-modal-card"
          >
            <div className="column">
              {/* Close Modal Btn */}
              <button
                className="delete button"
              
                aria-label="close"
                onClick={() => {
                  setIsMoreInfoModal(false);
                }}
              ></button>

              {/* Modal Title */}

              <div
                className="column"
              >
                <div className="column">
                  <Image
                    src={item.image_path}
                    alt="not found"
                    fallback={<Shimmer />}
                  />
                </div>

                <div className="column">
                  <p className="modal-sub-heading ">
                    {item.controls_description
                      ? item.controls_description
                      : item.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MoreInfoModal;
