import { useEffect } from "react";
import { clientDetailsSelector } from "../../stores/selectors";
import { useSelector } from "react-redux";

const ThemeProvider = () => {
  const { clientData } = useSelector(clientDetailsSelector);
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--primary",
      clientData.company_color
    );
  }, [clientData]);

  return null; // This component doesn't render anything, it just updates the styles
};

export default ThemeProvider;
