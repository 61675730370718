import { call, put } from "redux-saga/effects";
import apiClient from "../../../services/httpServices";

import {
	fetchClientfail,
	fetchClientStarted,
	getClientSuccess,
} from "./LoginAuthReducer";
import { GET_TOKEN } from "../../../services/webConstant";

export function* fetchClientSaga(action) {
	const { payload } = action;
	try {
		yield put(fetchClientStarted());
		const { data } = yield call(
			apiClient.post,
			GET_TOKEN.GET_TOKEN_URL,
			payload
		);

		yield put(
			getClientSuccess({ token: data.token, clientDetails: data.data })
		);
	} catch (err) {
		console.log("Unable to fetch client details and token", err);
		yield put(fetchClientfail(err));
	}
}
