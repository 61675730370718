import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetchingAddSmartDetails: false,
  getAddSmartDevicesData: {},
  selectedOptions: [],
  loading:true,
   AddSmartDevivceLoading:false,
  AddSmartDevice_single_Product_detail:[]
  
};

const getAddSmartDeviceSlice = createSlice({
  name: "getAddSmartDevices",
  initialState,
  reducers: {
    fetchAddSmartDevicesStarted(state) {
      state.isFetchingAddSmartDetails = true;
      state.loading = true;
    },
    fetchAddSmartDevicesSuccess(state, { payload }) {
      state.isFetchingAddSmartDetails = false;
      state.getAddSmartDevicesData = payload;
      state.loading = false;
    },
    fetchAddSmartDevicesFail(state) {
      state.isFetchingAddSmartDetails = true;
      state.loading = false;
    },

    addSmartDeviceSingleProductStarted(state){
      state.AddSmartDevivceLoading=true

    },
    addSmartDeviceSingleProductSuccess(state,{payload}){
      state.AddSmartDevivceLoading=false;
      state.AddSmartDevice_single_Product_detail=payload


    },
    addSmartDeviceSingleProductFail(state,{payload}){
      state.AddSmartDevivceLoading=false;
      state.AddSmartDevice_single_Product_detail=payload

    }

  },
});

const { actions, reducer } = getAddSmartDeviceSlice;

export const { fetchAddSmartDevicesStarted,
    fetchAddSmartDevicesSuccess,
    fetchAddSmartDevicesFail,
    addSmartDeviceSingleProductStarted,
    addSmartDeviceSingleProductSuccess,
    addSmartDeviceSingleProductFail,

 } = actions;

export default reducer;
