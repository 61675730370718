import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./modal.css";
import { GET_PRODUCT_DETAILS } from "../../../scenes/products/redux/productActions";

const ViewEditModal = ({ isViewModal, setIsViewModal }) => {
  const dispatch = useDispatch();
  const { numberOfBedrooms } = useSelector(
    ({ getProductsDetails }) => getProductsDetails
  );

  const [bedCounts, setBedCounts] = useState(1);

  useEffect(() => {
    setBedCounts(numberOfBedrooms);
  }, [numberOfBedrooms]);

  const modalCloseHandler = () => {
    const userEmail = sessionStorage.getItem("userEmail");
    sessionStorage.setItem("bedNumberCount", bedCounts);
    setIsViewModal(false);
 
    const payload = {
      brand_id: "",
      bedroom: bedCounts,
      email: userEmail,
    };

    dispatch({ type: GET_PRODUCT_DETAILS, payload });
  };

  const handleIncrement = () => {
    setBedCounts(parseInt(bedCounts) + 1);
    if (bedCounts >= 6) {
      setBedCounts(6);
    }
  };

  const handleDecrement = () => {
    setBedCounts(parseInt(bedCounts) - 1);
    if (bedCounts <= 1) {
      setBedCounts(1);
    }
  };
  return (
    <>
      {isViewModal && (
        <div className="modal is-active is-flex is-flex-direction-row is-justify-content-end">
          <div
            className="modal-background"
            onClick={() => setIsViewModal(false)}
          ></div>
          <div
            className="new-modal-card"
          >
            <div className=" is-flex is-flex-direction-column py-2">
              {/* Close Modal Btn */}
              <button
                className="delete button"
                aria-label="close"
                onClick={() => setIsViewModal(false)}
              ></button>


                 {/* Modal Title */}
                 <div className="modal-text-box ">
                <h1 className="modal-heading"> Your answers </h1>
                <p className="modal-sub-heading"> Edit your answers below to adjust your results: </p>
              </div>

              {/* Modal body */}
                <div
                  className=" is-flex is-flex-direction-row is-justify-content-left is-align-items-center p-1 px-4 box"
                  style={{
                    boxShadow: "none",
                    borderRadius: 0,
                    borderBottom: "1px solid #d9d9d9",
                  }}
                >
                  {/* <span className="icon">
                    <i class="fa-solid fa-bed"></i>
                  </span> */}
                  <div className="navbar-item startHover">
                    <span>
                      <img src="icons/bed.png" />
                    </span>
                  </div>
                  <div className="column has-text-left ml-2">
                    {bedCounts === 1
                      ? `${bedCounts} bed`
                      : bedCounts >= 6
                      ? `${bedCounts}+ beds`
                      : `${bedCounts} beds`}
                  </div>
                  <div className="is-size-4">
                    <i
                      className="fas fa-circle-minus mr-1 has-text-success"
                      onClick={handleDecrement}
                    ></i>

                    <i
                      className="fas fa-circle-plus has-text-success"
                      onClick={handleIncrement}
                    ></i>
                  </div>
                </div>
                {/* submit button */}
                <div className="is-flex is-flex-direction-row is-justify-content-end">
                  <button
                    type="submit"
                    class="submit-button"
                    onClick={modalCloseHandler}
                  >
                    Apply
                  </button>
                </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewEditModal;
