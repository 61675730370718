import React from "react";
import { useSelector } from "react-redux";
import { Image, Shimmer } from "react-shimmer";

const ProductInfoModal = ({ isVisible, onClose, Data }) => {
  const { isradiatorProductInfoLoading, productDataOfradiator } = useSelector(
    (state) => state.getARadiator
  );

  return (
    <>
      {isVisible && (
        <div className="modal is-active is-flex is-flex-direction-row is-justify-content-end">
          <div
            className="modal-background"
            onClick={() => onClose(false)}
          ></div>
          <div
            className="card column p-6 is-half is-fullheight"
            style={{ height: "100%", overflow: "auto" }}
          >
            <div className="columns is-flex is-flex-direction-column py-2">
              {/* Close Modal Btn */}
              <button
                className="delete button my-custom-color"
                style={{
                  marginLeft: "-46px",
                  marginTop: "50px",
                  scale: "2.2",
                  position: "fixed",
                }}
                aria-label="close"
                onClick={() => onClose(false)}
              ></button>

              {/* Modal Title */}
              {productDataOfradiator &&
                productDataOfradiator.length.map((item, index) => {
                  return (
                    <div className="column" key={index}>
                      <h1 className="is-size-3 has-text-weight-bold has-text-right mb-3 is-size-4-mobile">
                        {item.pro_title}
                      </h1>
                      <p className="is-size-6  has-text-right mb-4 is-size-7-mobile">
                        {item.pro_subtitle}
                      </p>
                    </div>
                  );
                })}

              {/* image */}
              {productDataOfradiator &&
                productDataOfradiator.option.map((item, index) => {
                  return (
                    <div className="column" key={index}>
                      <figure class="image box">
                        <Image
                          src={item.image}
                          fallback={<Shimmer width={500} height={300} />}
                        />
                      </figure>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductInfoModal;
