export const ROUTES = {
  getQuotes: "getQuotes",
  postcodeForm: "postcodeForm",
  infoForm: "infoForm",
  products: "products",
  orderSummary: "orderSummary",
  controls: "control",
  addSmartDevice: "addSmartDevice",
  addARadiator: "addARadiator",
  chooseYourInstallationDate: "chooseYourInstallationDate",
  productDetails: "productDetails",
  addValves: "addValves",
  paymentSuccessful: "paymentSuccessful",
};
