import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PaymentErrorModal from "../../molecule/Modal/PaymentErrorModal";
import PaymentSuccesModal from "../../molecule/Modal/PaymentSuccesModal";
import { clearclientkeyDataSuccess } from "./redux/PaymentReducer";
import { STORE_PAYMENT_DATA } from "./redux/paymentAction";
import PaymentSuccessful from "../../../scenes/paymeny-successful/PaymentSuccessful";
import { setRoute } from "../../../scenes/router/redux/reducer";
import { ROUTES } from "../../../utils/constants";
import { GET_BOOKING_ORDER } from "../../../scenes/order/redux/OrderSummaryActions";
import { useForm } from "react-hook-form";

export default function CheckoutForm({ setStripePaymentProcess }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState(null);
  const [succesMessageModal, setSuccessMessageModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const { secretData } = useSelector(
    ({ getClientSecretDetail }) => getClientSecretDetail
  );
  const { CartData } = useSelector(({ addProductToCart }) => addProductToCart);

  const clientSecret = secretData && secretData.client_secret;
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    const { paymentIntent, error } = await stripe.confirmPayment({
      type: "card",

      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: "http://localhost:3000/",
      },
      redirect: "if_required",
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setErrorModal(true);
      console.log("errror in checkout");
    } else if (paymentIntent) {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.

      // setSuccessMessageModal(true);
      const payload = {
        transaction_id: paymentIntent.id,
        amount: paymentIntent.amount,
        status: "1",
      };
      dispatch({ type: STORE_PAYMENT_DATA, payload: payload });
      const payloadForBookOrder = {
        order_total_amount:
          CartData && CartData.grand_total ? CartData.grand_total : "",
        product_id: CartData && CartData.product_id && CartData.product_id,
        controler_id: CartData && CartData.control_id && CartData.control_id,
        thermostat_id:
          CartData && CartData.thermostate_id && CartData.thermostate_id,
        radiator_id: CartData && CartData.radiator_id && CartData.radiator_id,
        volve_id: CartData && CartData.volvo_id ? CartData.volvo_id : 0,
      };

      dispatch({ type: GET_BOOKING_ORDER, payload: payloadForBookOrder });

      dispatch(setRoute(ROUTES.paymentSuccessful));
    }
  };
  return (
    <>
      {setStripePaymentProcess && (
        <>
          {" "}
          <div
            className="is-flex is-justify-content-center is-align-items-center"
            id="CheckoutSecurityMgs"
          >
            <div className="column is-2">
              {" "}
              <span>
                <i class="fas fa-lock" style={{ color: "grey" }}></i>
              </span>
            </div>
            <div >
              <p>
                {" "}
                Secure payment powered by Stripe
              </p>
            </div>
            <div className="column is-2"></div>
          </div>
          <div className="column">
            <p className="subtitle is-size-5 mt-3"> Enter Your card details below</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div id="payment-form">
              <PaymentElement />
            </div>

            <div className="columns is-gapless is-flex is-flex-direction-column ">
              <div className="column my-4">
                <input
                  type="checkbox"
                  name="checkbox"
                  className=" is-danger"
                  required
                />{" "}
                <label className="is-size-6 ml-2">
                  I agree to the{" "}
                  <a>
                    NuHome term and condition
                  </a>
                </label>
              </div>

              <div className="column ">
                {" "}
                <input
                  type="checkbox"
                  name="checkbox"
                  className=" is-danger"
                  required
                />{" "}
                <label className="is-size-6 ml-2">
                  I'm happy to receive useful reminders & ways to improve my
                  home.
                </label>
              </div>
            </div>


            <div className="main-btn-sec">

            <button
              type="submit"
              disabled={!stripe || !elements}
              className="home-survey"
              
            >
              Book Installation <i class="fa-solid fa-lock"></i>
            </button>

            </div>
            {/* Show error message to your customers */}
          </form>
        </>
      )}

      {errorMessage && (
        <PaymentErrorModal
          errorMessage={errorMessage}
          setErrorModal={setErrorModal}
          errorModal={errorModal}
          setStripePaymentProcess={setStripePaymentProcess}
        />
      )}

      {succesMessageModal && (
        <PaymentSuccesModal
          succesMessageModal={succesMessageModal}
          setSuccessMessageModal={setSuccessMessageModal}
          setStripePaymentProcess={setStripePaymentProcess}
        />
      )}
    </>
  );
}
