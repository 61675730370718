import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_CONTROL_DETAILS,
  GET_SINGLE_CHOOSE_CONTROL,
  GET_FILTER_DETAILS,
} from "./redux/controlAction";
import { selectedBoiler } from "../products/redux/bucketReducer";
import "./ChooseControl.css";
import { ROUTES } from "../../utils/constants";
import { router } from "../router/Router";
import { setRoute } from "../router/redux/reducer";
import Cart from "../../myComponent/organisms/cart/Cart";
import {
  ADD_PRODUCT_TO_BASKET,
  REMOVE_CART_ITEM,
} from "../../myComponent/organisms/cart/redux/CartAction";
import { Image, Shimmer } from "react-shimmer";
import { clearAddProductToCartSuccess } from "../../myComponent/organisms/cart/redux/CartReducer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MoreInfoModal from "../../myComponent/molecule/Modal/MoreInfomModal";
const ChooseControls = () => {
  const [value, setValue] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isMoreInfoModal, setIsMoreInfoModal] = useState(false);
  const dispatch = useDispatch();
  const {
    getControlDetailsData,
    loading,
    isFetchingSingleControls,
    getFilterDetailsData,
    SingleControlsData,
  } = useSelector(({ getControlDetails }) => getControlDetails);
  const { CartData } = useSelector(({ addProductToCart }) => addProductToCart);
  const { currencyPhoneEmail, isLoadingdynamicCurrrencyandPhone } = useSelector(
    ({ getBoilerQuote }) => getBoilerQuote
  );
  const ControlData = getControlDetailsData.data;
  // const FilterData = getFilterDetailsData.data;
  const {
    isAddProductToCartSubmitting,
    addProductToCartSuccess,
    addProductToCardError,
  } = useSelector(({ addProductToCart }) => addProductToCart);
  const selectProduct = useSelector((selectProduct) => selectProduct);
  const [isAdded, setIsAdded] = useState(false);

  const getBoilerBrand = selectProduct.bucketReducer.selectedProduct;
  const handleToSmartDevice = () => {
    dispatch(setRoute(ROUTES.addSmartDevice));
    router(ROUTES.addSmartDevice);
  };

  const handleNext = () => {
    dispatch(setRoute(ROUTES.addARadiator));
    router(ROUTES.addARadiator);
  };

  useEffect(() => {
    const getBoilerBrand =
      selectProduct.bucketReducer.selectedProduct.boiler_brand_name;

    dispatch({
      type: GET_CONTROL_DETAILS,
      // boilerName: getBoilerBrand,
    });
    dispatch({
      type: GET_FILTER_DETAILS,
      // boilerName: getBoilerBrand,
    });
  }, []);

  const addBoilerdata = (item, id) => {
    const payload = {
      product_id: CartData && CartData.product_id,
      controler_id: item.id,
      radiator_id: CartData && CartData.radiator_id ? CartData.radiator_id : 0,
      thermostat_id:
        CartData && CartData.thermostate_id ? CartData.thermostate_id : 0,
      volve_id: CartData && CartData.volve_id ? CartData.volve_id : 0,
      radiator_qty: CartData && CartData.radiator_qty && CartData.radiator_qty,
      volvo_qty: CartData && CartData.volvoe_qty && CartData.volvoe_qty,
    };

    if (selectedItem === id) {
      setSelectedItem(null); // Deselect the item if the same button is clicked again
    } else {
      setSelectedItem(id); // Set the selected item
    }

    setValue(item);
    dispatch({ type: ADD_PRODUCT_TO_BASKET, payload });
    dispatch(selectedBoiler(item));
  };

  const handleRemoveControl = (id) => {
    const payload = {
      controler_id: id,
    };

    dispatch({ type: REMOVE_CART_ITEM, payload });
  };

  const handleChangeBoiler = () => {
    dispatch(setRoute(ROUTES.products));
    router(ROUTES.products);
  };

  const handleMoreInfoForControls = (id) => {
    setIsMoreInfoModal(true);
    const payload = {
      controls_id: id,
    };

    dispatch({ type: GET_SINGLE_CHOOSE_CONTROL, payload });
  };
  if (loading) {
    return (
      <>
        <div className="loaderContainer">
          <div className="mainLoader"></div>
        </div>
      </>
    );
  }

  return (
    <div>
      <section className="section less-pad-mob shopping-bar-onlymob">
        <div className="container">
          <div className="columns is-mobile">
            {/* <div className="column">
              <span>Total</span>
              <h3 className="title">£4,000</h3>
            </div> */}
            <div className="column">
              <div className="mobile-cart-next">
                <div className="cart-item">
                  <a>
                    <i className="fa-solid"></i>
                  </a>
                </div>
                {/* <button
                  className="button is-success is-fullwidth"
                  onClick={() => handleToSmartDevice()}
                >
                  <p>
                    <strong>Next</strong>
                  </p>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer />

      <div className="is-max-desktop">
        <div className="is-flex is-flex-direction-row is-flex-wrap-wrap is-justify-content-center mt-5">
          <div className="column ">
            <div className="new-title-sec title-sec">
              <h2>
                We've matched you with{" "}
                <span className="number-of-boiler">
                  {CartData.boiler_title}
                </span>
              </h2>
              <p>
                {" "}
                We'll install your controls during your boiler installation
                &amp; show you how to use them{" "}
              </p>
            </div>
            <div className="columns  is-two-third is-flex-direction-row is-flex-wrap-wrap ">
              {ControlData &&
                ControlData.map((item, id) => (
                  <div className="column is-half " key={id}>
                    <div
                      className={`control-box ${
                        selectedItem === id ? "product-card-selected" : ""
                      }`}
                    >
                      <div className="card-content">
                        <div className="header-sec">
                          <p
                            className="control-more-info-btn"
                            onClick={() => handleMoreInfoForControls(item.id)}
                          >
                            {" "}
                            <i class="fa-solid fa-circle-info"></i> More Info
                          </p>

                          <p className="control-warranty">
                            {item.warranty_year}y Warranty
                          </p>
                        </div>

                        <figure className="imagebox">
                          <img src={item.image_path} alt="Placeholder image" />
                        </figure>

                        <div className="control-desk">
                          <p className="control-price">
                            {" "}
                            {item.price === null ? (
                              "FREE"
                            ) : (
                              <div>
                                {" "}
                                +
                                {currencyPhoneEmail
                                  ? currencyPhoneEmail.company_currency
                                  : " £"}
                                {Number(item.thrmostat_price)}{" "}
                              </div>
                            )}
                          </p>

                          {/* <p className="subtitle is-5">{item.title}</p> */}
                          {CartData &&
                          CartData.control_id &&
                          CartData.control_id == item.id ? (
                            <button
                              className={`button ${"is-danger"}`}
                              onClick={() => handleRemoveControl(item.id)}
                            >
                              Remove
                            </button>
                          ) : (
                            <button
                              onClick={() => addBoilerdata(item, id)}
                              className={`control-add ${
                                isAddProductToCartSubmitting &&
                                selectedItem === id
                                  ? " is-loading"
                                  : "is-success"
                              }`}
                            >
                              <span>
                                ADD <i class="fa-solid fa-plus"></i>
                              </span>
                            </button>
                          )}
                        </div>

                        <footer className="control-name">
                          <p>{item.title.substring(0, 18)}</p>
                        </footer>

                        {/* <div className="product-card-additional-info"></div> */}
                      </div>

                      {isMoreInfoModal && (
                        <MoreInfoModal
                          item={SingleControlsData}
                          isMoreInfoModal={isMoreInfoModal}
                          setIsMoreInfoModal={setIsMoreInfoModal}
                          isFetchingSingleControls={isFetchingSingleControls}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="column is-one-third">
            <Cart handleNext={handleNext} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseControls;
