import React from "react";
import { setRoute } from "../../../scenes/router/redux/reducer";
import { ROUTES } from "../../../utils/constants";
import { useDispatch } from "react-redux";

const AddStartAgainModal = ({
  showStartAgainModal,
  setShowStartAgainModal,
}) => {
  const dispatch = useDispatch();

  const handlleShowQuote = () => {
    dispatch(setRoute(ROUTES.getQuotes));
    setShowStartAgainModal(false);
  };

  const handleClose = () => {
    setShowStartAgainModal(false);
  };
  return (
    <>
      {showStartAgainModal && (
        <div className="quotesContainer has-background-dark">
          <div className="showStartAgainContainer">
            <div className="columns is-flex is-flex-direction-column is-justify-content-center ">
              <div className="column">
                <p className="title is-4 has-text-weight-normal">
                  {" "}
                  Start again
                </p>
              </div>
              <div className="column">
                <p className="subtitle is-7">
                  {" "}
                  Continue will reset your answers and take you back to the
                  first question
                </p>
              </div>
              <div className="column p-4">
                <button
                  class="button is-success is-fullwidth"
                  onClick={handlleShowQuote}
                >
                  {" "}
                  <span className="icon">
                    {" "}
                    <i class="fas fa-rotate-right"></i>
                  </span>{" "}
                  <div className="column">Reset answers</div>{" "}
                </button>
              </div>
              <div className="column">
                <button
                  class="button is-success is-fullwidth"
                  onClick={handleClose}
                >
                  {" "}
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddStartAgainModal;
