import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAddProductToCartSubmitting: false,
  addProductToCartSuccess: false,
  addProductToCardError: "",

  CartData: {},
  isLoading: false,
  error: null,
  isRemovingDataFromCart: false,
};

const addProductToCartSlice = createSlice({
  name: "addProductToCart",
  initialState: initialState,
  reducers: {
    postAddProductToCartStarted(state) {
      state.isAddProductToCartSubmitting = true;
      state.addProductToCartSuccess = false;
    },
    postAddProductTocartSuccess(state) {
      state.isAddProductToCartSubmitting = false;
      state.addProductToCartSuccess = true;
    },
    postAddProductToCartFail(state, { payload }) {
      state.isAddProductToCartSubmitting = false;
      state.addProductToCartSuccess = false;
      state.addProductToCardError = payload.error;
    },
    clearAddProductToCartSuccess(state) {
      state.isAddProductToCartSubmitting = false;
      state.addProductToCartSuccess = false;
    },

    getProductToCartStarted(state) {
      state.isLoading = false;
    },
    getProductToCartSuccess(state, { payload }) {
      console.log("This is data from backend side ",payload )
      state.isLoading = false;
      state.CartData = payload.cartDetails;
    },
    getProductTocartFails(state, { payload }) {
      state.isLoading = false;
      state.CartData = payload;
    },
    removeProductFromCartStarted(state) {
      state.isRemovingDataFromCart = true;
    },
    removeProductFromCartSuccess(state, { payload }) {
      state.isRemovingDataFromCart = true;
    },
    removeproductFromCartFail(state, { payload }) {
      state.isRemovingDataFromCart = false;
    },
  },
});

const { actions, reducer } = addProductToCartSlice;

export const {
  postAddProductToCartFail,
  postAddProductToCartStarted,
  postAddProductTocartSuccess,
  clearAddProductToCartSuccess,
  getProductToCartStarted,
  getProductTocartFails,
  getProductToCartSuccess,
  removeProductFromCartStarted,
  removeproductFromCartFail,
  removeProductFromCartSuccess,
} = actions;
export default reducer;
