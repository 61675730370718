const includedtext = [
  {
    point:
      "Removal, safe disposal, and recycling of your existing regular boiler, and installation of a new regular boiler, keeping it in the exact location",
  },
  ,
  {
    point:
      "Installation of the required flue including any extensions, elbows, or other accessories needed",
  },
  { point: "All required alterations to pipework and fixings" },
  { point: "A chemical flush of your home’s existing pipework and radiators" },
  { point: "Parts and labor warranty, direct with the manufacturer" },
  {
    point: "Chemical inhibitor treatment to protect your central heating system and keep it working efficiently, for longer",
  },
  { point: "12-month workmanship warranty, direct with Heatable" },
  {
    point: "Flue kit, flue extensions, elbows, and any other required accessories",
  },
  { point: "Magnetic boiler filter" },
  { point: "Re-balance of your entire heating system" },
  { point: "New installation, or upgrade of a condensate pipe (if needed)" },
  { point: "Mains gas pipe upgrade from the meter (if needed)" },
  { point: "Required electrical works" },
  { point: "CP1 certificate" },
  { point: "Post-install building control and warranty registration" },
  { point: "Any required external brickwork" },
];

export default includedtext;
