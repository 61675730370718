import React from "react";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import "./modal.css";

const TermsAndConditionModal = ({ termAndCondition, setTermAndCondition }) => {
  const { getTermConditionData, isgettingTermAndCOndition } = useSelector(
    (state) => state.getTermAndCondition
  );
  if (isgettingTermAndCOndition) {
    return <div className="mainLoader"></div>;
  }
  return (
    <>
      {termAndCondition && (
        <div className="modal is-active ">
          <div class="modal-background"></div>
          <div className="modal-content quotesContainer p-5">
            <div className="saveQuoteModal">
              <h1 className="title is-4 termsandconditiontitle mt-4">
                {" "}
                Terms and Conditions
              </h1>
              {getTermConditionData &&
                getTermConditionData.map((item, index) => (
                  <div key={index}>
                    {/* <h2 className="subtitle is-6 has-text-centered mb-4">
                      {item.title}
                    </h2> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.terms_page),
                      }}
                    />
                  </div>
                ))}
              <div
                className="is-flex is-justify-content-center mt-5
"
              >
                <button
                  className="termsandconditionbtn"
                  onClick={() => setTermAndCondition(false)}
                >
                  close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TermsAndConditionModal;
