import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRoute } from "../router/redux/reducer";
import { ROUTES } from "../../utils/constants";
import "./paymentSuccessful.css";
import { Image, Shimmer } from "react-shimmer";
import { SHOW_PAYMENT_DATA } from "./redux/PaymentSuccessAction";
import "./success.css";
import images from "./checked.png";

const PaymentSuccessful = () => {
  const dispatch = useDispatch();

  const { PaymentDetailsData } = useSelector(
    ({ getPaymentDetailsData }) => getPaymentDetailsData
  );

  const { currencyPhoneEmail } = useSelector(
    ({ getBoilerQuote }) => getBoilerQuote
  );

  const handleToProductScreen = () => {
    dispatch(setRoute(ROUTES.getQuotes));
    localStorage.clear();
  };
  return (
    <>
      <div className="content">
        <div className="wrapper-1">
          {/* <div className="wrapper-2">
            <Image
              className="thanku_topper"
              src="./image/thank-you.jpg"
              fallback={<Shimmer width={200} height={200} />}
            />
            <h1 className="thank_heading has-text-success">Thank you !</h1>
            <p className="subtitle is-size-4">Continue Shopping with us....</p>
            <a href="#" className="go-home" onClick={handleToProductScreen}>
              go home
            </a>
          </div> */}
          <div class="success-main-box">
            <div class="success-box">
              <img style={{ width: 150, height: 150 }} src={images} />
              <h2>THANK YOU</h2>
              <p>
                Your request has been acknowledged and one of our team members
                will be in contact with you shortly.
              </p>
              <p>
                You can also contact us at{" "}
                <a href={`tel:${currencyPhoneEmail.company_phone}`}>
                  {currencyPhoneEmail.company_phone}
                </a>{" "}
                if you would like to speak to us right away
              </p>

              <button onClick={handleToProductScreen}>RETURN HOME</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccessful;
