import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isfetchingSingleProductDetails: false,
  getSingleProductDetailsData: [],
  isLoading: false,
};

const getSingleProductDetailslice = createSlice({
  name: "getSingleProductDetails",
  initialState,
  reducers: {
    fetchSingleProductDetailStarted(state) {
      state.isfetchingSingleProductDetails = true;
      state.isLoading = true;
    },
    fetchSingleProductDetailSuccess(state, { payload }) {
      state.isfetchingSingleProductDetails = false;

      state.getSingleProductDetailsData = payload;
     
      state.isLoading = false;
    },
    fetchSingleProductDetailFail(state) {
      state.isfetchingSingleProductDetails = false;
      state.isLoading = false;
    },
  },
});
const { actions, reducer } = getSingleProductDetailslice;
export const {
  fetchSingleProductDetailFail,
  fetchSingleProductDetailSuccess,
  fetchSingleProductDetailStarted,
} = actions;
export default reducer;
