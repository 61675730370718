import { call, put } from "redux-saga/effects";
import apiClient from "../../../services/httpServices";
import { API_PRODUCT_URL, API_URL } from "../../../services/webConstant";
import {
  fetchSingleProductDetailFail,
  fetchSingleProductDetailSuccess,
  fetchSingleProductDetailStarted,
} from "./ProducDetailsReducer";

export function* getSingleProductDetailsSaga(action) {
  const payload = action.payload;

  try {
    yield put(fetchSingleProductDetailStarted());
    const productDetailsResponse = yield call(
      apiClient.get,
      `${API_PRODUCT_URL.GET_SINGLE_PRODUCT_DETAILS}?product_id=${payload.product_id}`
    );

    yield put(
      fetchSingleProductDetailSuccess(productDetailsResponse.data.data)
    );
  } catch (error) {
    yield put(fetchSingleProductDetailFail());
  }
}
