// localStorage.js
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// localStorage.js
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch {
    // ignore write errors
  }
};

export const calculateMonthlyPayment = (
  loanAmount,
  annualInterestRate,
  numberOfPayments
) => {
  if (annualInterestRate === 0) {
    return loanAmount / numberOfPayments;
  }
  const monthlyInterestRate = annualInterestRate / 12 / 100;
  const denominator = Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1;
  const monthlyPayment =
    (loanAmount *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
    denominator;
  return monthlyPayment;
};
