import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  postOrderSummary: [],
  postBookOrderSummaryData: [],
  getOrderSummaryData: [],
  loadingGetorderSummary: false,
  isLoading: false,
  isPostOrderSummarLoading: false,
  fetchingInsatllerdataLoading: false,
  error: null,
  getInstallerData: [],
  isLoadingPostBookOrderSummary: false,
};

const postOrderSummarySlice = createSlice({
  name: "postOrderSummary",
  initialState,
  reducers: {
    postorderSummaryStarted(state) {
      state.isPostOrderSummarLoading = true;
      state.error = null;
    },
    postOrderSummarySucces(state, { payload }) {
      state.isPostOrderSummarLoading = false;
      state.postOrderSummary = payload;
    },
    postOrderSummaryFail(state, { payload }) {
      state.isPostOrderSummarLoading = false;
      state.error = payload;
    },
    postBookOrderSummaryStarted(state) {
      state.isLoadingPostBookOrderSummary = true;
    },
    postBookOrderSummarySuccess(state, { payload }) {
      state.isLoadingPostBookOrderSummary = false;
      state.postBookOrderSummaryData = payload;
    },
    postBookOrderSummaryfails(state, { payload }) {
      state.isLoadingPostBookOrderSummary = false;
      state.postBookOrderSummaryData = payload;
    },

    getorderSummaryStarted(state) {
      state.loadingGetorderSummary = true;
    },
    getorderSummarySuccess(state, { payload }) {
      state.loadingGetorderSummary = false;

      state.getOrderSummaryData = payload;
    },
    getOrderSummaryFails(state, { payload }) {
      state.loadingGetorderSummary = false;
      state.getOrderSummaryData = payload;
    },
    fetchInstallerDataStarted(state) {
      state.fetchingInsatllerdataLoading = true;
    },
    fetchInstallerDataSuccess(state, { payload }) {
      state.fetchingInsatllerdataLoading = false;
      state.getInstallerData = payload.InstallerData;
    },
    fetchInstallerDataFails(state, { payload }) {
      state.fetchingInsatllerdataLoading = false;
      state.getInstallerData = payload;
    },
  },
});

const { actions, reducer } = postOrderSummarySlice;
export const {
  postorderSummaryStarted,
  postOrderSummarySucces,
  postOrderSummaryFail,
  getOrderSummaryFails,
  getorderSummarySuccess,
  getorderSummaryStarted,
  fetchInstallerDataFails,
  fetchInstallerDataSuccess,
  fetchInstallerDataStarted,
  postBookOrderSummaryStarted,
  postBookOrderSummaryfails,
  postBookOrderSummarySuccess,
} = actions;

export default reducer;
