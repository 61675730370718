import { call, delay, put } from "redux-saga/effects";
import apiClient from "../../../../services/httpServices";

import {
  getTermandConditionDataStarted,
  getTermandConditionDataSuccess,
  getTermandConditionDataFail,
  getPrivacyPolicyDataStarted,
  getPrivacyPolicyDataFails,
  getPrivacyPolicyDataSuccess,
  getPhoneNumberValidateDataStarted,
  getPhoneNumberValidateDataSuccess,
  getPhoneNumberValidateDataFails,
  postQuoteEmailStarted,
  postQuoteEmailSuccess,
  postQuoteEmailFail,
} from "./InfoFormReducer";
import {
  SEND_BY_MAIL,
  TERM_CONDITION,
  VALIDATION,
} from "../../../../services/webConstant";
import axios from "axios";

export function* getTermConditionDataSaga(action) {
  const { payload } = action;
  try {
    yield put(getTermandConditionDataStarted());
    const termConditionData = yield call(
      apiClient.get,
      TERM_CONDITION.GET_TERM_CONDITIONS
    );

    yield put(getTermandConditionDataSuccess(termConditionData.data.data));
  } catch (err) {
    yield put(getTermandConditionDataFail());
  }
}

export function* getPrivacyPolicySaga(action) {
  const { payload } = action;
  try {
    yield put(getPrivacyPolicyDataStarted());
    const privacyPolicyData = yield call(
      apiClient.get,
      TERM_CONDITION.GET_PRIVACY_POLICY
    );

    yield put(getPrivacyPolicyDataSuccess(privacyPolicyData.data.data));
  } catch (err) {
    yield put(getPrivacyPolicyDataFails());
  }
}

export function* getPhoneNumberValidateData(action) {
  const { payload } = action;

  try {
    yield delay(1000);
    yield put(getPhoneNumberValidateDataStarted());

    const validationResponse = yield call(
      axios.get,
      `https://api.veriphone.io/v2/verify`,
      payload
    );

    yield put(getPhoneNumberValidateDataSuccess(validationResponse.data));
  } catch (err) {
    yield put(getPhoneNumberValidateDataFails());
  }
}

export function* postQuoteEmailSaga(action) {
  const { payload } = action;
  try {
    const formattedPayload = { email: payload.email };
    yield put(postQuoteEmailStarted());
    const postQuoteEmailResponse = yield call(
      apiClient.post,
      SEND_BY_MAIL.POST_QUOTE_EMAIL,
      formattedPayload
    );
    yield put(postQuoteEmailSuccess(postQuoteEmailResponse.data));
  } catch (err) {
    yield put(postQuoteEmailFail());
  }
}
