import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import "react-phone-number-input/style.css";
import "./modal.css";
import { useDispatch, useSelector } from "react-redux";
import { HELP_CHOOSE_BOILER } from "../../../scenes/products/redux/productActions";
import { clearHelpChooseBoilterSubmission } from "../../../scenes/products/redux/productReducer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RequestCallModal = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();
  const {
    isHelpChooseBoilerSubmitting,
    helpChooseBoilerSuccess,
    isgetUserBookingDetailsDataLoading,
    isgetUserBookingDetailsData,
  } = useSelector(({ getProductsDetails }) => getProductsDetails);
  const PreviousFormData = localStorage.getItem("StoreFormData");
  let previousFirstName;
  let PreviouslastName;
  let PreviousEmailAddress;
  let PreviousPhoneNumber;
  if (PreviousFormData !== null) {
    const formdataval = JSON.parse(PreviousFormData);
    previousFirstName = formdataval.First_Name;
    PreviouslastName = formdataval.Last_Name;
    PreviousEmailAddress = formdataval.Email_Id;
    PreviousPhoneNumber = formdataval.phoneNumber;
  }
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: previousFirstName ? previousFirstName : "",

      lastname: PreviouslastName ? PreviouslastName : "",

      phone: PreviousPhoneNumber ? PreviousPhoneNumber : "",
      email: PreviousEmailAddress ? PreviousEmailAddress : "",
      Details: "",
    },
  });

  const onSubmit = (data) => {
    const payload = {
      firstname: data.firstname,

      lastname: data.lastname,
      email: data.email,
      phone: data.phone,
      message: data.Details,
    };
    dispatch({ type: HELP_CHOOSE_BOILER, payload });
    reset();
  };

  useEffect(() => {
    onClose(false);
    dispatch(clearHelpChooseBoilterSubmission({}));
  }, [helpChooseBoilerSuccess]);

  return (
    <>
      <ToastContainer />
      {isVisible && (
        <div
          className="modal is-active is-flex is-flex-direction-row is-justify-content-end"
          style={{ overflowY: "scroll" }}
        >
          <div
            className="modal-background"
            onClick={() => onClose(false)}
          ></div>
          <div className="new-modal-card">
            <div className="is-flex is-flex-direction-column py-2">
              {/* Close Modal Btn */}
              <button
                className="delete button"
                aria-label="close"
                onClick={() => onClose(false)}
              ></button>

              {/* Modal Title */}
              <div className="modal-text-box column">
                <h1 className="modal-heading"> Request a Call </h1>
                <p className="modal-sub-heading">
                  {" "}
                  Can't call now? Fill in the form below we will have a
                  professional call you within 2 working hours.{" "}
                </p>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                {/* First name */}
                <div className="column">
                  <Controller
                    name="firstname"
                    control={control}
                    // rules={{ required: "first Name is required" }}
                    render={({ field }) => (
                      <input
                        className="input is-hovered formInput"
                        placeholder="First Name"
                        {...field}
                        defaultValue={
                          isgetUserBookingDetailsData &&
                          isgetUserBookingDetailsData.firstname
                        }
                      />
                    )}
                  />
                  {errors.firstname && (
                    <span className="has-text-danger">
                      {errors.firstname.message}*
                    </span>
                  )}
                </div>

                <div className="column">
                  {/* Last name */}
                  <Controller
                    name="lastname"
                    control={control}
                    // rules={{ required: "Last Name is required" }}
                    render={({ field }) => (
                      <input
                        className="input formInput is-hovered"
                        placeholder="Last Name"
                        {...field}
                        defaultValue={
                          isgetUserBookingDetailsData &&
                          isgetUserBookingDetailsData.lastname
                        }
                      />
                    )}
                  />
                  {errors.lastname && (
                    <span className="has-text-danger">
                      {errors.lastname.message}*
                    </span>
                  )}
                </div>

                <div className="column">
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      // required: "Phone Number is required",
                      pattern: {
                        value: /^[1-9]\d{9}$/gi,
                        message: "Invalid Phone Number",
                      },
                    }}
                    render={({ field }) => (
                      <input
                        type="tel"
                        className="input formInput is-hovered"
                        placeholder="Phone*"
                        {...field}
                        defaultValue={
                          isgetUserBookingDetailsData &&
                          isgetUserBookingDetailsData.phone
                        }
                      />
                    )}
                  />
                  {errors.phone && (
                    <span className="has-text-danger">
                      {errors.phone.message}*
                    </span>
                  )}
                </div>

                <div className="column">
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      // required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    }}
                    render={({ field }) => (
                      <input
                        type="tel"
                        className="input formInput is-hovered"
                        placeholder="Email*"
                        {...field}
                        defaultValue={
                          isgetUserBookingDetailsData &&
                          isgetUserBookingDetailsData.email
                        }
                      />
                    )}
                  />
                  {errors.email && (
                    <span className="has-text-danger">
                      {errors.email.message}*
                    </span>
                  )}
                </div>

                <div className="column">
                  {/* Details */}
                  <Controller
                    name="Details"
                    control={control}
                    rules={{ required: "Details is required" }}
                    render={({ field }) => (
                      <textarea
                        className="input formInput is-hovered textarea"
                        rows="2"
                        placeholder="What are you looking for?"
                        {...field}
                      />
                    )}
                  />
                  {errors.Details && (
                    <span className="has-text-danger">
                      {errors.Details.message}*
                    </span>
                  )}
                </div>

                <div className="column">
                  <p>
                    Your information is 100% secure we promise we won't inundate
                    you
                  </p>
                </div>

                {/* submit button */}
                <div className="column">
                  <button type="submit" class="submit-button">
                    {isHelpChooseBoilerSubmitting ? (
                      <span class="loader"></span>
                    ) : helpChooseBoilerSuccess ? (
                      toast.success("Call requested successfully", {
                        position: "top-right",
                        toastId: "1",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      })
                    ) : (
                      <div>Request a call</div>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestCallModal;
