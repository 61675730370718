import React from "react";
import "./modal.css";


const HelpMeChooseModal = ({ isVisible, onClose }) => {
  return (
    <>
      {isVisible && (
        <div className="modal is-active is-flex is-flex-direction-row is-justify-content-end">
          <div
            className="modal-background"
            onClick={() => onClose(false)}
          ></div>
          <div
            className="new-modal-card"
            style={{ height: "100%", overflow: "auto" }}
          >
            <div className="is-flex is-flex-direction-column py-2">
              {/* Close Modal Btn */}
              <button
                className="delete button"
                aria-label="close"
                onClick={() => onClose(false)}
              ></button>


                {/* Modal Title */}
                <div className="modal-text-box column">
                <h1 className="modal-heading"> Tips for choosing your new boiler </h1>
                <p className="modal-sub-heading"> All the boilers in your results are suitable for your home.
                  Here we’ll try to bust some jargon and help you choose. </p>
              </div>

              <div className="column ">
                <h1 className="is-size-5 has-text-weight-bold has-text-left">
                  Hot water flow rate
                </h1>
                <h1 className="subtitle is-6 has-text-weight-normal has-text-left mt-2 is-size-7-mobile">
                  This is the speed your boiler can heat and supply hot water to
                  your taps and showers. Got a busy home? Go for a higher flow
                  rate.
                </h1>
              </div>

              <div className="column ">
                <h1 className="is-size-5 has-text-weight-bold has-text-left">
                  Central heating output
                </h1>
                <h1 className="subtitle is-6 has-text-weight-normal has-text-left mt-2 is-size-7-mobile">
                  The higher this is, the more radiators your boiler can heat.
                  Each of our boilers shows the number of radiators it can
                  power.
                </h1>
              </div>

              <div className="column ">
                <h1 className="is-size-5 has-text-weight-bold has-text-left">
                  Compact boilers
                </h1>
                <h1 className="subtitle is-6 has-text-weight-normal has-text-left mt-2 is-size-7-mobile">
                  These are specifically designed to be smaller so are more
                  likely to fit in smaller spaces, such as cupboards.
                </h1>
              </div>

              <div className="column ">
                <h1 className="is-size-5 has-text-weight-bold has-text-left">
                  Warranty
                </h1>
                <h1 className="subtitle is-6 has-text-weight-normal has-text-left mt-2 is-size-7-mobile">
                  Consider how long you intend to stay in your current home.
                  Boilers with a shorter warranty tend to be less expensive.
                </h1>
              </div>

              {/* IFrames */}
              <div className="is-flex is-flex-direction-column mt-4">
                <div className="column is-flex is-flex-direction-row">
                  <div className="columns is-desktop is-align-items-center ">
                    <div className="column">
                      <iframe
                        width="100%"
                        height="auto"
                        src="https://youtube.com/embed/xxxxxx"
                        allowfullscreen
                        style={{ borderRadius: "10px" }}
                      ></iframe>
                    </div>
                    <div className="column">
                      <h1 className="is-size-4 has-text-left is-size-5-mobile">
                        How do I choose the right boiler?
                      </h1>
                    </div>
                  </div>
                </div>

                <div className="column is-flex is-flex-direction-row ">
                  <div className="columns is-desktop is-align-items-center">
                    <div className="column">
                      <iframe
                        width="100%"
                        height="auto"
                        src="https://youtube.com/embed/xxxxxx"
                        allowfullscreen
                        style={{ borderRadius: "10px" }}
                      ></iframe>
                    </div>
                    <div className="column">
                      <h1 className="is-size-4 has-text-left is-size-5-mobile">
                        What happens next?
                      </h1>
                    </div>
                  </div>
                </div>

                <div className="column is-flex is-flex-direction-row">
                  <div className="columns is-desktop is-align-items-center">
                    <div className="column">
                      <iframe
                        width="100%"
                        height="auto"
                        src="https://youtube.com/embed/xxxxxx"
                        allowfullscreen
                        style={{ borderRadius: "10px" }}
                      ></iframe>
                    </div>
                    <div className="column">
                      <h1 className="is-size-4 has-text-left is-size-5-mobile ">
                        Your Installation day
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HelpMeChooseModal;
