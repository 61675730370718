import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  route: "getQuotes",
  userStep: 1,
};

const routeSlice = createSlice({
  name: "router",
  initialState,
  reducers: {
    setRoute(state, { payload }) {
      if (payload === "products") {
        state.userStep = 1;
      } else if (payload === "control") {
        state.userStep = 2;
      } else if (payload === "addARadiator" || payload === "addValves") {
        state.userStep = 3;
      } else {
        state.userStep = 4;
      }
      state.route = payload;
      // console.log("✅✅✅⭐ setroute", payload, state.userStep);
    },
  },
});

const { actions, reducer } = routeSlice;

export const { setRoute } = actions;
export default reducer;
