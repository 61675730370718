import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddStartAgainModal from "../../myComponent/molecule/Modal/AddStartAgainModal";
import { AddresComponents } from "../../myComponent/molecule/form/AddresComponents";
import InfoForm from "../../myComponent/organisms/form/InfoForm";
import { clientDetailsSelector } from "../../stores/selectors";
import { ROUTES } from "../../utils/constants";
import AddValves from "../AddValves/AddValves";
import AddSmartDevice from "../addSmartDevice/AddSmartDevice";
import GetAquote from "../get-a-quote/GetAquote";
import PaymentSuccessful from "../paymeny-successful/PaymentSuccessful";
import ProdcutDetails from "../productDetails/ProdcutDetails";
import AddARadiator from "../radiator/AddARadiator";
import ChooseControls from "./../choose-controls/ChooseControls";
import OrderSummary from "./../order/OrderSummary";
import Products from "./../products/Products";
import { setRoute } from "./redux/reducer";
import { set } from "date-fns";

const {
  products,
  orderSummary,
  controls,
  addSmartDevice,
  getQuotes,
  infoForm,
  addARadiator,
  addressComponents,
  productDetails,
  paymentSuccessful,
  addValves,
} = ROUTES;

const newurl = window.location.search;

export const router = (values) => {
  if (values === products) {
    document.getElementById("nav01").classList.add("is-active");
    document.getElementById("nav02").classList.remove("is-active");
    document.getElementById("nav03").classList.remove("is-active");
    document.getElementById("nav04").classList.remove("is-active");
  } else if (values === controls) {
    document.getElementById("nav02").classList.add("is-active");
    document.getElementById("nav03").classList.remove("is-active");
    document.getElementById("nav01").classList.remove("is-active");
    document.getElementById("nav04").classList.remove("is-active");
  } else if (values === addARadiator || values === addValves) {
    document.getElementById("nav04").classList.add("is-active");
    document.getElementById("nav03").classList.remove("is-active");
    document.getElementById("nav01").classList.remove("is-active");
    document.getElementById("nav02").classList.remove("is-active");
  } else {
    document.getElementById("nav03").classList.add("is-active");
    document.getElementById("nav02").classList.remove("is-active");
    document.getElementById("nav01").classList.remove("is-active");
    document.getElementById("nav04").classList.remove("is-active");
  }
};

const Router = (url) => {
  const dispatch = useDispatch();
  const [showStartAgainModal, setShowStartAgainModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  const cartModalRef = useRef();
  const { route, userStep } = useSelector(({ router }) => router);
  const { clientData } = useSelector(clientDetailsSelector);
  const { AllDataForResumeSession } = useSelector(
    ({ getAllSavedDataForResume }) => getAllSavedDataForResume
  );
  const { CartData } = useSelector(({ addProductToCart }) => addProductToCart);
  const { currencyPhoneEmail, isLoadingdynamicCurrrencyandPhone } = useSelector(
    ({ getBoilerQuote }) => getBoilerQuote
  );

  useEffect(() => {
    if (url) {
      // dispatch(setRoute(ROUTES.getQuotes));
    }
    document.documentElement.style.setProperty(
      "--primary",
      clientData.company_color
    );
  }, []);

  useEffect(() => {
    calculateTotalProducts();
  }, [CartData]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showCartModal &&
        cartModalRef.current &&
        !cartModalRef.current.contains(e.target)
      ) {
        setShowCartModal(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showCartModal]);

  // useEffect(() => {
  //   if (
  //     AllDataForResumeSession &&
  //     AllDataForResumeSession.cart_selected_product &&
  //     AllDataForResumeSession.cart_selected_product.id !== ""
  //   ) {
  //     dispatch(setRoute(ROUTES.products));
  //     const call = () => {
  //       return router(ROUTES.products);
  //     };
  //     setTimeout(call, 500);
  //   }
  //   // if (
  //   //   AllDataForResumeSession &&
  //   //   AllDataForResumeSession.cart_selected_controles &&
  //   //   AllDataForResumeSession.cart_selected_controles !== ""
  //   // ) {
  //   //   dispatch(setRoute(ROUTES.controls));
  //   //   const call = () => {
  //   //     return router(ROUTES.controls);
  //   //
  //   //   setTimeout(call, 500);
  //   // }
  //   // if (
  //   //   AllDataForResumeSession &&
  //   //   AllDataForResumeSession.cart_selected_thermostate &&
  //   //   AllDataForResumeSession.cart_selected_thermostate !== ""
  //   // ) {
  //   //   dispatch(setRoute(ROUTES.addSmartDevice));
  //   //   const call = () => {
  //   //     return router(ROUTES.addSmartDevice);
  //   //   };

  //   //   setTimeout(call, 500);
  //   // }
  // }, [AllDataForResumeSession]);

  const handleBackPress = () => {
    if (route === ROUTES.controls) {
      dispatch(setRoute(ROUTES.products));
      router(ROUTES.products);
    } else if (route === ROUTES.addSmartDevice) {
      dispatch(setRoute(ROUTES.controls));
      router(ROUTES.controls);
    } else if (route === ROUTES.orderSummary) {
      dispatch(setRoute(ROUTES.addValves));
      router(ROUTES.addSmartDevice);
    } else if (route === ROUTES.products) {
      setShowStartAgainModal(true);
    } else if (route === ROUTES.productDetails) {
      dispatch(setRoute(ROUTES.products));
    } else if (route === ROUTES.addValves) {
      dispatch(setRoute(ROUTES.addARadiator));
    } else if (route === ROUTES.addARadiator) {
      dispatch(setRoute(ROUTES.controls));
    }
  };

  const handlemenuBar = () => {
    setShowMenu(true);
  };

  const convertToNumberOrZero = (value) => {
    const numberValue = parseFloat(value);
    return isNaN(numberValue) ? 0 : numberValue;
  };

  const calculateTotalProducts = () => {
    let total = 0;
    total += CartData.control_id ? 1 : 0;
    total += CartData.boiler_brand_id ? 1 : 0;
    total += CartData.thermostate_id ? 1 : 0;
    total += CartData.radiator_id
      ? convertToNumberOrZero(CartData.radiator_qty)
      : 0;
    setTotalProducts(total);
    total += CartData.volve_id ? convertToNumberOrZero(CartData.volvoe_qty) : 0;
    setTotalProducts(total);
  };

  const handleNavPress = (value, step) => {
    // console.log("✅✅✅⭐ handlenavpress", value, step);
    if (step <= userStep) {
      dispatch(setRoute(value));
      router(value);
    }
  };

  const renderNavigation = () => (
    <section>
      <nav className="navbar navbar_fix" role="navigation">
        <div className="navbar-menu is-active">
          <div className="navbar-center">
            <div className="navbar-item">
              <div
                className="buttons"
                style={route === products ? { visibility: "hidden" } : {}}
              >
                <a
                  className="button is-primary"
                  onClick={() => handleBackPress()}
                >
                  <strong>Back</strong>
                </a>
              </div>
            </div>
            <div
              role="button"
              className="navbar-burger"
              aria-label="menu"
              // aria-expanded="false"
              data-target="topbarmenu"
              onClick={() => setShowMenu((val) => !val)}
              style={{
                height: "auto",
                width: "auto",
                position: "unset",
                backgroundColor: "purple",
              }}
            >
              {showMenu ? (
                <div className="navbar nevbar-end">
                  <button
                    id="nav01"
                    className="button is-white"
                    onClick={() => handleNavPress(products, 1)}
                  >
                    Choose boiler
                  </button>
                  <button
                    id="nav02"
                    className="button is-white"
                    onClick={() => handleNavPress(controls, 2)}
                  >
                    Choose controls
                  </button>
                  <button
                    className="button is-white"
                    id="nav04"
                    onClick={() => handleNavPress(addSmartDevice, 3)}
                  >
                    Add smart devices
                  </button>
                  <button
                    id="nav03"
                    className="button is-white"
                    onClick={() => handleNavPress(orderSummary, 4)}
                  >
                    Select install date
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div id="topbarmenu" className="navbar-menu">
            <div className="navbar">
              <a
                id="nav01"
                className="navbar-item is-active has-text-dark has-background-white"
                onClick={() => handleNavPress(products, 1)}
                style={{ cursor: "default" }}
              >
                1. Choose boiler
              </a>

              <a
                id="nav02"
                className="navbar-item has-text-dark has-background-white"
                onClick={() => handleNavPress(controls, 2)}
                style={{ cursor: "default" }}
              >
                2. Choose controls{" "}
              </a>
              <a
                id="nav04"
                className="navbar-item has-text-dark has-background-white"
                onClick={() => handleNavPress(addARadiator, 3)}
                style={{ cursor: "default" }}
              >
                3. Add Radiator & Valve{" "}
              </a>
              {/* <a
                className="navbar-item has-text-dark has-background-white"
                id="nav04"
                style={{ cursor: "default" }}
                onClick={() => handleNavPress(addSmartDevice)}
              >
                3. Add smart devices
              </a> */}
              <a
                id="nav03"
                className="navbar-item has-text-dark has-background-white"
                onClick={() => handleNavPress(orderSummary, 4)}
                style={{ cursor: "default" }}
              >
                4. Select install date
              </a>
            </div>
          </div>
          {/* <div className='navbar-item cart_icon'><p className="badge">10</p> <i class="fa-solid fa-cart-shopping"></i></div> */}

          <div
            className="navbar-cart"
            onClick={() => {
              setShowCartModal((val) => !val);
            }}
          >
            <i class="fa-solid fa-cart-shopping fa-xl"></i>
            {/* <span class="navbar-cart-num">
              {totalProducts >= 10 ? "9+" : totalProducts}
            </span> */}
            {totalProducts > 0 && (
              <span
                className={`navbar-cart-num ${
                  totalProducts >= 10 ? "navbar-cart-num-9plus" : ""
                }`}
              >
                {totalProducts >= 10 ? "9+" : totalProducts}
              </span>
            )}
            {/* Modal */}
            {showCartModal && (
              <>
                <div className="cartModalContainer" ref={cartModalRef}>
                  {CartData && CartData.boiler_title && (
                    <div className="shopping-basket-item">
                      <div className="shopping-basket-item-sec">
                        <i class="fa-solid fa-fire"></i> {CartData.boiler_title}
                      </div>
                      <div className="shopping-basket-product-price">
                        {currencyPhoneEmail.company_currency}{" "}
                        {CartData && CartData.boiler_cost
                          ? Number(CartData.boiler_cost).toLocaleString()
                          : 0}
                      </div>
                    </div>
                  )}
                  {CartData && CartData.controls_name && (
                    <div className="shopping-basket-item">
                      <div className="shopping-basket-item-sec">
                        <i class="fa-solid fa-circle-check"></i>{" "}
                        {CartData.controls_name}
                      </div>
                      <div className="shopping-basket-product-price">
                        + {currencyPhoneEmail.company_currency}
                        {Number(CartData.controls_price).toLocaleString()}
                      </div>
                    </div>
                    // </div>
                  )}
                  {CartData && CartData.title && (
                    <div className=" shopping-basket-item">
                      <div className="shopping-basket-item-sec">
                        <i class="fa-solid fa-circle-check"></i>{" "}
                        {CartData.title}
                      </div>
                      <div className="shopping-basket-product-price">
                        {" "}
                        + {currencyPhoneEmail.company_currency}
                        {Number(CartData.thermostate_price).toLocaleString()}
                      </div>
                    </div>
                    // </div>
                  )}
                  {CartData && CartData.type && (
                    <div className="shopping-basket-item">
                      <div className="shopping-basket-item-sec">
                        <i class="fa-solid fa-circle-check"></i> {CartData.type}{" "}
                        {CartData.radiator_qty > 1 &&
                          `(x${CartData.radiator_qty})`}
                      </div>
                      <div className="shopping-basket-product-price is-flex is-justify-content-space-between">
                        {" "}
                        <p className="is-size-6"></p> +{" "}
                        {currencyPhoneEmail.company_currency}
                        {Number(CartData.radiator_price).toLocaleString()}{" "}
                        <span className=" has-text-danger">
                          {" "}
                          {/* ({CartData.radiator_qty}){" "} */}
                        </span>
                      </div>
                    </div>
                  )}
                  {CartData && CartData.volvo_title && (
                    <div className=" shopping-basket-item">
                      <div className="shopping-basket-item-sec">
                        <i class="fa-solid fa-circle-check"></i>{" "}
                        {CartData.volvo_title}
                        {CartData.volvoe_qty > 1 && `(x${CartData.volvoe_qty})`}
                      </div>
                      <div className="shopping-basket-product-price">
                        {" "}
                        + {currencyPhoneEmail.company_currency}
                        {CartData.volvoe_price}{" "}
                        {/* <span className="has-text-danger">({CartData.volvoe_qty})</span> */}
                      </div>
                    </div>
                  )}
                  {CartData && CartData.convert_combi_price && (
                    <div className=" shopping-basket-item">
                      <div className="shopping-basket-item-sec">
                        <i class="fa-solid fa-circle-check"></i> Extra Added
                        price for Combi Boiler
                      </div>
                      <div className="shopping-basket-product-price">
                        {" "}
                        + {currencyPhoneEmail.company_currency}
                        {CartData.convert_combi_price}{" "}
                        {/* <span className="has-text-danger">({CartData.volvoe_qty})</span> */}
                      </div>
                    </div>
                  )}
                  {CartData && CartData.upgrade_unwanted_price && (
                    <div className=" shopping-basket-item">
                      <div className="shopping-basket-item-sec">
                        <i class="fa-solid fa-circle-check"></i> Extra Added
                        price for Hot Water cylinder
                      </div>
                      <div className="shopping-basket-product-price">
                        {" "}
                        + {currencyPhoneEmail.company_currency}
                        {CartData.upgrade_unwanted_price}{" "}
                        {/* <span className="has-text-danger">({CartData.volvoe_qty})</span> */}
                      </div>
                    </div>
                  )}
                  {CartData && CartData.upgrade_unwanted_price && (
                    <div className=" shopping-basket-item">
                      <div className="shopping-basket-item-sec">
                        <i class="fa-solid fa-circle-check"></i> Extra Added
                        price for Hot Water cylinder
                      </div>
                      <div className="shopping-basket-product-price">
                        {" "}
                        + {currencyPhoneEmail.company_currency}
                        {CartData.upgrade_unwanted_price}{" "}
                        {/* <span className="has-text-danger">({CartData.volvoe_qty})</span> */}
                      </div>
                    </div>
                  )}
                  {CartData && CartData.flue_comesout_price && (
                    <div className=" shopping-basket-item">
                      <div className="shopping-basket-item-sec">
                        <i class="fa-solid fa-circle-check"></i> Extra Added
                        price for Flue comes Out from roof
                      </div>
                      <div className="shopping-basket-product-price">
                        {" "}
                        + {currencyPhoneEmail.company_currency}
                        {CartData.flue_comesout_price}{" "}
                        {/* <span className="has-text-danger">({CartData.volvoe_qty})</span> */}
                      </div>
                    </div>
                  )}
                  {CartData && CartData.additional && (
                    <div className=" shopping-basket-item">
                      <div className="shopping-basket-item-sec">
                        <i class="fa-solid fa-circle-check"></i> Extra Added
                        price
                      </div>
                      <div className="shopping-basket-product-price">
                        {" "}
                        + {currencyPhoneEmail.company_currency}
                        {/* {CartData.additional[0].price}{" "} */}
                        {CartData.additional.reduce(
                          (total, item) => total + parseInt(item.price),
                          0
                        )}{" "}
                      </div>
                    </div>
                  )}
                  <div className=" shopping-basket-item">
                    <div className="shopping-basket-item-sec">
                      <i class="fa-solid fa-tag"></i> <b>Grand total</b>
                    </div>
                    <div className="shopping-basket-product-price">
                      <p className="title is-4">
                        {" "}
                        {CartData && CartData.grand_total ? (
                          <>
                            {" "}
                            {currencyPhoneEmail.company_currency}
                            {Number(CartData.grand_total).toLocaleString()}
                          </>
                        ) : (
                          <>0</>
                        )}{" "}
                      </p>
                    </div>
                  </div>
                  <div class="up-arrow-cart"></div>
                </div>
              </>
            )}
          </div>
        </div>
      </nav>
    </section>
  );

  return (
    <div>
      <section>
        {route === getQuotes ? (
          <GetAquote />
        ) : route === infoForm ? (
          <InfoForm />
        ) : route === products ? (
          <>
            {renderNavigation()} <Products />
          </>
        ) : route === controls ? (
          <>
            {renderNavigation()} <ChooseControls />
          </>
        ) : route === orderSummary ? (
          <>
            {renderNavigation()} <OrderSummary />
          </>
        ) : route === addSmartDevice ? (
          <>
            {renderNavigation()}
            <AddSmartDevice />
          </>
        ) : route === addARadiator ? (
          <>
            {renderNavigation()}
            <AddARadiator />
          </>
        ) : route === productDetails ? (
          <>
            {renderNavigation()}
            <ProdcutDetails />
          </>
        ) : route === paymentSuccessful ? (
          <PaymentSuccessful />
        ) : route === addValves ? (
          <>
            {renderNavigation()}
            <AddValves />
          </>
        ) : (
          <AddresComponents />
        )}
      </section>
      <AddStartAgainModal
        showStartAgainModal={showStartAgainModal}
        setShowStartAgainModal={setShowStartAgainModal}
      />
    </div>
  );
};
export default Router;
