import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetchingControlDetails: false,
  getControlDetailsData: {},
  selectedOptions: [],
  isFetchingSingleControls: false,
  SingleControlsData: [],
  // NEW
  isFetchingFilterDetails: false,
  getFilterDetailsData: {},
};

const getControlDetailSlice = createSlice({
  name: "getControlDetails",
  initialState,
  reducers: {
    fetchControlDetailStarted(state) {
      state.isFetchingControlDetails = true;
      state.loading = true;
    },
    fetchControlDetailSuccess(state, { payload }) {
      state.isFetchingControlDetails = false;
      state.getControlDetailsData = payload;
      state.loading = false;
    },
    fetchControlDetailsFail(state) {
      state.isFetchingControlDetails = false;
      state.loading = false;
    },
    fetchSingleControlStarted(state) {
      state.isFetchingSingleControls = true;
    },
    fetchSingleControlSuccess(state, { payload }) {
      state.isFetchingSingleControls = false;
      state.SingleControlsData = payload;
    },
    fetchSingleControlFail(state, { payload }) {
      state.isFetchingSingleControls = false;
      state.SingleControlsData = payload;
    },

    // NEW

    fetchFilterDetailStarted(state) {
      state.isFetchingFilterDetails = true;
      state.loading = true;
    },
    fetchFilterDetailSuccess(state, { payload }) {
      state.isFetchingFilterDetails = false;
      state.getFilterDetailsData = payload;
      state.loading = false;
    },
    fetchFilterDetailsFail(state) {
      state.isFetchingFilterDetails = false;
      state.loading = false;
    },
  },
});

const { actions, reducer } = getControlDetailSlice;

export const {
  fetchControlDetailStarted,
  fetchControlDetailSuccess,
  fetchControlDetailsFail,
  fetchSingleControlFail,
  fetchSingleControlSuccess,
  fetchSingleControlStarted,
  fetchFilterDetailStarted,
  fetchFilterDetailSuccess,
  fetchFilterDetailsFail,
} = actions;
export default reducer;
