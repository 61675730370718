import { call, put } from "redux-saga/effects";
import apiClient from "../../../services/httpServices";
import { API_URL, GET_CART } from "../../../services/webConstant";
import {
  postOrderSummaryFail,
  postOrderSummarySucces,
  postorderSummaryStarted,
  getOrderSummaryFails,
  getorderSummarySuccess,
  getorderSummaryStarted,
  fetchInstallerDataFails,
  fetchInstallerDataSuccess,
  fetchInstallerDataStarted,
  postBookOrderSummaryStarted,
  postBookOrderSummaryfails,
  postBookOrderSummarySuccess,
} from "./OrderSummaryReducer";
import { GET_INSTALLER } from "./OrderSummaryActions";
import { SHOW_PAYMENT_DATA } from "../../paymeny-successful/redux/PaymentSuccessAction";

export function* postOrderSummarySaga(action) {
  const { payload } = action;

  try {
    yield put(postorderSummaryStarted());
    const OrderSummeryResponse = yield call(
      apiClient.post,
      API_URL.ORDER_SUMMARY_DETAILS,
      payload
    );

    yield put(postOrderSummarySucces(OrderSummeryResponse));
    // yield put({ type: GET_INSTALLER });
  } catch (err) {
    yield put(postOrderSummaryFail({}));
  }
}

export function* postBookOrderSummarySaga(action) {
  const { payload } = action;

  try {
    yield put(postBookOrderSummaryStarted());

    const postBookOrderSummaryResponse = yield call(
      apiClient.post,
      GET_CART.GET_BOOKING_ORDER,
      payload
    );

    yield put(postBookOrderSummarySuccess(postBookOrderSummaryResponse));
    yield put({ type: SHOW_PAYMENT_DATA });
  } catch (err) {
    yield put(postBookOrderSummaryfails());
  }
}

export function* getOrderSummarySaga(action) {
  const payload = action.payload;

  try {
    yield put(getorderSummaryStarted());
    const { data } = yield call(apiClient.get, API_URL.GET_ORDER_SUMMARY);
    yield put(getorderSummarySuccess(data.data));
  } catch (error) {
    yield put(getOrderSummaryFails());
  }
}

export function* getInstallerDetailSaga(action) {
  try {
    yield put(fetchInstallerDataStarted());

    const installerResponse = yield call(apiClient.get, API_URL.GET_INSTALLER);

    yield put(
      fetchInstallerDataSuccess({ InstallerData: installerResponse.data.data })
    );
  } catch (error) {
    yield put(fetchInstallerDataFails());
  }
}
