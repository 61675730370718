import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoadingKeyData: false,
  secretData: [],
  isStorePaymentDataLoading: false,
  storePaymentData: [],
};

const getClientSecretDetailSlice = createSlice({
  name: "getClientSecretDetail",
  initialState: initialState,
  reducers: {
    getclientKeyDataStarted(state) {
      state.isLoadingKeyData = true;
    },
    getclientKeyDataSuccess(state, { payload }) {
      state.isLoadingKeyData = false;
      state.secretData = payload;
    },
    getclientKeyDataFail(state, { payload }) {
      state.isLoadingKeyData = false;
      state.secretData = payload;
    },
    clearclientkeyDataSuccess(state) {
      state.isLoadingKeyData = false;
      state.secretData = "";
    },
    storePaymentDataDetailStarted(state) {
      state.isStorePaymentDataLoading = true;
    },
    storePaymentDataDetailSuccess(state, { payload }) {
      state.isStorePaymentDataLoading = false;
      state.storePaymentData = payload;
    },
    storePaymentDataDetailFail(state, { payload }) {
      state.isStorePaymentDataLoading = false;
      state.storePaymentData = payload;
    },
  },
});

const { actions, reducer } = getClientSecretDetailSlice;
export const {
  getclientKeyDataStarted,
  getclientKeyDataSuccess,
  getclientKeyDataFail,
  storePaymentDataDetailStarted,
  storePaymentDataDetailSuccess,
  storePaymentDataDetailFail,

  clearclientkeyDataSuccess,
} = actions;

export default reducer;
