import { call, put } from "redux-saga/effects";
import apiClient from "../../../services/httpServices";
import {
  API_PRODUCT_URL,
  HELP_CHOOSE_BOILER,
  SAVE_BOOKING,
} from "../../../services/webConstant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchProductsDetailstarted,
  fetchProductsDetailsuccess,
  fetchProductsDetailsFail,
  fetchProductDropdownSuccess,
  fetchProductDropdownStarted,
  fetchProductDropdownFail,
  postSaveQuoteEmailFail,
  postSaveQuoteEmailStarted,
  postSaveQuoteEmailuccess,
  isHelpChooseBoilterSubmissionStarted,
  isHelpChooseBoilterSubmissionSuccess,
  isHelpChooseBoilterSubmissionFail,
  fetchWhatsIncludedDataFails,
  fetchWhatsIncludedDataSuccess,
  fetchWhatsIncludedDataStarted,
  fetchUserBookingInfoDetailsFails,
  fetchUserBookinginfoDetailsSuccess,
  fetchUserBookingInfoDetailsStarted,
  fetchAdditionalCharges,
  fetchAdditionalChargesStart,
  fetchAdditionalChargesError,
  fetchAvailabilityDateStarted,
  fetchAvailabilityDateSuccess,
  fetchAvailabilityDateFails,
  postAllProductStarted,
  postAllProductSuccess,
  postAllProductFail,
  postParticularProductStarted,
  postParticularProductSuccess,
  postParticularProductFail,
} from "./productReducer";
import { GET_USERINFO_BOOKING_DETAILS } from "./productActions";
import qs from "query-string";
import { SEND_BY_MAIL } from "../../../services/webConstant";

export function* getProductDetailsSaga(action) {
  try {
    yield put(fetchProductsDetailstarted());
    const { email, ...rest } = action.payload;
    const params = qs.stringify(rest);
    const body = { email };

    const { data } = yield call(
      apiClient.post,
      `${API_PRODUCT_URL.GET_PRODUCT_DETAILS}?${params}`,
      body
    );

    console.log("data", data);
    yield put(
      fetchProductsDetailsuccess({
        productData: data.data,
        numberOfBedrooms: data.bedroom,
      })
    );
  } catch (error) {
    yield put(fetchProductsDetailsFail());
  }
}

export function* getProductDropdownSaga(action) {
  try {
    yield put(fetchProductDropdownStarted());

    const { email, ...rest } = action.payload;
    const params = qs.stringify(rest);
    const body = { email };
    console.log("this api is working");
    const { data } = yield call(
      apiClient.post,
      `${API_PRODUCT_URL.GET_PRODUCT_DETAILS}?${params}`,
      body
    );

    yield put(
      fetchProductDropdownSuccess({
        boilerType: data.data,
      })
    );
  } catch (err) {
    yield put(fetchProductDropdownFail());
  }
}

export function* postSaveQuoteSEmailSaga(action) {
  const { payload } = action;

  try {
    yield put(postSaveQuoteEmailStarted());
    const SaveQuoteEmailResponse = yield call(
      apiClient.post,
      SAVE_BOOKING.SAVE_BOOKING_MAIL,
      payload
    );

    yield put(postSaveQuoteEmailuccess(SaveQuoteEmailResponse));

    yield put({ type: GET_USERINFO_BOOKING_DETAILS });
  } catch (err) {
    yield put(postSaveQuoteEmailFail({}));
  }
}

export function* helpChooseBoilerSaga(action) {
  const { payload } = action;

  try {
    yield put(isHelpChooseBoilterSubmissionStarted());
    yield call(
      apiClient.post,
      HELP_CHOOSE_BOILER.HELP_CHOOSE_BOILER_API,
      payload
    );

    yield put(isHelpChooseBoilterSubmissionSuccess({}));
  } catch (err) {
    yield put(isHelpChooseBoilterSubmissionFail({}));
  }
}

export function* getAdditionalChargesSaga(action) {
  const params = action.email;
  try {
    yield put(fetchAdditionalChargesStart());
    const additionalCharges = yield call(
      apiClient.get,
      `${API_PRODUCT_URL.GET_ADDITIONAL_CHARGES}?email=${params}`
    );

    yield put(
      fetchAdditionalCharges({
        additionalValue: additionalCharges.data.grand_total,
      })
    );
  } catch (err) {
    yield put(fetchAdditionalChargesError());
  }
}

export function* getWhatsIncludedSaga(action) {
  try {
    yield put(fetchWhatsIncludedDataStarted());

    const whatsIncludedResponse = yield call(
      apiClient.get,
      API_PRODUCT_URL.GET_WHATS_INCLUDED_DATA
    );

    yield put(
      fetchWhatsIncludedDataSuccess({
        IncludedData: whatsIncludedResponse.data.data,
      })
    );
  } catch (err) {
    yield put(fetchWhatsIncludedDataFails());
  }
}

export function* getAvailabilityDate(action) {
  try {
    yield put(fetchAvailabilityDateStarted());

    const availabilityDate = yield call(
      apiClient.get,
      API_PRODUCT_URL.GET_AVAILABILITY_DATE
    );

    yield put(
      fetchAvailabilityDateSuccess({
        IncludedData: availabilityDate.data.data,
      })
    );
  } catch (err) {
    yield put(fetchAvailabilityDateFails());
  }
}

export function* getUserInfoDetailsSaga(action) {
  const { payload } = action;

  try {
    yield put(fetchUserBookingInfoDetailsStarted());

    const getUserInfoDetailsResponse = yield call(
      apiClient.get,
      SAVE_BOOKING.GET_USERINFO_BOOKING_DETAILS
    );

    yield put(
      fetchUserBookinginfoDetailsSuccess(getUserInfoDetailsResponse.data.data)
    );
  } catch (err) {
    yield put(fetchUserBookingInfoDetailsFails());
  }
}

export function* postAllProductEmailSaga(action) {
  const { payload } = action;
  try {
    console.log("🟢🟢🟢Inside postAllProductEmail Saga");
    yield put(postAllProductStarted());
    const postAllProductEmailResponse = yield call(
      apiClient.post,
      SEND_BY_MAIL.POST_ALL_PRODUCT_EMAIL,
      payload
    );
    console.log(
      "🔴🔴🔴Inside postAllProductEmail Response",
      postAllProductEmailResponse
    );
    yield put(postAllProductSuccess(postAllProductEmailResponse.data));
  } catch (err) {
    yield put(postAllProductFail());
  }
}

export function* postParticularProductEmailSaga(action) {
  const { payload } = action;
  try {
    console.log("🟢🟢🟢Inside postParticularProductEmail Saga");
    yield put(postParticularProductStarted());
    const postParticularProductEmailResponse = yield call(
      apiClient.post,
      SEND_BY_MAIL.POST_PARTICULAR_PRODUCT_EMAIL,
      payload
    );
    console.log(
      "🔴🔴🔴Inside postParticularProductEmail Response",
      postParticularProductEmailResponse
    );
    yield put(postAllProductSuccess(postParticularProductEmailResponse.data));
  } catch (err) {
    yield put(postAllProductFail());
  }
}
