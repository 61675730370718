import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "./modal.css";
import {
  SAVE_BOOKING_MAIL,
  POST_ALL_PRODUCT_EMAIL,
} from "../../../scenes/products/redux/productActions";

const SaveQuoteModal = ({
  saveAllQuoteModal,
  handleCancel,
  userPrefillData,
}) => {
  const dispatch = useDispatch();
  const { saveQuoteLoading, saveQuoteSuccess } = useSelector(
    ({ getProductsDetails }) => getProductsDetails
  );
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      checkbox: false,
      lastname: userPrefillData ? userPrefillData.Last_Name : "",
      FirstName: userPrefillData ? userPrefillData.First_Name : "",
      email: userPrefillData ? userPrefillData.Email_Id : "",
      phoneNumber: userPrefillData ? userPrefillData.phoneNumber : "",
    },
  });

  useEffect(() => {
    handleCancel();
  }, [saveQuoteSuccess]);

  const onSubmit = (data) => {
    reset();
    const payload = {
      first_name: data.FirstName,
      last_name: data.lastname,
      email: data.email,
      phone: data.phoneNumber,
    };

    dispatch({
      type: SAVE_BOOKING_MAIL,
      payload,
    });

    dispatch({
      type: POST_ALL_PRODUCT_EMAIL,
      payload: {
        email: data.email,
      },
    });
  };

  return (
    <>
      {saveAllQuoteModal && (
        <div
          className="modal is-active is-flex is-flex-direction-row is-justify-content-end"
          style={{ overflowY: "scroll" }}
        >
          {/* hello */}
          <div
            className="modal-background"
            onClick={() => handleCancel(false)}
          ></div>
          <div className="new-modal-card">
            <button
              className="delete button"
              aria-label="close"
              onClick={() => handleCancel(false)}
            ></button>

            {/* Modal Title */}
            <div className="modal-text-box column">
              <h1 className="modal-heading"> Send all my quotes </h1>
              <p className="modal-sub-heading">
                {" "}
                Upgrade your heating system with our personalized quote. Fill
                out the form Get a Free Boiler Quotes Now!{" "}
              </p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="column">
                <p className="mb-2">First Name</p>
                <Controller
                  name="FirstName"
                  control={control}
                  rules={{ required: "first Name is required" }}
                  render={({ field }) => (
                    <input
                      className="input formInput is-hovered is-size-6-mobile"
                      {...field}
                    />
                  )}
                />
                {errors.FirstName && (
                  <span className="is-size-7-mobile has-text-danger">
                    {errors.FirstName.message}*
                  </span>
                )}
              </div>
              <div className="column">
                <p className="mb-2">Last Name</p>
                <Controller
                  name="lastname"
                  control={control}
                  rules={{ required: "Last Name is required" }}
                  render={({ field }) => (
                    <input
                      className="input formInput is-hovered is-size-6-mobile"
                      {...field}
                    />
                  )}
                />
                {errors.lastname && (
                  <span className="is-size-7-mobile has-text-danger">
                    {errors.lastname.message}*
                  </span>
                )}
              </div>
              <div className="column">
                <p className="mb-2">Email</p>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  }}
                  render={({ field }) => (
                    <input
                      className="input formInput is-hovered is-size-6-mobile"
                      {...field}
                    />
                  )}
                />
                {errors.email && (
                  <span className="is-size-7-mobile has-text-danger">
                    {errors.email.message}*
                  </span>
                )}
              </div>
              <div className="column">
                <p className="mb-2">Phone</p>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: "Phone Number is required",
                    pattern: {
                      value: /^[6-9]\d{9}$/gi,
                      message: "Invalid Phone Number",
                    },
                  }}
                  render={({ field }) => (
                    <input
                      className="input formInput is-hovered is-size-6-mobile"
                      {...field}
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <span className="is-size-7-mobile has-text-danger">
                    {errors.phoneNumber.message}*
                  </span>
                )}
              </div>
              <div class="column is-flex">
                <Controller
                  name="checkbox"
                  control={control}
                  rules={{ required: "please check the field" }}
                  render={({ field }) => (
                    <input
                      type="checkbox"
                      className="is-size-7-mobile"
                      {...field}
                    />
                  )}
                />
                <p className="is-size-7-mobile ml-2">
                  {" "}
                  I agree to the{" "}
                  <a href="#" className="terms-btn">
                    terms and conditions
                  </a>
                </p>
              </div>
              {errors.checkbox && (
                <span className="is-size-7-mobile has-text-danger">
                  {errors.checkbox.message}*
                </span>
              )}

              <div className="column">
                <Controller
                  name="submit"
                  control={control}
                  render={({ field }) => (
                    <button className="submit-button">
                      {saveQuoteLoading ? (
                        <span class="loader"></span>
                      ) : (
                        "Save My Quotes"
                      )}
                    </button>
                  )}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SaveQuoteModal;
