import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_SMART_DEVICES,
  GET_SMART_DEVICES_DETAILS,
} from "./redux/addSmartDeviceAction";
import { selectedSmartDevice } from "../products/redux/bucketReducer";
import { ROUTES } from "../../utils/constants";
import { router } from "../router/Router";
import { setRoute } from "../router/redux/reducer";
import "./addSmartDevice.css";
import Cart from "../../myComponent/organisms/cart/Cart";
import {
  ADD_PRODUCT_TO_BASKET,
  REMOVE_CART_ITEM,
} from "../../myComponent/organisms/cart/redux/CartAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MoreInfoModal from "../../myComponent/molecule/Modal/MoreInfomModal";

const AddSmartDevice = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState();

  const [isMoreInfoModal, setIsMoreInfoModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const {
    getAddSmartDevicesData,
    loading,
    AddSmartDevice_single_Product_detail,
    AddSmartDevivceLoading,
  } = useSelector(({ getAddSmartDevices }) => getAddSmartDevices);
  const { CartData } = useSelector(({ addProductToCart }) => addProductToCart);
  const { currencyPhoneEmail, isLoadingdynamicCurrrencyandPhone } = useSelector(
    ({ getBoilerQuote }) => getBoilerQuote
  );
  const {
    isAddProductToCartSubmitting,
    addProductToCartSuccess,
    addProductToCardError,
  } = useSelector(({ addProductToCart }) => addProductToCart);
  const selectProduct = useSelector((selectProduct) => selectProduct);
  const getBoilerBrand = selectProduct.bucketReducer.selectedProduct;

  const dataSmartDevices = getAddSmartDevicesData.data;

  const handleNext = () => {
    dispatch(setRoute(ROUTES.orderSummary));
    router(ROUTES.orderSummary);
  };

  useEffect(() => {
    const boilerNames = sessionStorage.getItem("boilerName");

    dispatch({
      type: GET_SMART_DEVICES,
      payload: boilerNames,
    });
  }, []);

  const handleAddSmartDevice = (item, id) => {
    if (selectedItem === id) {
      setSelectedItem(null);
    } else {
      setSelectedItem(id);
    }

    setValue(item);
    const payload = {
      product_id: CartData && CartData.product_id,
      thermostat_id: item.id,
      radiator_id: CartData && CartData.radiator_id ? CartData.radiator_id : 0,
      controler_id: CartData && CartData.control_id ? CartData.control_id : 0,
      volve_id: CartData && CartData.volve_id ? CartData.volve_id : 0,
      radiator_qty: CartData && CartData.radiator_qty && CartData.radiator_qty,
      volvo_qty: CartData && CartData.volvoe_qty && CartData.volvoe_qty,
    };

    dispatch(selectedSmartDevice(item));
    dispatch({ type: ADD_PRODUCT_TO_BASKET, payload });
  };
  const handleSummary = () => {
    dispatch(setRoute(ROUTES.orderSummary));
    router(ROUTES.orderSummary);
  };

  const handleRemoveSmartDevice = (id) => {
    const payload = {
      thermostat_id: id,
    };

    dispatch({ type: REMOVE_CART_ITEM, payload });
  };
  const handleChangeBoiler = () => {
    dispatch(setRoute(ROUTES.products));
    router(ROUTES.products);
  };

  const handleforInfo = (id) => {
    setIsMoreInfoModal(true);
    const payload = {
      smart_id: id,
    };
    dispatch({ type: GET_SMART_DEVICES_DETAILS, payload });
  };

  if (loading) {
    return (
      <>
        <div className="loaderContainer">
          <div className="mainLoader"></div>
        </div>
      </>
    );
  }

  return (
    <div>
      <section className="section less-pad-mob shopping-bar-onlymob">
        <div className="container">
          <div className="columns is-mobile">
            {/* <div className="column">
              <span>Total</span>
              <h3 className="title">£4,000</h3>
            </div> */}
            <div className="column">
              <div className="mobile-cart-next">
                <div className="cart-item">
                  <a>
                    <i className="fa-solid"></i>
                  </a>
                </div>
                {/* <button
                  className="button is-success is-fullwidth"
                  onClick={() => handleSummary()}
                >
                  next
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer />


        <div className="is-max-desktop mt-5">
          <div className="columns  is-flex-direction-row is-flex-wrap-wrap is-justify-content-center">
            <div className="column ">

            <div className="new-title-sec title-sec">
              <h2>Add extras to your BOX</h2>
              <p > We’ll install your devices, connect them up & show you how they work </p> 
            </div>

              <div className="columns is-two-third is-flex-direction-row is-flex-wrap-wrap ">
                {dataSmartDevices &&
                  dataSmartDevices.map((item, id) => (
                    <div className="column is-half " key={id}>
                      <div
                        className={`control-box ${
                          selectedItem === id ? "product-card-selected" : ""
                        }`}
                      >
                        <div className="card-content">

                        <div className="header-sec">

                          <p className="control-more-info-btn" onClick={() => handleforInfo(item.id)}> <i class="fa-solid fa-circle-info"></i> More Info</p>

                          <p className="control-warranty">{item.warranty_year.split(" ")[0]}y Warranty</p>

                          </div>

                            <figure className="imagebox">
                              <img
                                src={item.image_path}
                                alt="Placeholder image"
                                // style={{ height: 200 }}
                              />
                            </figure>

                          <div className="control-desk">
                          

                            <p className="control-price">
                              {currencyPhoneEmail
                                ? currencyPhoneEmail.company_currency
                                : " £"}
                              {Number(item.price).toLocaleString()}
                            </p>
                            
                     
                            {/* <p className="subtitle is-7">{item.title}</p> */}
                            {CartData &&
                            CartData.thermostate_id &&
                            CartData.thermostate_id == item.id ? (
                              <button
                                className={`control-remove ${"is-danger"}`}
                                onClick={() => handleRemoveSmartDevice(item.id)}
                              >
                                <span>Remove <i class="fa-solid fa-xmark"></i> </span>

                              </button>
                            ) : (
                              <button
                                onClick={() => handleAddSmartDevice(item, id)}
                                className={`control-add ${
                                  isAddProductToCartSubmitting &&
                                  selectedItem === id
                                    ? " is-loading"
                                    : "is-success"
                                }`}
                              >
                                <span>ADD <i class="fa-solid fa-plus"></i> </span>
                              </button>
                            )}

                        
                          </div>
                          <footer className="control-name">
                        <p>
                                {item.title.substring(0, 18)}
                              </p>
                        </footer>
                        </div>
                        
                      </div>
                      {isMoreInfoModal && (
                        <MoreInfoModal
                          item={AddSmartDevice_single_Product_detail}
                          isMoreInfoModal={isMoreInfoModal}
                          setIsMoreInfoModal={setIsMoreInfoModal}
                          AddSmartDevivceLoading={AddSmartDevivceLoading}
                        />
                      )}
                    </div>
                  ))}
              </div>
            </div>

            <div className=" column is-one-third ">
              <Cart handleNext={handleNext} />
            </div>
          </div>
        </div>
      {/* </div> */}
    </div>
  );
};

export default AddSmartDevice;
