import { call, put } from "redux-saga/effects";
import apiClient from "../../../services/httpServices";

import {
  fetchgetARadiatorFails,
  fetchgetARadiatorStarted,
  fetchgetARadiatorSuccess,
  getRadiatorHeightDataFail,
  getRadiatorheightDataStarted,
  getRadiatorHeightDataSuccess,
  getRadiatorlengthStarted,
  getRadiatorlengthSuccess,
  getRadiatorlengthFail,
  getHelpMeChooseRadiatorFail,
  getHelpMeChooseRadiatorSuccess,
  getHelpMeChooseRadiatorStarted,
  getradiatorPriceandBTUSuccess,
  getradiatorPriceandBTUStarted,
  getradiatorPriceandBTUfail,
  getRadiatorProductInformationDataStarted,
  getRadiatorProductInformationDataSuccess,
  getRadiatorProductInformationDataFail,
  getRadiatorImageDataStarted,
  getRadiatorImageDataSuccess,
  getRadiatorImageDatafail,
} from "./AddaRadiatorReducer";
import { GET_RADIATOR_DATA } from "../../../services/webConstant";
// import { API_URL } from "../../../services/webConstant";

export function* getARadiatorImagedataSaga(action) {
  // const payload = action.payload;

  try {
    yield put(getRadiatorImageDataStarted());

    const { data } = yield call(apiClient.get, GET_RADIATOR_DATA.GET_RADIATOR);

    yield put(getRadiatorImageDataSuccess(data.data));
  } catch (err) {
    yield put(getRadiatorImageDatafail());
  }
}

export function* getARadiatorSaga(action) {
  // const payload = action.payload;

  try {
    yield put(fetchgetARadiatorStarted());

    const { data } = yield call(
      apiClient.get,
      GET_RADIATOR_DATA.GET_RADIATOR_TYPE
    );

    yield put(fetchgetARadiatorSuccess(data.data));
  } catch (err) {
    yield put(fetchgetARadiatorFails());
  }
}

export function* getRadiatorHeightSaga(action) {
  const { payload } = action;

  try {
    yield put(getRadiatorheightDataStarted());

    const radiatorheightdata = yield call(
      apiClient.post,
      GET_RADIATOR_DATA.GET_RADIATOR_HEIGHT,
      payload
    );

    yield put(getRadiatorHeightDataSuccess(radiatorheightdata.data.data));
  } catch (err) {
    yield put(getRadiatorHeightDataFail());
  }
}

export function* getRadiatorLengthSaga(action) {
  const { payload } = action;

  try {
    yield put(getRadiatorlengthStarted());

    const radiatorLengthdata = yield call(
      apiClient.post,
      GET_RADIATOR_DATA.GET_RADIATOR_LENGTH,
      payload
    );

    yield put(getRadiatorlengthSuccess(radiatorLengthdata.data.data));
  } catch (err) {
    yield put(getRadiatorlengthFail());
  }
}

export function* getHelpMeChooseRadiatorSaga(action) {
  const { payload } = action;
  try {
    yield put(getHelpMeChooseRadiatorStarted());

    const helpMeChooseRadiatorData = yield call();
    yield put(getHelpMeChooseRadiatorSuccess());
  } catch (err) {
    yield put(getHelpMeChooseRadiatorFail());
  }
}

export function* getRadiatorPriceandBtuSaga(action) {
  const { payload } = action;

  try {
    yield put(getradiatorPriceandBTUStarted());
    const radiatorPriceBtu = yield call(
      apiClient.post,
      GET_RADIATOR_DATA.GET_RADIATOR_PRICE_BTU,
      payload
    );

    yield put(getradiatorPriceandBTUSuccess(radiatorPriceBtu.data.data));
  } catch (err) {
    yield put(getradiatorPriceandBTUfail());
  }
}

export function* getProductInformationDataSaga(action) {
  try {
    yield put(getRadiatorProductInformationDataStarted());

    const getradiatorProductInfodata = yield call(
      apiClient.get,
      GET_RADIATOR_DATA.PRODUCT_RADIATOR_INFO_DATA
    );
    yield put(
      getRadiatorProductInformationDataSuccess(
        getradiatorProductInfodata.data.data
      )
    );
  } catch (err) {
    yield put(getRadiatorProductInformationDataFail());
  }
}
