import React, { useState } from "react";
import HTMLReactParser from "html-react-parser";
import "./quoteCarddesign.css";
import UseSVG from "../../../atom/image/UseSVG";
import logo1 from "../../../../assets/svgIcons/averge-tap.svg";
import logo2 from "../../../../assets/svgIcons/back-boiler.svg";
import logo3 from "../../../../assets/svgIcons/bath-1.svg";
import logo4 from "../../../../assets/svgIcons/bath-2.svg";
import logo5 from "../../../../assets/svgIcons/bath-3.svg";
import logo6 from "../../../../assets/svgIcons/bedroom-1 2.svg";
import logo7 from "../../../../assets/svgIcons/bedroom-2.svg";
import logo8 from "../../../../assets/svgIcons/bedroom-3.svg";
import logo9 from "../../../../assets/svgIcons/bedroom-4 2.svg";
import logo10 from "../../../../assets/svgIcons/bedroom-5.svg";
import logo11 from "../../../../assets/svgIcons/bedroom.svg";
import logo12 from "../../../../assets/svgIcons/bungalow.svg";
import logo13 from "../../../../assets/svgIcons/combi-boiler.svg";
import logo14 from "../../../../assets/svgIcons/cupboard.svg";
import logo15 from "../../../../assets/svgIcons/detached.svg";
import logo16 from "../../../../assets/svgIcons/doesntFitWithOurPlan.svg";
import logo17 from "../../../../assets/svgIcons/fast-tap.svg";
import logo18 from "../../../../assets/svgIcons/flat-1.svg";
import logo19 from "../../../../assets/svgIcons/flat.svg";
import logo20 from "../../../../assets/svgIcons/floor-standing.svg";
import logo21 from "../../../../assets/svgIcons/garage.svg";
import logo22 from "../../../../assets/svgIcons/highest-two-third.svg";
import logo23 from "../../../../assets/svgIcons/horizontal-flue.svg";
import logo24 from "../../../../assets/svgIcons/kitchen.svg";
import logo25 from "../../../../assets/svgIcons/loft.svg";
import logo26 from "../../../../assets/svgIcons/lowest-third.svg";
import logo27 from "../../../../assets/svgIcons/lpg.svg";
import logo28 from "../../../../assets/svgIcons/mains-gas.svg";
import logo29 from "../../../../assets/svgIcons/mid-terrace.svg";
import logo30 from "../../../../assets/svgIcons/noWithImage.svg";
import logo31 from "../../../../assets/svgIcons/notworking.svg";
import logo32 from "../../../../assets/svgIcons/oil.svg";
import logo33 from "../../../../assets/svgIcons/old&inefficient.svg";
import logo34 from "../../../../assets/svgIcons/other.svg";
import logo35 from "../../../../assets/svgIcons/regular-boiler.svg";
import logo36 from "../../../../assets/svgIcons/same-room.svg";
import logo37 from "../../../../assets/svgIcons/semi-detached-1.svg";
import logo38 from "../../../../assets/svgIcons/semi-detached.svg";
import logo39 from "../../../../assets/svgIcons/shower-1_1.svg";
import logo40 from "../../../../assets/svgIcons/shower-2 1.svg";
import logo41 from "../../../../assets/svgIcons/slow-tap.svg";
import logo42 from "../../../../assets/svgIcons/spoled.svg";
import logo43 from "../../../../assets/svgIcons/system-boiler.svg";
import logo44 from "../../../../assets/svgIcons/unknown.svg";
import logo45 from "../../../../assets/svgIcons/utiily-room.svg";
import logo46 from "../../../../assets/svgIcons/vertical-flue.svg";
import logo47 from "../../../../assets/svgIcons/wall-moundet.svg";
import logo48 from "../../../../assets/svgIcons/yesWithImageBulb.svg";
import logo49 from "../../../../assets/svgIcons/shower-0.svg";
import logo50 from "../../../../assets/svgIcons/shower-1.svg";
import logo51 from "../../../../assets/svgIcons/shower-3.svg";
import logo52 from "../../../../assets/svgIcons/1-5-radiators.svg";
import logo53 from "../../../../assets/svgIcons/6-10-radiators.svg";
import logo54 from "../../../../assets/svgIcons/11-15-radiators.svg";
import logo55 from "../../../../assets/svgIcons/Over-15-radiators.svg";
import logo56 from "../../../../assets/svgIcons/less-then-two-meters.svg";
import logo57 from "../../../../assets/svgIcons/more-then-two-meters.svg";
import logo58 from "../../../../assets/svgIcons/no.svg";
import logo59 from "../../../../assets/svgIcons/yes.svg";
import logo60 from "../../../../assets/svgIcons/one-floor.svg";
import logo61 from "../../../../assets/svgIcons/two-floor.svg";
import logo62 from "../../../../assets/svgIcons/round.svg";
import logo63 from "../../../../assets/svgIcons/square.svg";
import logo64 from "../../../../assets/svgIcons/10-20-years.svg";
import logo65 from "../../../../assets/svgIcons/20-25-years.svg";
import logo66 from "../../../../assets/svgIcons/up-to-10-years.svg";
import logo67 from "../../../../assets/svgIcons/Over-25-years.svg";
import logo68 from "../../../../assets/svgIcons/I-dont-know.svg";
import logo69 from "../../../../assets/svgIcons/bedroom-5-new.svg";
import logo70 from "../../../../assets/svgIcons/bedroom-6+.svg";
import logo71 from "../../../../assets/svgIcons/no-bathtub.svg";

const QuoteCard = ({
  title,
  Options,
  handleSelect,
  questionSubTitle,
  handelModal,
  modalPresent,
}) => {
  const [showing, setShowingData] = useState(false);
  const [bgColor, setBgcolor] = useState("");
  const [descState, setDescState] = useState({});
  const [openDescriptionIndex, setOpenDescriptionIndex] = useState(null);

  const isShowing = () => {
    if (showing === true) {
      setShowingData(false);
      setBgcolor("");
    } else {
      setShowingData(true);
      setBgcolor("#f2761a");
    }
  };

  return (
    <section className="section">
      <div className="container has-text-centered">
        <h2 className="subtitle is-2 is-size-3-mobile mb-3">
          {HTMLReactParser(`${title}`)}
        </h2>

        <h5 className="subtitle is-5 is-size-5-mobile detl-txt">
          {modalPresent === "Yes" ? (
            <a
              className="is-underlined js-modal-trigger"
              data-target="modal-js-1"
              onClick={() => handelModal()}
            >
              <i className="fa fa-info-circle" aria-hidden="true"></i>{" "}
              {questionSubTitle}
            </a>
          ) : (
            <></>
          )}
        </h5>

        <div className="is-flex is-flex-wrap-wrap is-justify-content-center">
          {Options.map((item, index) => {
            const isDescriptionOpen = openDescriptionIndex === index;

            return (
              <div
                className="RectangleCard"
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelect({ item });
                }}
              >
                <div
                  className="all-option"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect({ item });
                  }}
                >
                  <i
                    className="card-main-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelect({ item });
                    }}
                  >
                    <img src={item.option_icon} alt="React Logo" />
                  </i>
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelect({ item });
                    }}
                  >
                    {item.option_title}
                  </p>
                  <p
                    className="show-info"
                    onClick={() => setOpenDescriptionIndex(index)}
                  >
                    <i className="fa-solid fa-info"></i>
                  </p>
                </div>
                <div
                  className={`card__content ${
                    isDescriptionOpen ? "description-open" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect({ item });
                  }}
                >
                  <p className="card__title">
                    <i class="fa-solid fa-circle-info"></i>
                  </p>
                  <p>{item.option_discription}</p>
                </div>

                {isDescriptionOpen && (
                  <>
                    <p className="card__description is-hidden-tablet is-hidden-desktop">
                      {item.option_discription}
                    </p>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default QuoteCard;
