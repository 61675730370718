import { call, put } from "redux-saga/effects";
import apiClient from "../../../../services/httpServices";

import {
  getclientKeyDataStarted,
  getclientKeyDataSuccess,
  getclientKeyDataFail,
  storePaymentDataDetailStarted,
  storePaymentDataDetailSuccess,
  storePaymentDataDetailFail,
} from "./PaymentReducer";
import { CREATE_SECRETDATA } from "../../../../services/webConstant";

export function* postclientDataForSecretKeySaga(action) {
  const { payload } = action;

  console.log("client secret==>", payload);

  try {
    yield put(getclientKeyDataStarted());

    const secretdata = yield call(
      apiClient.post,
      CREATE_SECRETDATA.STARTED_CREATING_PAYMENT,

      payload
    );

    yield put(getclientKeyDataSuccess(secretdata.data));
  } catch (err) {
    yield put(getclientKeyDataFail());
  }
}

export function* postStorePaymentDataSaga(action) {
  const { payload } = action;

  try {
    yield put(storePaymentDataDetailStarted());

    const storePaymentData = yield call(
      apiClient.post,
      CREATE_SECRETDATA.STORE_PAYMENT_DATA,
      payload
    );

    yield put(storePaymentDataDetailSuccess(storePaymentData));
  } catch (err) {
    yield put(storePaymentDataDetailFail());
  }
}
