import axios from "axios";
import { store } from "../stores";

const apiClient = axios.create({
	baseURL: "https://api.draftforclients.online/public",
});

// Add a request interceptor
apiClient.interceptors.request.use((config) => {
	const {
		clientDetails: { clientToken },
	} = store.getState();

	if (clientToken) {
		config.headers["Authorization"] = `Bearer  ${clientToken}`;
	}

	return config;
});

export default apiClient;

// import axios from "axios";

// export const apiClient = axios.create({
//   baseURL: "https://api.draftforclients.online/public",
//   headers: {
//     Authorization:
//       "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS5kcmFmdGZvcmNsaWVudHMub25saW5lL3B1YmxpYy9hcGkvZ2V0LWNsaWVudCIsImlhdCI6MTY5MDgxODQzNywiZXhwIjoxNjkxNDIzMjM3LCJuYmYiOjE2OTA4MTg0MzcsImp0aSI6Ik9uampMN1phRThSMUh0ZVEiLCJzdWIiOiIzMSIsInBydiI6IjYyNDU4ZjU2YjY0YTc1NTA5ODFmMDVmOThiNjQzMDBmY2M1NmViYWIifQ.3EFbC787TMp7FAF-sZxoqBREH95CQnorF5OI6eV_I_M",
//   },
// });

// export default apiClient;
