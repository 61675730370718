import React from "react";
import { useSelector } from "react-redux";

const PrivacyPolicyModal = ({ privacypolicydata, setPrivacypolicyData }) => {
  const { privacyPolicyData, isgettingPrivacyPolicyLoading } = useSelector(
    (state) => state.getTermAndCondition
  );

  if (isgettingPrivacyPolicyLoading) {
    return <div className="mainLoader"></div>;
  }
  return (
    <>
      {privacypolicydata && (
        <div className="modal is-active">
          <div class="modal-background"></div>
          <div className="modal-content quotesContainer p-5">
            <div className="saveQuoteModal">
              <h1 className="title is-4 termsandconditiontitle mt-4">
                {" "}
                Privacy Policy
              </h1>
              <div className="column">
                {privacyPolicyData &&
                  privacyPolicyData.map((item, index) => {
                    return (
                      <div>
                        <h1 className="subtitle is-6">{item.terms_page}</h1>
                      </div>
                    );
                  })}
                <div
                  className="is-flex is-justify-content-center mt-5
"
                >
                  <button
                    className="termsandconditionbtn"
                    onClick={() => setPrivacypolicyData(false)}
                  >
                    close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyPolicyModal;
