import React from "react";
import { useState } from "react";
import StripeIndex from "./StripeIndex";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useEffect } from "react";
import { STARTED_CREATING_PAYMENT } from "./redux/paymentAction";
import PayByFinance from "../pay-finance/PayByFinance";
const PUBLIC_KEY = "pk_test_rWnTsKKDnZd6qFPshyYMvSp5";
//pk_test_rWnTsKKDnZd6qFPshyYMvSp5

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const Checkout = ({ paymentGatewayCheckoutPage }) => {
  const dispatch = useDispatch();
  // useEffect(() => {

  // }, []);
  const [stripePaymentProcess, setStripePaymentProcess] = useState(false);
  const [payByfinanceprocess, setPayByFInanceProcess] = useState(false);
  const [payAfterInstallation, setPayAfterInstallation] = useState(false);
  const [payByKanda, setPayByKanda] = useState(false);

  const { secretData } = useSelector(
    ({ getClientSecretDetail }) => getClientSecretDetail
  );

  const options = {
    mode: "payment",

    amount: 1099,
    currency: "inr",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <div className="checkout-box">
      <section>
        <div className="order-summry-box-inner p-5">
          <div className="columns is-flex is-flex-direction-row is-justify-content-space-between is-align-content-center">
            <div className="checkout-lable">
              <i class="far fa-credit-card"></i>
              <p>How would you like to Pay</p>
            </div>

            <div></div>
          </div>
        </div>
      </section>
      {secretData && paymentGatewayCheckoutPage && (
        <>
          <div className="m-3 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
            <div></div>
            <p className="subtitle is-5">
              Make one payment by card or pay in monthly installments with our{" "}
            </p>
            <label
              htmlFor="cardRadio"
              className="pay-option-box is-align-content-center column is-6 is-centered is-flex is-justify-content-space-between"
            >
              <div className="is-flex is-flex-direction-row is-align-content-center">
                <input
                  type="radio"
                  name="paymentmode"
                  id="cardRadio"
                  checked={stripePaymentProcess}
                  onChange={() => {
                    setStripePaymentProcess(true);
                    setPayByFInanceProcess(false);
                    setPayAfterInstallation(false);
                    setPayByKanda(false);
                  }}
                  style={{ width: "50px", height: "30px", alignSelf: "center" }}
                />
                <p>Pay By Card</p>
              </div>
              <div>
                <span className="is-info mx-2">
                  <i class="fab fa-cc-visa"></i>
                </span>
                <span className="mx-2">
                  <i class="fab fa-cc-amex"></i>
                </span>
              </div>
            </label>
            <label
              htmlFor="financeRadio"
              className="pay-option-box is-align-content-center column is-6 is-centered is-flex is-justify-content-space-between"
            >
              <div className="is-flex is-flex-direction-row is-align-content-center">
                <input
                  type="radio"
                  name="paymentmode"
                  id="financeRadio"
                  checked={payByfinanceprocess}
                  onChange={() => {
                    setStripePaymentProcess(false);
                    setPayByFInanceProcess(true);
                    setPayAfterInstallation(false);
                    setPayByKanda(false);
                  }}
                  style={{ width: "50px", height: "30px", alignSelf: "center" }}
                />
                <p>Finance</p>
              </div>
            </label>
            {/* <label
              htmlFor="payAfterRadio"
              className="pay-option-box is-align-content-center column is-6 is-centered is-flex is-justify-content-space-between"
            >
              <div className="is-flex is-flex-direction-row is-align-content-center">
                <input
                  type="radio"
                  name="paymentmode"
                  id="payAfterRadio"
                  checked={payAfterInstallation}
                  onChange={() => {
                    setStripePaymentProcess(false);
                    setPayByFInanceProcess(false);
                    setPayAfterInstallation(true);
                    setPayByKanda(false);
                  }}
                  style={{ width: "50px", height: "30px", alignSelf: "center" }}
                />
                <p>Pay after Installation</p>
              </div>
            </label>
            <label
              htmlFor="kandaPayRadio"
              className="pay-option-box is-align-content-center column is-6 is-centered is-flex is-justify-content-space-between"
            >
              <div className="is-flex is-flex-direction-row is-align-content-center">
                <input
                  type="radio"
                  name="paymentmode"
                  id="kandaPayRadio"
                  checked={payByKanda}
                  onChange={() => {
                    setStripePaymentProcess(false);
                    setPayByFInanceProcess(false);
                    setPayAfterInstallation(false);
                    setPayByKanda(true);
                  }}
                  style={{ width: "50px", height: "30px", alignSelf: "center" }}
                />
                <p className="is-flex-grow-1">Pay using Kanda Pay</p>
              </div>
              <div>
                <img
                  src="./image/kandapay.png"
                  height={"20px"}
                  width={"40px"}
                />
              </div>
            </label> */}
          </div>
          {stripePaymentProcess && (
            <section className="has-text-centered">
              <div className="columns">
                <div className="summary-box">
                  <Elements stripe={stripeTestPromise} options={options}>
                    <CheckoutForm
                      setStripePaymentProcess={setStripePaymentProcess}
                    />
                  </Elements>
                  {/* )} */}
                </div>
              </div>
            </section>
          )}
          {payByfinanceprocess && (
            <>
              <PayByFinance
                setPayByFInanceProcess={setPayByFInanceProcess}
                payByfinanceprocess={payByfinanceprocess}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Checkout;
