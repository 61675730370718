import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isfetchingBoilerQuotes: false,
  getBoilerQuoteData: [
    {
      id: 4,
      option_id: null,
      previous_id: 4,
      quote_type: "1",
      boiler_title: "What kind of fuel does your boiler use?",
      boiler_subtitle: "How to find out",
      modal_present: "Yes",
      modal_title: "How to find out",
      more_details_text:
        "The fuel type is often written on the front of the boiler.\n\nMost boilers are supplied with gas. If you have a gas meter, gas cooker or gas fire it is likely to be a gas boiler. If you have gas bottles or an LPG storage tank outside then it is LPG.If you're still unsure, give us a ring on 0800 193 7777",
      num_option: "3",
      card_type: "quoteCard",
      date_update: "2022-12-20 18:34:14",
      status: "1",
      created_at: "2023-01-03T12:11:26.000000Z",
      updated_at: "2023-01-03T12:11:26.000000Z",
      optiones: [
        {
          id: 5,
          question_id: "4",
          next_question_value_id: "5",
          previous_question_value_id: null,
          option_title: "Gas",
          option_subtitle: "",
          option_price: "",
          option_icon: "/static/media/mains-gas.4b13b89a.svg",
          option_discription: "If you have a gas meter, your boiler uses gas.",
          card_type: null,
          status: "1",
          created_at: "2023-01-03T14:13:17.000000Z",
          updated_at: "2023-01-03T14:13:17.000000Z",
        },
        {
          id: 6,
          question_id: "4",
          next_question_value_id: "5",
          previous_question_value_id: null,
          option_title: "LPG",
          option_subtitle: "",
          option_price: "",
          option_icon: "/static/media/lpg.c604a4aa.svg",
          option_discription:
            "LPG stands for Liquid Petroleum Gas. It's a gas stored in a tank outside. It's not the same as oil so please check carefully.",
          card_type: "quoteCard",
          status: "1",
          created_at: "2023-01-03T14:13:17.000000Z",
          updated_at: "2023-01-03T14:13:17.000000Z",
        },
        {
          id: 7,
          question_id: "4",
          next_question_value_id: null,
          previous_question_value_id: null,
          option_title: "Oil",
          option_subtitle: "",
          option_price: "",
          option_icon: "/static/media/oil.76e1cc95.svg",
          option_discription:
            "Oil boilers work by using a stored supply of oil in an external tank. These are more common in rural areas.",
          card_type: null,
          status: "1",
          created_at: "2023-01-03T14:13:17.000000Z",
          updated_at: "2023-01-03T14:13:17.000000Z",
        },
      ],
    },
  ],

  loading: false,
  selectedOptions: [],
  phoneNumberValidateError: {},
  emailValidateError: {},
  isLoadingdynamicCurrrencyandPhone: false,
  currencyPhoneEmail: [],
};

const getBoilerQuoteSlice = createSlice({
  name: "getBoilerQuote",
  initialState,
  reducers: {
    fetchBoilerQuoteStarted(state) {
      state.isfetchingBoilerQuotes = true;
      state.loading = true;
    },
    fetchBoilerQuoteSuccess(state, { payload }) {
      state.isfetchingBoilerQuotes = false;
      state.getBoilerQuoteData = payload;
      state.loading = false;
    },
    fetchBoilerQuoteFail(state) {
      state.isfetchingBoilerQuotes = false;
      state.loading = false;
    },
    storeSlectedOption(state, { payload }) {
      const index = state.selectedOptions.findIndex(
        (item) => item.questionId === payload.questionId
      );

      if (index === -1) {
        state.selectedOptions.push({
          questionId: payload.questionId,
          optionSelected: payload.selectedOption,
          questionTitle: payload.questionTitle,
        });
      } else {
        state.selectedOptions[index] = {
          questionId: payload.questionId,
          optionSelected: payload.selectedOption,
          questionTitle: payload.questionTitle,
        };
      }
    },
    postSaveBoilerQuoteDataStarted(state) {
      state.loading = true;
    },
    loadingState(state, { payload }) {
      // if (payload === 200) {
      state.loading = false;
      // }
    },
    postSaveBoilerQuoteDataFails(state, { payload }) {
      state.loading = false;
    },

    phoneValidateFail(state, { payload }) {
      state.phoneNumberValidateError = payload;
    },
    emailValidateFail(state, { payload }) {
      state.emailValidateError = payload;
    },
    getPhoneEmailCurrencyStarted(state) {
      state.isLoadingdynamicCurrrencyandPhone = true;
    },
    getPhoneEmailCurrencySuccess(state, { payload }) {
      state.isLoadingdynamicCurrrencyandPhone = false;
      state.currencyPhoneEmail = payload.data;
    },
    getPhoneEmailCurrencyFails(state, { payload }) {
      state.isLoadingdynamicCurrrencyandPhone = false;
    },
    clearCurrencyEMailphoneData(state) {
      state.isLoadingdynamicCurrrencyandPhone = false;
      state.currencyPhoneEmail = [];
    },
  },
});

const { actions, reducer } = getBoilerQuoteSlice;

export const {
  fetchBoilerQuoteStarted,
  fetchBoilerQuoteSuccess,
  fetchBoilerQuoteFail,
  storeSlectedOption,
  phoneValidateFail,
  emailValidateFail,
  postSaveBoilerQuoteDataStarted,
  postSaveBoilerQuoteDataFails,
  loadingState,
  getPhoneEmailCurrencyStarted,
  getPhoneEmailCurrencyFails,
  clearCurrencyEMailphoneData,
  getPhoneEmailCurrencySuccess,
} = actions;
export default reducer;
