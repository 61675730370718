import { GET_RADIATOR } from "../scenes/radiator/redux/AddaRadiatorAction";

export const API_URL = {
  GET_BOILER_QUOTE: "https://api.draftforclients.online/public/api",
  // VALIDATE_PHONE_NUMBER: "https://api.veriphone.io/v2/verify",
  // VALIDATE_EMAIL_ADDRESS: "https://api.zerobounce.net/v2/validate",
  SAVE_BOILER_QUOTE:
    "https://api.draftforclients.online/public/api/save-quotes",
  GET_SMART_DEVICES: "api/smart-device",
  ORDER_SUMMARY_DETAILS: "api/order_summary",
  GET_ORDER_SUMMARY: "api/get-booking-details",
  GET_NEW_QUOTES: "api/get-quotes-demo",
  GET_RADIATOR: "api/get-radiators",
  GET_VALVE: "api/get-valves",
  GET_INSTALLER: "api/fetch-installer",
  GET_ALL_SAVE_USER_DATA_DETAILS: "api/get-all-save-details",
};
export const QUOTES_URL = {
  GET_QUOTES: "api/get-quotes-demo",
};

export const GET_TOKEN = {
  GET_TOKEN_URL: "api/get-client",
};

export const GET_CART = {
  ADD_PRODUCT_TO_BASKET: "api/add_to_cart",
  GET_CART_PRODUCT_DETAILS: "api/get_cart",
  GET_BOOKING_ORDER: "api/book-order",
  REMOVE_CART_ITEM: "api/cart-item-delete ",
};

export const API_PRODUCT_URL = {
  GET_PRODUCT_DETAILS: "api/show-product",
  SAVE_PRODUCT_DETAILS: "saveProductDetails/",
  GET_DROPDOWN_DETAILS: "api/show-product",
  GET_SINGLE_PRODUCT_DETAILS: "api/product_by_id",
  GET_WHATS_INCLUDED_DATA: "api/whts-included",
  GET_ADDITIONAL_CHARGES: "api/addition_charges",
  GET_AVAILABILITY_DATE: "api/engineer-availability-date",
};
//?product_id=5c

export const API_FORM_URL = {
  GET_FORM_DETAILS:
    "https://api.draftforclients.online/public/api/postalcodeShow",
};

export const API_CONTROL_URL = {
  GET_CONTROL_DETAILS: "api/get_caintrols_value",
  // NEW
  GET_FILTER_DETAILS: "api/get-filters",
  SAVE_CONTROL_DETAILS: "saveProductDetails/",
  GET_SINGLE_CHOOSE_CONTROL: "api/get-controls-info",
  GET_SMART_DEVICES_DETAILS: "api/get-smart-device-info",
};

export const SAVE_BOOKING = {
  SAVE_BOOKING_MAIL: "api/save-booking-mail",
  GET_USERINFO_BOOKING_DETAILS: "api/get-booking-info",
};

// export const API_ADDSMART_DEVICE_URL={
//   GET_SMART_DEVICES:"https://api.draftforclients.online/public/api/get-products"
// }

export const HELP_CHOOSE_BOILER = {
  HELP_CHOOSE_BOILER_API: "api/help-me-choose",
};

export const CREATE_SECRETDATA = {
  STARTED_CREATING_PAYMENT: "api/payment/create",
  STORE_PAYMENT_DATA: "api/store_payment_data",
};

export const SHOW_DATA_PAYMENT = {
  SHOW_PAYMENT_DATA: "api/show_payment_data",
};
export const GET_RADIATOR_DATA = {
  GET_RADIATOR_TYPE: "api/get-radiator-type",
  GET_RADIATOR_HEIGHT: "api/get-radiator-height",
  GET_RADIATOR_LENGTH: "api/get-radiator-length",
  GET_RADIATOR_PRICE_BTU: "api/get-radiator-price",
  PRODUCT_RADIATOR_INFO_DATA: "api/get-radiator-product-info",
  GET_RADIATOR: "api/get-radiators",
};

export const TERM_CONDITION = {
  GET_TERM_CONDITIONS: "api/get-terms-conditions",
  GET_PRIVACY_POLICY: "api/get-prcivacy-policy",
  GET_CURRENCY_PHONE_EMAIL: "api/get-client-currency",
};
//getradiatorPriceandBTUfail
//

export const VALIDATION = {
  PHONE_NUMBER_VALIDATION: "https://api.veriphone.io/v2/verify",
};

export const SEND_BY_MAIL = {
  POST_QUOTE_EMAIL: "api/save_quote_email_send",
  POST_ALL_PRODUCT_EMAIL: "api/product-mail-send?bedroom=&brand_id=",
  POST_PARTICULAR_PRODUCT_EMAIL:
    "api/product-particular-mail-send?bedroom=&brand_id=",
};
