import { call, put } from "redux-saga/effects";
import apiClient from "../../../services/httpServices";
import {
  API_URL,
  QUOTES_URL,
  TERM_CONDITION,
} from "../../../services/webConstant";
import { useDispatch, useSelector } from "react-redux";
import {
  emailValidateFail,
  fetchBoilerQuoteFail,
  fetchBoilerQuoteStarted,
  fetchBoilerQuoteSuccess,
  loadingState,
  phoneValidateFail,
  postSaveBoilerQuoteDataStarted,
  postSaveBoilerQuoteDataFails,
  storeSlectedOption,
  getPhoneEmailCurrencyStarted,
  getPhoneEmailCurrencyFails,
  clearCurrencyEMailphoneData,
  getPhoneEmailCurrencySuccess,
} from "./GetAquoteReducer";
import { GET_ALL_SAVE_USER_DATA_DETAILS } from "../../router/redux/RouterAction";
import {
  GET_DROPDOWN_DETAILS,
  GET_PRODUCT_DETAILS,
} from "../../products/redux/productActions";
import { setRoute } from "../../router/redux/reducer";
import { ROUTES } from "../../../utils/constants";
let url = window.location.search;
const newUrl = url.split("?")[1];
const param1 = newUrl.split("&&");
let p1 = param1[0];

export function* getBoilerQuoteSaga(action) {
  const { payload } = action;
  try {
    yield put(fetchBoilerQuoteStarted());
    const { data } = yield call(
      apiClient.get,
      `${QUOTES_URL.GET_QUOTES}?random_id=${p1}`
    );

    yield put(fetchBoilerQuoteSuccess(data.data));
  } catch (error) {
    yield put(fetchBoilerQuoteFail());
  }
}

export function* saveBoilerQuoteSaga(action) {
  const beCount = sessionStorage.getItem("bedNumberCount");
  const userEmail = sessionStorage.getItem("userEmail");
  try {
    yield put(postSaveBoilerQuoteDataStarted());
    const { payload } = action;

    const { data } = yield call(
      apiClient.post,
      API_URL.SAVE_BOILER_QUOTE,
      payload
    );

    yield put(loadingState(data));
    const payloadboiler = {
      brand_id: "",
      bedroom: beCount,
      email: userEmail,
    };
    yield put({ type: GET_PRODUCT_DETAILS, payload: payloadboiler });
    yield put({ type: GET_DROPDOWN_DETAILS, payload: payloadboiler });
    yield put({ type: GET_ALL_SAVE_USER_DATA_DETAILS });
    yield put(setRoute(ROUTES.products));
  } catch (error) {
    yield put(postSaveBoilerQuoteDataFails());
  }
}
//yield put({ type: GET_CART_PRODUCT_DETAILS });
export function* valdiateEmailSaga(action) {
  try {
    const { payload } = action;
    const { data } = yield call(
      apiClient.get,
      `${API_URL.VALIDATE_EMAIL_ADDRESS}?api_key=2c4c29b45ebc4cb190df8cd6363738ef&email=${payload.email}&ip_address=`
    );

    if (!data.free_email) {
      yield put(
        emailValidateFail({
          email: "Entered email is invalid",
        })
      );
    }
  } catch (error) {
    yield put(
      emailValidateFail({
        email: "Entered email is invalid",
      })
    );
  }
}

export function* valdiatePhoneNumberSaga(action) {
  try {
    const { payload } = action;
    const data = yield call(
      apiClient.get,
      `${API_URL.VALIDATE_PHONE_NUMBER}?phone=${payload.phone}&key=288229EAEE0D49AFB7821B4E34B71384`
    );

    if (!data.phone_valid) {
      yield put(
        phoneValidateFail({
          phoneNumber: "Entered phone number is invalid",
        })
      );
    }
  } catch (error) {
    yield put(
      phoneValidateFail({
        phoneNumber: "Entered phone number is invalid",
      })
    );
  }
}
export function* dynamicCurrencyEmailPhoneSaga(action) {
  try {
    yield put(getPhoneEmailCurrencyStarted());

    const data = yield call(
      apiClient.get,
      TERM_CONDITION.GET_CURRENCY_PHONE_EMAIL
    );
    yield put(getPhoneEmailCurrencySuccess(data.data));
  } catch (err) {
    yield put(getPhoneEmailCurrencyFails());
  }
}
