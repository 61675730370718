import { set } from "date-fns";
import React from "react";
import { useDispatch } from "react-redux";
import { clearclientkeyDataSuccess } from "../../organisms/checkoutpage/redux/PaymentReducer";
import { useEffect } from "react";

const PaymentSuccesModal = ({
  succesMessageModal,
  setSuccessMessageModal,
  setStripePaymentProcess,
}) => {
  const dispatch = useDispatch();
  const handleCLoseSuccessModal = () => {
    setStripePaymentProcess(false);
    setSuccessMessageModal(false);
    dispatch(clearclientkeyDataSuccess());
    
  };
  useEffect(() => {
    const div = document.getElementById("animatedDiv");
    div.style.opacity = "1"; // Update the opacity to 1 to trigger the animation
  }, []);
  return (
    <>
      {/* {succesMessageModal && ( */}
      <div className="quotesContainer">
        <div className="showStartAgainContainer">
          <div className="columns is-flex is-flex-direction-column is-justify-content-center ">
            <div className="column">
              {/* <p className="title is-4 has-text-weight-normal"> Start again</p> */}
            </div>
            <div className="column">
              <p>Your payment is successful</p>
            </div>
            <div className="columns is-centered">
              <div
                id="animatedDiv"
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  opacity: "0", // Start with 0 opacity
                  transition: "opacity 2s ease-in-out",

                  // position: "absolute",
                }}
                className="is-flex  is-justify-content-center is-align-content-center is-flex-direction-column button is-success"
              >
                <span></span>
                <span style={{ color: "white" }}>
                  <i class="fas fa-check" style={{ scale: "2.9" }}></i>
                </span>
              </div>
            </div>

            <div className="column">
              <button
                class="button is-success is-fullwidth"
                onClick={handleCLoseSuccessModal}
              >
                {" "}
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default PaymentSuccesModal;

{
  /* <div
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                backgroundColor: "green",

                // position: "absolute",
              }}
              className="column"
            >
              <span style={{ scale: "1.7" }}>
                <i class="fas fa-check"></i>
              </span>
            </div> */
}
